import { ProjectItem } from './generated/project-item';

export class EditContentsItem {
    idx: number;
    itemCode: string;
    itemDescription: string;
    itemBrand: string;
    brandColor: string;
    comment: string;
    isDeep: boolean;

    constructor(item?: ProjectItem, idx?: number) {
        if (item) {
            this.itemCode = item.itemCode;
            this.itemDescription = item.itemDescription;
            this.itemBrand = item.itemBrand;
            this.brandColor = item.brandColor;
            this.comment = item.comment;
            this.isDeep = item.itemStorageType && item.itemStorageType.toLocaleUpperCase() == 'DEEP';
        }

        this.idx = idx;
    }
}