import { AddProjectItemData } from './addItemsToTray-dialog';

export class ViewModelRowData {

    public id: number;
    public projectItemId: number;
    public quantity: number;
    public sequenceNumber: number;
    public itemWidth: number;
    public itemLength: number;
    public itemBrand: string;
    public itemSubBrand: string;
    public brandColor: string;

    constructor(data?: AddProjectItemData) {
        if (data) {
            this.projectItemId = data.id;
            this.quantity = 1;
            this.sequenceNumber = null;
            this.itemWidth = data.itemWidth || 3;
            this.itemLength = data.itemLength || 15;
            this.itemBrand = data.itemBrand;
            this.itemSubBrand = data.itemSubBrand;
            this.brandColor = data.brandColor;
        }
    }
}
