import { ProjectItem } from './generated/project-item';

export class AddProjectItemData {
    id: number;
    itemCode: string;
    itemDescription: string;
    itemBrand: string;
    itemSubBrand: string;
    brandColor: string;
    quantityAvailable: number;
    quantity: number;
    itemWidth: number;
    itemLength: number;
    comment: string;
    isDeep: boolean;
    masterSequence: string;

    constructor(projectItem: ProjectItem) {
        this.id = projectItem.id;
        this.itemCode = projectItem.itemCode;
        this.itemDescription = projectItem.itemDescription;
        this.itemBrand = projectItem.itemBrand;
        this.itemSubBrand = projectItem.itemSubBrand;
        this.brandColor = projectItem.brandColor;
        this.quantityAvailable = projectItem.quantity;
        this.quantity = this.quantityAvailable;
        this.itemWidth = projectItem.itemWidth || 3;
        this.itemLength = projectItem.itemLength || 15;
        this.comment = projectItem.comment;
        this.isDeep = projectItem.itemStorageType && projectItem.itemStorageType.toLocaleUpperCase() == 'DEEP';
        this.masterSequence = projectItem.masterSequence;
    }
}

export class AddItemsToTrayData {
    items: AddProjectItemData[];
    addColumn: boolean;
}

export class ExtendedAddProjectItemData {

    constructor(addProjectItem: AddProjectItemData) {
        this.item = addProjectItem;
        this.remainingQuantity = addProjectItem.quantity;
    }

    item: AddProjectItemData;
    remainingQuantity: number;
}