import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AccordionModule, BsDatepickerModule, TabsModule, TypeaheadModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { GeneralLoaderComponent } from './general-loader/general-loader.component';
import { HeaderComponent } from './header/header.component';
import { FilterPipe } from './pipes/filter.pipe';
import { JoinSelectedNamesPipe } from './pipes/join-selected-names.pipe';
import { OrderPipe } from './pipes/ngx-order.pipe';
import { OrganizationStatusPipe } from './pipes/organization-status.pipe';
import { OrganizationTypePipe } from './pipes/organization-type.pipe';
import { UniqueIdPipe } from './pipes/unique-id.pipe';
import { AlertService, LoaderService, StorageService } from './services';
import { AccessGuard } from './services/access-guard.service';
import { AccessService } from './services/access.service';
import { ApiService } from './services/api.service';
import { AuthGuard } from './services/auth-guard.service';
import { FileHelperService } from './services/file-helper.service';
import { ProductsService } from './services/products.service';
import { ProjectsService } from './services/projects.service';
import { ViewModelService } from './services/view-model.service';
import { TrolleyLabelsService } from './services/trolley-labels.service';
import { DropdownMultiselectComponent } from './dropdown-multiselect/dropdown-multiselect.component';
import { MatSelectModule } from '@angular/material';
import { TrolleySizePipe } from './pipes/trolley-size.pipe';
import { PopoverModule } from 'ngx-bootstrap/popover';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?now=' + (new Date().getTime()));
}

@NgModule({
    imports: [
        CommonModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        FormsModule,
        MatDialogModule,
        MatSelectModule,
        MultiselectDropdownModule,
        BsDatepickerModule.forRoot(),
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        TypeaheadModule.forRoot(),
        PopoverModule.forRoot()
    ],
    providers: [
        StorageService,
        LoaderService,
        ApiService,
        AlertService,
        AuthGuard,
        AccessGuard,
        AccessService,
        FileHelperService,
        ProductsService,
        ProjectsService,
        ViewModelService,
        TrolleyLabelsService,

        OrganizationStatusPipe,
        OrganizationTypePipe,
        FilterPipe,
        OrderPipe,
        JoinSelectedNamesPipe,
        UniqueIdPipe,
        TrolleySizePipe
    ],
    declarations: [
        GeneralLoaderComponent,
        HeaderComponent,
        ConfirmDialogComponent,
        DatePickerComponent,
        DropdownMultiselectComponent,

        OrganizationStatusPipe,
        OrganizationTypePipe,
        FilterPipe,
        OrderPipe,
        JoinSelectedNamesPipe,
        UniqueIdPipe,
        FileUploadComponent,
        TrolleySizePipe
    ],
    exports: [
        GeneralLoaderComponent,
        HeaderComponent,
        ConfirmDialogComponent,
        DatePickerComponent,
        DropdownMultiselectComponent,

        TranslateModule,
        HttpClientModule,
        FormsModule,
        MatDialogModule,
        MultiselectDropdownModule,
        BsDatepickerModule,
        TabsModule,
        AccordionModule,
        TypeaheadModule,
        MatSelectModule,
        PopoverModule,

        OrganizationStatusPipe,
        OrganizationTypePipe,
        FilterPipe,
        OrderPipe,
        JoinSelectedNamesPipe,
        UniqueIdPipe,
        FileUploadComponent,
        TrolleySizePipe
    ],
    entryComponents: [
        ConfirmDialogComponent,
    ]
})
export class SharedModule { }
