import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_URL } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Project } from '../models/generated/project';
import { ViewModel } from '../models/view-model';
import { AddItemsToTrayData, AddProjectItemData, ExtendedAddProjectItemData } from '../models/addItemsToTray-dialog';
import { ViewModelTrayData } from '../models/view-model-tray-data';
import { Tray } from '../models/generated/tray';
import { TrayProjectItem } from '../models/generated/tray-project-item';
import { ViewModelColumnData } from '../models/view-model-column-data';
import { ViewModelRowData } from '../models/view-model-row-data';
import { ProjectsService } from './projects.service';
import { LoaderService } from './loader.service';


import { ProjectItem } from '../models/generated/project-item';
import { TrolleyData } from '../models/generated/trolley-data';
import { TrolleyType } from '../models/generated/trolley-type';
import { TrayData } from '../models/generated/tray-data';
import { map } from 'rxjs/operators';
import { TrayUpdateData } from '../models/generated/tray-update-data';
import { TrayTrolleyViewModel } from '../models/view-model-trolleys';
import { TrolleyBase } from '../models/generated/trolley-base';
import { AlertService } from './alert.service';
import { Util } from '../util';
import { NailingTrolleyViewModel } from '../models/view-model-nailing-trolley';
import { TrolleyProjectItem } from '../models/generated/trolley-project-item';
import { ProjectUpdateData } from '../models/generated/project-update-data';
import { Trolley } from '../models/generated/trolley';
import { TrolleySequenceData } from '../models/generated/trolley-sequence-data';
import { EditContentsItem } from '../models/edit-contents-data';
import { TrolleyOverview, TrolleyOverviewCompartment, TrolleyOverviewTray } from '../models/trolley-overview';
import { ViewModelTrolleyColumn } from '../models/view-model-trolley-column';
import { ViewModelTrolleyRow } from '../models/view-model-trolley-row';
import { OverviewContentsItem } from '../models/overview-contents-data';
import { TrayType } from '../models/generated/tray-type';
import { ViewModelSubBrandInfo } from '../models/view-model-sub-brand-info';

@Injectable()
export class ViewModelService {

    private _viewModelDataObject: ViewModel;
    private _viewModelData: BehaviorSubject<ViewModel>;
    public viewModelData: Observable<ViewModel>;

    constructor(
        private apiService: ApiService,
        private projectsService: ProjectsService,
        private loaderService: LoaderService,
        private alertService: AlertService
    ) {
        this._viewModelData = new BehaviorSubject<ViewModel>(this._viewModelDataObject);
        this.viewModelData = this._viewModelData.asObservable();
    }

    public getData() {
        return this._viewModelDataObject;
    }

    public initializeModel(data: Project) {
        const configuration = this._viewModelDataObject ? this._viewModelDataObject.trolleyConfiguration : [];

        this._viewModelDataObject = new ViewModel();
        this._viewModelDataObject.projectId = data.id;
        this._viewModelDataObject.name = data.name;
        this._viewModelDataObject.projectStatus = data.projectStatus;
        this._viewModelDataObject.storageSystem = data.storageSystem;
        this._viewModelDataObject.trolleyDoorType = data.trolleyDoorType;
        this._viewModelDataObject.projectItems = data.projectItems;
        this._viewModelDataObject.trolleys = data.trolleys;
        this._viewModelDataObject.trolleyConfiguration = configuration;

        let nailingTrayIds = [];
        for (const trolley of data.trolleys) {
            if (trolley.trolleyType == TrolleyType.nailing) {
                for (const trayId of trolley.trays) {
                    nailingTrayIds.push(trayId);
                }
            }
        }

        // add trays
        data.trays.sort((t1, t2) => t1.sequenceNumber - t2.sequenceNumber).forEach((trayItem: Tray, trayIndex: number) => {
            let trayVM = new ViewModelTrayData(trayItem);

            for (const projectItem of trayItem.items.sort((t1, t2) => t1.row - t2.row)) {
                const itemFullInfo = data.projectItems.find((item) => item.id === projectItem.projectItemId);

                if (!trayVM.columns[projectItem.column]) {
                    trayVM.columns[projectItem.column] = new ViewModelColumnData();
                }

                if (!trayVM.columns[projectItem.column].rows[projectItem.row]) {
                    trayVM.columns[projectItem.column].rows[projectItem.row] = new ViewModelRowData();
                }

                trayVM.columns[projectItem.column].columnWidth = this.calculateColumnWidth(trayVM.columns[projectItem.column].columnWidth, [itemFullInfo]);

                trayVM.columns[projectItem.column].rows[projectItem.row].id = projectItem.id;
                trayVM.columns[projectItem.column].rows[projectItem.row].projectItemId = projectItem.projectItemId;
                trayVM.columns[projectItem.column].rows[projectItem.row].quantity = projectItem.quantity;
                trayVM.columns[projectItem.column].rows[projectItem.row].itemWidth = itemFullInfo.itemWidth || 3;
                trayVM.columns[projectItem.column].rows[projectItem.row].itemLength = itemFullInfo.itemLength || 15;
                trayVM.columns[projectItem.column].rows[projectItem.row].itemBrand = itemFullInfo.itemBrand;
                trayVM.columns[projectItem.column].rows[projectItem.row].brandColor = itemFullInfo.brandColor;
            }

            trayVM.remainingSpace = this.calculateTrayRemainingSpace(trayVM);

            trayItem.subBrands.forEach(brand => {
                brand.itemSizeInfos.sort((b1, b2) => b1.itemCode > b2.itemCode ? 1 : -1);

                const subBrand: ViewModelSubBrandInfo = {
                    name: brand.name,
                    brandColor: brand.brandColor,
                    minSize: brand.itemSizeInfos[0].size,
                    maxSize: brand.itemSizeInfos[brand.itemSizeInfos.length - 1].size
                };

                trayVM.subBrands.push(subBrand);
            });

            trayVM.calculateBrandColors();

            if (nailingTrayIds.indexOf(trayVM.id) >= 0) {
                this._viewModelDataObject.nailingTrays.push(trayVM);
            } else {
                this._viewModelDataObject.trays.push(trayVM);
            }
        });

        this.calculateAllColumnsRemainingSpace();

        return this._viewModelDataObject;
    }

    public initializeTrays(trays: Tray[], projectItems: ProjectItem[]) {
        trays.sort((t1, t2) => t1.sequenceNumber - t2.sequenceNumber).forEach((trayItem: Tray, trayIndex: number) => {
            let trayVM = new ViewModelTrayData(trayItem);

            for (const projectItem of trayItem.items.sort((t1, t2) => t1.row - t2.row)) {
                const itemFullInfo = projectItems.find((item) => item.id === projectItem.projectItemId);

                if (!trayVM.columns[projectItem.column]) {
                    trayVM.columns[projectItem.column] = new ViewModelColumnData();
                }

                if (!trayVM.columns[projectItem.column].rows[projectItem.row]) {
                    trayVM.columns[projectItem.column].rows[projectItem.row] = new ViewModelRowData();
                }

                trayVM.columns[projectItem.column].columnWidth = this.calculateColumnWidth(trayVM.columns[projectItem.column].columnWidth, [itemFullInfo]);

                trayVM.columns[projectItem.column].rows[projectItem.row].id = projectItem.id;
                trayVM.columns[projectItem.column].rows[projectItem.row].projectItemId = projectItem.projectItemId;
                trayVM.columns[projectItem.column].rows[projectItem.row].quantity = projectItem.quantity;
                trayVM.columns[projectItem.column].rows[projectItem.row].itemWidth = itemFullInfo.itemWidth || 3;
                trayVM.columns[projectItem.column].rows[projectItem.row].itemLength = itemFullInfo.itemLength || 15;
                trayVM.columns[projectItem.column].rows[projectItem.row].itemBrand = itemFullInfo.itemBrand;
                trayVM.columns[projectItem.column].rows[projectItem.row].brandColor = itemFullInfo.brandColor;
            }

            if (trayItem.subBrands) {
                trayItem.subBrands.forEach(brand => {
                    brand.itemSizeInfos.sort((b1, b2) => b1.itemCode > b2.itemCode ? 1 : -1);
                    const subBrand: ViewModelSubBrandInfo = {
                        name: brand.name,
                        brandColor: brand.brandColor,
                        minSize: brand.itemSizeInfos[0].size,
                        maxSize: brand.itemSizeInfos[brand.itemSizeInfos.length - 1].size
                    };
                    trayVM.subBrands.push(subBrand);
                });
            }

            trayVM.remainingSpace = this.calculateTrayRemainingSpace(trayVM);
            trayVM.calculateBrandColors();

            this._viewModelDataObject.trays.push(trayVM);
        });

        this.calculateAllColumnsRemainingSpace();
    }

    public updateTrayColumnContents(trayIndex: number, column: ViewModelColumnData, items: EditContentsItem[]) {
        if (items) {
            const remainingIdx = items.map(r => r.idx);

            let newRows = [];
            column.rows.forEach((row, idx) => {
                if (remainingIdx.indexOf(idx) >= 0) {
                    newRows.push(row);
                }
            });

            if (newRows.length > 0) {
                column.rows = newRows;
                let items = [];
                for (let row of column.rows) {
                    const projectItem = this._viewModelDataObject.projectItems.find(i => i.id == row.projectItemId);
                    if (items.indexOf(projectItem) === -1) {
                        items.push(projectItem);
                    }
                }
                column.columnWidth = this.calculateNewColumnWidth(items);
                column.remainingSpace = this.calculateColumnRemainingSpace(column.rows);
            } else {
                this._viewModelDataObject.trays[trayIndex].columns.splice(this._viewModelDataObject.trays[trayIndex].columns.indexOf(column), 1);
            }

            this._viewModelDataObject.trays[trayIndex].remainingSpace = this.calculateTrayRemainingSpace(this._viewModelDataObject.trays[trayIndex]);
            this._viewModelDataObject.trays[trayIndex].calculateBrandColors();
        }
    }

    public updateNailingTrayColumnContents(tray: ViewModelTrayData, column: ViewModelColumnData, items: EditContentsItem[]) {
        if (items) {
            const remainingIdx = items.map(r => r.idx);

            let newRows = [];
            column.rows.forEach((row, idx) => {
                if (remainingIdx.indexOf(idx) >= 0) {
                    newRows.push(row);
                }
            });

            if (newRows.length > 0) {
                column.rows = newRows;
                let items = [];
                for (let row of column.rows) {
                    const projectItem = this._viewModelDataObject.projectItems.find(i => i.id == row.projectItemId);
                    if (items.indexOf(projectItem) === -1) {
                        items.push(projectItem);
                    }
                }
                column.columnWidth = this.calculateNewColumnWidth(items);
                column.remainingSpace = this.calculateColumnRemainingSpace(column.rows);
            } else {
                tray.columns.splice(tray.columns.indexOf(column), 1);
            }

            tray.remainingSpace = this.calculateTrayRemainingSpace(tray);
            tray.calculateBrandColors();
        }
    }

    // Step 1: Upload inventory

    public uploadProjectInventory(file: File) {
        const obs$ = this.projectsService.importProjectsFile(file, this._viewModelDataObject.projectId);

        this.loaderService.wrap(obs$).subscribe(() => {
            this.alertService.success('File uploaded successfully');
            this.refreshViewModel();
        });
    }

    public updateProjectItemQuantity(rowId: number, data: ProjectItem) {
        const index = this._viewModelDataObject.projectItems.findIndex(p => p.id === rowId);
        this._viewModelDataObject.projectItems[index].quantity = data.quantity;

        this.refreshViewModel();
    }

    public addProjectItem(item) {
        const index = this._viewModelDataObject.projectItems.findIndex(p => p.id == item.id);
        if (index > -1) {
            this._viewModelDataObject.projectItems[index] = item;
        } else {
            this._viewModelDataObject.projectItems.push(item);
        }

        this.refreshViewModel();
    }

    public deleteProjectItem(item) {
        const idx = this._viewModelDataObject.projectItems.indexOf(item);
        this._viewModelDataObject.projectItems.splice(idx, 1);

        this.refreshViewModel();
    }

    // Step 2: Storage systems

    public updateProjectData(data: ProjectUpdateData) {
        this._viewModelDataObject.storageSystem = data.storageSystem;
        this._viewModelDataObject.trolleyDoorType = data.trolleyDoorType;

        this.refreshViewModel();
    }

    // Step 3: Configure trays

    addTray(tray: TrayData) {
        tray.sequenceNumber = Math.max(...this._viewModelDataObject.trays.map(t => t.sequenceNumber), 0) + 1;
        const obs$ = this.projectsService.addTrays(this._viewModelDataObject.projectId, tray)
            .pipe(map(response => {
                const trayVM = new ViewModelTrayData(response);
                this._viewModelDataObject.trays[this._viewModelDataObject.trays.length] = trayVM;
                return trayVM;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    editTray(tray: TrayData, trayId: number) {
        const obs$ = this.projectsService.editTray(this._viewModelDataObject.projectId, trayId, tray)
            .pipe(map(response => {
                const trayVM = this._viewModelDataObject.trays.find(el => el.id === trayId);
                if (trayVM) {
                    trayVM.name = response.name;
                    trayVM.trayType = response.trayType;
                }
                return trayVM;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    createTrays(trays: ViewModelTrayData[]) {
        const trayList = new Array<TrayUpdateData>();

        trays.forEach(tray => {
            const trayData = new TrayUpdateData();
            trayData.name = tray.name;
            trayData.sequenceNumber = tray.sequenceNumber;
            trayData.trayType = tray.trayType;

            tray.columns.forEach((column, ci) => {
                column.rows.forEach((row, ri) => {
                    const item = new TrayProjectItem();
                    item.id = row.id;
                    item.projectItemId = row.projectItemId;
                    item.quantity = row.quantity;
                    item.row = ri;
                    item.column = ci;
                    trayData.items.push(item);
                });
            });

            trayList.push(trayData);
        });

        const obs$ = this.projectsService.createTrays(this._viewModelDataObject.projectId, trayList);
        return this.loaderService.wrapObservable(obs$);
    }

    updateTrayData(data: AddItemsToTrayData, trayIndex: number, addColumn: boolean, columnIndex: number): boolean {
        if (!this.canAddItemsToTray(data.items, this._viewModelDataObject.trays[trayIndex], addColumn, columnIndex)) {
            //this.alertService.error('Selected items will not fit into trayy!');
            return false;
        }

        this.addItemsToTray(data.items, this._viewModelDataObject.trays[trayIndex], addColumn, columnIndex);
        return true;
    }

    autoUpdateTrayData(item: AddProjectItemData, trayIndex: number): boolean {
        return this.autoAddItemsToTray(item, this._viewModelDataObject.trays[trayIndex]);
    }

    autoUpdateNailingTrayData(item: AddProjectItemData, tray: ViewModelTrayData): boolean {
        return this.autoAddItemsToTray(item, tray);
    }

    updateShelfData(data: AddItemsToTrayData, shelfIndex: number, showAlert: boolean = true): boolean {
        const shelf = this._viewModelDataObject.trays[shelfIndex];
        if (shelf.columns.length < 1) {
            shelf.columns.push(new ViewModelColumnData());
        }

        const shelfItemsCount = shelf.columns[0].rows.length;
        let itemsCount = 0;
        data.items.forEach(item => itemsCount += item.quantity);
        if (shelf.trayType === TrayType.shallowShelf && shelfItemsCount + itemsCount > 10) {
            if (showAlert) {
                this.alertService.error('Selected items will not fit into shelf!');
            }

            return false;
        } else if (shelf.trayType === TrayType.deepShelf && shelfItemsCount + itemsCount > 16) {
            if (showAlert) {
                this.alertService.error('Selected items will not fit into shelf!');
            }

            return false;
        }

        data.items.forEach(e => {
            for (let i = 0; i < e.quantity; ++i) {
                shelf.columns[0].rows.push(new ViewModelRowData(e));
            }

        });

        shelf.columns[0].columnWidth = 3;
        this.calculateAllColumnsRemainingSpace();
        shelf.remainingSpace = this.calculateTrayRemainingSpace(shelf);
        shelf.calculateBrandColors();
        return true;
    }

    deleteTray(idx: number) {
        const tray = this._viewModelDataObject.trays[idx];
        if (tray) {
            const obs$ = this.projectsService.deleteTray(this._viewModelDataObject.projectId, tray.id)
                .pipe(map(() => {
                    this._viewModelDataObject.trays.splice(idx, 1);
                    return 1;
                }));

            return this.loaderService.wrapObservable(obs$);
        }
    }

    /* Configure tray Trolleys */

    addTrayTrolley(trolley: TrayTrolleyViewModel) {
        const trolleyData = new TrolleyData();
        trolleyData.description = trolley.description;
        trolleyData.trolleySize = trolley.trolleySize;
        trolleyData.sequenceNumber = Math.max(...this._viewModelDataObject.trolleys
            .filter(t => t.trolleyType === TrolleyType.trays)
            .map(t => t.sequenceNumber), 0) + 1;
        trolleyData.trolleyType = TrolleyType.trays;

        const obs$ = this.projectsService.createTrolley(this._viewModelDataObject.projectId, trolleyData)
            .pipe(map(response => {
                const trolley = new Trolley();
                trolley.id = response.id;
                trolley.description = response.description;
                trolley.sequenceNumber = response.sequenceNumber;
                trolley.trolleyConfigurationId = response.trolleyConfigurationId;
                trolley.trolleyType = response.trolleyType;
                trolley.trolleySize = response.trolleySize;

                const trolleyVM = new TrayTrolleyViewModel(trolley);
                this._viewModelDataObject.trolleys.push(trolley);
                return trolleyVM;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    updateTrayTrolley(trolley: TrayTrolleyViewModel) {
        const trolleyBase = new TrolleyBase();
        trolleyBase.description = trolley.description;
        trolleyBase.sequenceNumber = trolley.sequenceNumber;
        trolleyBase.trays = trolley.trays.map(t => t.id);
        trolleyBase.trolleyType = TrolleyType.trays;

        const obs$ = this.projectsService.updateTrolley(this._viewModelDataObject.projectId, trolley.id, trolleyBase)
            .pipe(map(response => {
                trolley.trays.forEach((tray, trayIdx) => {
                    tray.trolleyId = trolley.id;
                    tray.sequenceNumberTrolley = trayIdx;
                });

                const trolleyVM = new TrayTrolleyViewModel(response);
                const trolleyItem = this._viewModelDataObject.trolleys.find(t => t.id === trolley.id);

                if (trolleyItem) {
                    trolleyItem.description = trolley.description;
                    trolleyItem.trays = trolleyBase.trays;
                }

                return trolleyVM;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    deleteTrayTrolley(trolley: TrayTrolleyViewModel) {
        const obs$ = this.projectsService.deleteTrolley(this._viewModelDataObject.projectId, trolley.id)
            .pipe(map(() => {
                trolley.trays.forEach((tray) => {
                    tray.trolleyId = null;
                    tray.sequenceNumberTrolley = null;
                });

                const index = this._viewModelDataObject.trolleys.findIndex(t => t.id === trolley.id);

                if (index > -1) {
                    this._viewModelDataObject.trolleys.splice(index, 1);
                }

                return 1;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    /* Configure nailing Trolleys */

    addNailingTrolley(trolley: NailingTrolleyViewModel) {
        const trolleyData = new TrolleyData();
        trolleyData.description = trolley.description;
        trolleyData.trolleyConfigurationId = trolley.trolleyConfigurationId;
        trolleyData.sequenceNumber = Math.max(...this._viewModelDataObject.trolleys
            .filter(t => t.trolleyType === TrolleyType.nailing)
            .map(t => t.sequenceNumber), 0) + 1;
        trolleyData.trolleyType = TrolleyType.nailing;

        const obs$ = this.projectsService.createTrolley(this._viewModelDataObject.projectId, trolleyData)
            .pipe(map(response => {
                const trolley = new Trolley();
                trolley.id = response.id;
                trolley.description = response.description;
                trolley.sequenceNumber = response.sequenceNumber;
                trolley.trolleyConfigurationId = response.trolleyConfigurationId;
                trolley.trolleyType = response.trolleyType;

                for (let tray of response.trays) {
                    trolley.trays.push(tray.id);
                    const trayVM = new ViewModelTrayData(tray);
                    this._viewModelDataObject.nailingTrays.push(trayVM);
                }

                this._viewModelDataObject.trolleys.push(trolley);
                return trolley;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    updateNailingTrolley(trolley: NailingTrolleyViewModel) {
        const trolleyBase = new TrolleyBase();
        trolleyBase.description = trolley.description;
        trolleyBase.sequenceNumber = trolley.sequenceNumber;
        trolleyBase.trays = trolley.trays.map(t => t.id);
        trolleyBase.trolleyType = TrolleyType.nailing;

        trolley.compartments.forEach(c => {
            c.rows.forEach((item, index) => {
                if (item.projectItemId) {
                    const projectItem = new TrolleyProjectItem();
                    projectItem.id = item.id;
                    projectItem.projectItemId = item.projectItemId;
                    projectItem.compartment = item.compartment;
                    projectItem.trolleyId = item.trolleyId;
                    projectItem.sequenceNumber = index;
                    trolleyBase.trolleyProjectItems.push(projectItem);
                }
            });
        });

        const obs$ = this.projectsService.updateTrolley(this._viewModelDataObject.projectId, trolley.id, trolleyBase)
            .pipe(map(response => {

                const trolleyItem = this._viewModelDataObject.trolleys.find(t => t.id === trolley.id);

                if (trolleyItem) {
                    trolleyItem.description = response.description;
                    trolleyItem.trolleyProjectItems = response.trolleyProjectItems;
                }

                return response;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    updateTrolleyCompartmentData(trolleyId: number, compartment: number, rows: ViewModelTrolleyRow[]) {
        let trolley = this._viewModelDataObject.trolleys.find(t => t.id == trolleyId);
        if (trolley) {
            let newTrolleyItems = trolley.trolleyProjectItems.filter(p => p.compartment != compartment);
            rows.forEach((item, index) => {
                if (item.projectItemId) {
                    const projectItem = new TrolleyProjectItem();
                    projectItem.id = item.id;
                    projectItem.projectItemId = item.projectItemId;
                    projectItem.compartment = item.compartment;
                    projectItem.trolleyId = item.trolleyId;
                    projectItem.sequenceNumber = index;
                    newTrolleyItems.push(projectItem);
                }
            });

            trolley.trolleyProjectItems = newTrolleyItems;
        }
    }

    deleteTrolley(trolley: Trolley) {
        const obs$ = this.projectsService.deleteTrolley(this._viewModelDataObject.projectId, trolley.id)
            .pipe(map(() => {
                const index = this._viewModelDataObject.trolleys.findIndex(t => t.id === trolley.id);

                if (index > -1) {
                    this._viewModelDataObject.trolleys.splice(index, 1);
                }

                for (const trayId of trolley.trays) {
                    if (trolley.trolleyType == TrolleyType.nailing) {
                        const idx = this._viewModelDataObject.nailingTrays.findIndex(t => t.id == trayId);
                        this._viewModelDataObject.nailingTrays.splice(idx, 1);
                    } else if (trolley.trolleyType == TrolleyType.trays) {
                        const tray = this._viewModelDataObject.trays.find(t => t.id == trayId);
                        if (tray) {
                            tray.trolleyId = null;
                            tray.sequenceNumberTrolley = null;
                        }
                    }
                }

                return 1;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    deleteNailingTrolley(trolley: NailingTrolleyViewModel) {
        const obs$ = this.projectsService.deleteTrolley(this._viewModelDataObject.projectId, trolley.id)
            .pipe(map(() => {
                const index = this._viewModelDataObject.trolleys.findIndex(t => t.id === trolley.id);

                if (index > -1) {
                    this._viewModelDataObject.trolleys.splice(index, 1);
                }

                for (const tray of trolley.trays) {
                    const idx = this._viewModelDataObject.nailingTrays.findIndex(t => t.id == tray.id);
                    if (idx) {
                        this._viewModelDataObject.nailingTrays.splice(idx, 1);
                    }
                }

                return 1;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    editNailingTray(tray: TrayData, trayId: number) {
        const obs$ = this.projectsService.editTray(this._viewModelDataObject.projectId, trayId, tray)
            .pipe(map(response => {
                const trayVM = this._viewModelDataObject.nailingTrays.find(el => el.id === trayId);
                if (trayVM) {
                    trayVM.name = response.name;
                    trayVM.trayType = response.trayType;
                }
                return trayVM;
            }));

        return this.loaderService.wrapObservable(obs$);
    }

    updateNailingTrayData(data: AddItemsToTrayData, tray: ViewModelTrayData, addColumn: boolean, columnIndex: number): boolean {
        if (!this.canAddItemsToTray(data.items, tray, addColumn, columnIndex)) {
            //this.alertService.error('Selected items will not fit into tray!');
            return false;
        }
        this.addItemsToTray(data.items, tray, addColumn, columnIndex);
        return true;
    }

    reorganizeTrolleys(trolleys: Trolley[]) {
        const data = [];

        let index = 0;
        for (let trolley of trolleys) {
            const item = new TrolleySequenceData();
            item.id = trolley.id;
            item.description = trolley.description;
            item.doorType = this._viewModelDataObject.trolleyDoorType;
            item.sequenceNumber = index++;
            data.push(item);
        }

        return this.loaderService.wrap(
            this.projectsService.reorganizeTrolleys(this._viewModelDataObject.projectId, data)
        ).pipe(map(() => {
            for (const item of data) {
                let trolley = this._viewModelDataObject.trolleys.find(t => t.id == item.id);
                if (trolley) {
                    trolley.description = item.description;
                    trolley.doorType = item.doorType;
                    trolley.sequenceNumber = item.sequenceNumber;
                }
            }

            this._viewModelDataObject.trolleys = this._viewModelDataObject.trolleys.sort((t1, t2) => t1.sequenceNumber - t2.sequenceNumber);
        }));
    }

    saveTray(tray: ViewModelTrayData) {
        const trayData = new TrayUpdateData();
        trayData.name = tray.name;
        trayData.sequenceNumber = tray.sequenceNumber;
        trayData.trayType = tray.trayType;

        tray.columns.forEach((column, ci) => {
            column.rows.forEach((row, ri) => {
                const item = new TrayProjectItem();
                item.id = row.id;
                item.projectItemId = row.projectItemId;
                item.quantity = row.quantity;
                item.row = ri;
                item.column = ci;
                trayData.items.push(item);
            });
        });

        const obs$ = this.projectsService.updateTray(this._viewModelDataObject.projectId, tray.id, trayData);
        return this.loaderService.wrapObservable(obs$);
    }

    getColumnsInitiatedWidth(tray: ViewModelTrayData) {
        let totalColumnWidth = 0;
        if (tray.trayType === TrayType.shallow || tray.trayType === TrayType.deep) {
            for (const column of tray.columns) {
                totalColumnWidth += (1 / column.columnWidth);
            }
        } else if (tray.trayType === TrayType.shallowShelf || tray.trayType === TrayType.deepShelf) {
            totalColumnWidth = tray.columns.length > 0 ? 1 : 0;
        }
        return totalColumnWidth;
    }

    calculateRemainingProjectItems() {
        let remainingProjectItems = Util.cloneObject(this._viewModelDataObject.projectItems);

        for (const tray of this._viewModelDataObject.trays) {
            for (const column of tray.columns) {
                for (const row of column.rows) {
                    const projectItem = remainingProjectItems.find(p => p.id == row.projectItemId);
                    if (projectItem) {
                        projectItem.quantity -= row.quantity;
                    }
                }
            }
        }

        for (const tray of this._viewModelDataObject.nailingTrays) {
            for (const column of tray.columns) {
                for (const row of column.rows) {
                    const projectItem = remainingProjectItems.find(p => p.id == row.projectItemId);
                    if (projectItem) {
                        projectItem.quantity -= row.quantity;
                    }
                }
            }
        }

        for (const trolley of this._viewModelDataObject.trolleys) {
            for (const trolleyItem of trolley.trolleyProjectItems) {
                const projectItem = remainingProjectItems.find(p => p.id == trolleyItem.projectItemId);
                projectItem.quantity -= 1;
            }
        }

        return remainingProjectItems;
    }

    clearTrays() {
        this._viewModelDataObject.trays = [];

        this._viewModelDataObject.trolleys.forEach(trolley => {
            if (trolley.trolleyType === TrolleyType.trays) {
                trolley.trays = [];
            }
        });
    }

    clearTraysData() {
        if (this._viewModelDataObject.trays && this._viewModelDataObject.trays.length) {
            this._viewModelDataObject.trays.forEach(tray => {
                tray.columns = [];
                tray.remainingSpace = 1;
            });
        }
    }

    clearTrolleysData() {
        if (this._viewModelDataObject.trolleys && this._viewModelDataObject.trolleys.length) {
            this._viewModelDataObject.trolleys.forEach(trolley => {
                trolley.trolleyProjectItems = [];
            });
        }

        if (this._viewModelDataObject.nailingTrays && this._viewModelDataObject.nailingTrays.length) {
            this._viewModelDataObject.nailingTrays.forEach(tray => {
                tray.columns = [];
                tray.remainingSpace = 1;
                tray.brandColors = [];
            });
        }
    }

    removeProjectItem(id) {
        this._viewModelDataObject.trays.forEach((tray, trayIndex) => {
            tray.columns.forEach(column => {
                const removeItems = [];
                let items = [];

                column.rows.forEach((row, rowIndex) => {
                    const projectItem = this._viewModelDataObject.projectItems.find(_ => _.id === row.projectItemId);

                    if (row.projectItemId === id) {
                        removeItems.push(rowIndex);
                    } else {
                        const item = new EditContentsItem(projectItem, rowIndex);
                        items.unshift(item);
                    }
                });

                for (let i = removeItems.length - 1; i >= 0; i--) {
                    column.rows.splice(removeItems[i], 1);
                }

                this.updateTrayColumnContents(trayIndex, column, items);
            });
        });

        this._viewModelDataObject.nailingTrays.forEach((tray, trayIndex) => {
            tray.columns.forEach(column => {
                const removeItems = [];
                let items = [];

                column.rows.forEach((row, rowIndex) => {
                    const projectItem = this._viewModelDataObject.projectItems.find(_ => _.id === row.projectItemId);

                    if (row.projectItemId === id) {
                        removeItems.push(rowIndex);
                    } else {
                        const item = new EditContentsItem(projectItem, rowIndex);
                        items.unshift(item);
                    }
                });

                for (let i = removeItems.length - 1; i >= 0; i--) {
                    column.rows.splice(removeItems[i], 1);
                }

                this.updateNailingTrayColumnContents(tray, column, items)
            });
        });

        this._viewModelDataObject.trolleys.forEach((trolley, trolleysIndex) => {
            const compartmentData = [];

            if (trolley && trolley.trolleyProjectItems.length) {
                const compartments = [...new Set(trolley.trolleyProjectItems.map(_ => _.compartment))];

                compartments.forEach(compartment => {
                    let compartmentItems = trolley.trolleyProjectItems.filter(_ => _.compartment === compartment);
                    const compartmentLength = Math.max(...compartmentItems.map(t => t.sequenceNumber), 0);
                    compartmentItems = compartmentItems.filter(_ => _.projectItemId !== id);

                    compartmentData.push({
                        items: compartmentItems,
                        compartmentLength: compartmentLength,
                        compartment: compartment
                    });
                });

                trolley.trolleyProjectItems = [];

                compartmentData.forEach(compartment => {
                    let len = compartment.compartmentLength;

                    for (let i = (compartment.items.length - 1); i >= 0; i--) {
                        compartment.items[i].sequenceNumber = len;
                        trolley.trolleyProjectItems.push(compartment.items[i]);
                        len--;
                    }
                });
            }
        });

        console.log(this._viewModelDataObject);
    }

    public getTrolleyOverview() {
        let overviewDict = {};

        for (const trolley of this._viewModelDataObject.trolleys) {
            let trolleyOverview = new TrolleyOverview();

            if (trolley.trolleyConfigurationId) {
                const configuration = this._viewModelDataObject.trolleyConfiguration.find(c => c.id == trolley.trolleyConfigurationId);
                if (configuration) {
                    if (configuration.nailsCount.length > 0) {
                        configuration.nailsCount.forEach(e => {
                            for (let i = 0; i < 4; ++i) {
                                const compartment = new TrolleyOverviewCompartment();
                                for (let index = 0; index < e; ++index) {
                                    compartment.rows.unshift(index);
                                }
                                trolleyOverview.compartments.push(compartment);
                            }
                        });
                    } else {
                        for (let i = 0; i < configuration.compartmentsCount; i++) {

                            const compartment = new TrolleyOverviewCompartment();
                            for (let index = 0; index < 12; ++index) {
                                compartment.rows.unshift(index);
                            }
                            trolleyOverview.compartments.push(compartment);
                        }
                    }
                }
            }

            for (const projectItem of trolley.trolleyProjectItems) {
                if (projectItem.compartment < trolleyOverview.compartments.length) {
                    const projectItemInfo = this._viewModelDataObject.projectItems.find(pi => pi.id == projectItem.projectItemId);
                    if (projectItemInfo) {
                        trolleyOverview.compartments[projectItem.compartment].elements.push(projectItemInfo.brandColor);
                        let compartmentItem = trolleyOverview.compartments[projectItem.compartment].items.find(i => i.itemId === projectItemInfo.id);
                        if (!compartmentItem) {
                            trolleyOverview.compartments[projectItem.compartment].items.push(new OverviewContentsItem(projectItemInfo, 1));
                        } else {
                            compartmentItem.quantity++;
                        }
                    }
                }
            }

            for (const trayId of trolley.trays) {
                let tray: ViewModelTrayData = null;
                if (trolley.trolleyType == TrolleyType.trays) {
                    tray = this._viewModelDataObject.trays.find(t => t.id == trayId);
                } else {
                    tray = this._viewModelDataObject.nailingTrays.find(t => t.id == trayId);
                }

                if (tray) {
                    const trolleyTray = new TrolleyOverviewTray(tray);
                    for (const trayColumn of tray.columns) {
                        for (const trayRow of trayColumn.rows) {
                            const projectItemInfo = this._viewModelDataObject.projectItems.find(pi => pi.id == trayRow.projectItemId);
                            if (projectItemInfo) {
                                const rowItem = trolleyTray.items.find(i => i.itemId === projectItemInfo.id);
                                if (!rowItem) {
                                    trolleyTray.items.push(new OverviewContentsItem(projectItemInfo, 1));
                                } else {
                                    rowItem.quantity++;
                                }
                            }
                        }
                    }

                    trolleyTray.calculateBrandColors();
                    trolleyOverview.trays.push(trolleyTray);
                }
            }

            overviewDict[trolley.id] = trolleyOverview;
        }

        return overviewDict;
    }

    private refreshViewModel() {
        this._viewModelData.next(this._viewModelDataObject);
    }

    private calculateColumnWidth(columnWidth, items) {
        if (!items || items.length == 0)
            return columnWidth;

        var itemsWidth = Math.min(...items.map(i => i.itemWidth || 3));
        return (!columnWidth || columnWidth === 0 || itemsWidth < columnWidth) ? (itemsWidth || 3) : columnWidth;
    }

    private calculateNewColumnWidth(items) {
        return Math.min(...items.map(t => t.itemWidth || 3));
    }

    private calculateTrayRemainingSpace(tray: ViewModelTrayData) {
        const totalColumnWidth = tray.columns.reduce((prev, cur) => prev + (1 / cur.columnWidth), 0);
        return (1 - totalColumnWidth);
    }

    private calculateAllColumnsRemainingSpace() {
        this._viewModelDataObject.trays.forEach((trayItem, trayIndex) => {
            this._viewModelDataObject.trays[trayIndex].columns.forEach((columnItem, columnIndex) => {
                this._viewModelDataObject.trays[trayIndex].columns[columnIndex].remainingSpace = this.calculateColumnRemainingSpace(this._viewModelDataObject.trays[trayIndex].columns[columnIndex].rows);
            });
        });

        this._viewModelDataObject.nailingTrays.forEach((trayItem, trayIndex) => {
            this._viewModelDataObject.nailingTrays[trayIndex].columns.forEach((columnItem, columnIndex) => {
                this._viewModelDataObject.nailingTrays[trayIndex].columns[columnIndex].remainingSpace = this.calculateColumnRemainingSpace(this._viewModelDataObject.nailingTrays[trayIndex].columns[columnIndex].rows);
            });
        });
    }

    private calculateColumnRemainingSpace(rows) {
        const totalRowLength = rows.reduce((prev, cur) => prev + (1 / cur.itemLength), 0);
        return (1 - totalRowLength);
    }

    private checkMasterSequence(items: AddProjectItemData[], tray: ViewModelTrayData, columnIndex: number) {
        if (tray.columns[columnIndex]) {
            items.forEach(item => {
                if (tray.columns[columnIndex].masterSequence !== item.masterSequence) {
                    return false;
                }
            });
        }

        return true;
    }

    private canAddItemsToTray(items: AddProjectItemData[], tray: ViewModelTrayData, addColumn: boolean, columnIndex: number) {
        let currentColumnIndex = addColumn ? tray.columns.length : columnIndex;
        let remainingWidth = tray.remainingSpace;
        let remainingItems = items.map(i => new ExtendedAddProjectItemData(i));

        if (tray.columns[columnIndex]) {
            if (!remainingItems.every(_ => _.item.masterSequence === tray.columns[columnIndex].masterSequence)) {
                return false;
            }
        }

        while (remainingItems.some(i => i.remainingQuantity > 0)) {
            let remainingLengthSpace = 1;
            let addedItems = [];
            let existingColumn: ViewModelColumnData = null;

            if (currentColumnIndex >= 0 && tray.columns.length > currentColumnIndex) {
                existingColumn = tray.columns[currentColumnIndex];
                remainingLengthSpace = existingColumn.remainingSpace;
            }

            currentColumnIndex++;

            for (const item of remainingItems) {
                let addedQuantity = 0;

                for (let i = 0; i < item.remainingQuantity; i++) {
                    remainingLengthSpace -= 1 / item.item.itemLength;
                    if (remainingLengthSpace < 0) {
                        break;
                    }

                    addedQuantity++;
                }

                if (addedQuantity > 0) {
                    addedItems.push(item);
                    item.remainingQuantity -= addedQuantity;
                }

                if (remainingLengthSpace <= 0) {
                    break;
                }
            }

            const currentColumnSpace = existingColumn ? 1 / existingColumn.columnWidth : 0;
            const columnWidth = existingColumn ? this.calculateColumnWidth(existingColumn.columnWidth, addedItems.map(i => i.item)) : this.calculateNewColumnWidth(addedItems.map(i => i.item));
            remainingWidth -= (1 / columnWidth - currentColumnSpace)
            if (remainingWidth < 0) {
                return false;
            }
        }

        return true;
    }

    private addItemsToTray(items: AddProjectItemData[], tray: ViewModelTrayData, addColumn: boolean, columnIndex: number) {
        let currentColumnIndex = addColumn ? tray.columns.length : columnIndex;
        let remainingItems = items.map(i => new ExtendedAddProjectItemData(i));

        while (remainingItems.some(i => i.remainingQuantity > 0)) {
            let column: ViewModelColumnData;
            let remainingLengthSpace = 1;
            let addedItems = [];

            if (currentColumnIndex >= 0 && tray.columns.length > currentColumnIndex) {
                column = tray.columns[currentColumnIndex];
                remainingLengthSpace = column.remainingSpace;
            }

            if (!column) {
                column = new ViewModelColumnData();
                tray.columns.push(column);
            }

            currentColumnIndex++;

            for (const item of remainingItems) {
                let addedQuantity = 0;
                column.masterSequence = item.item.masterSequence;

                for (let i = 0; i < item.remainingQuantity; i++) {
                    remainingLengthSpace -= 1 / item.item.itemLength;
                    if (remainingLengthSpace < 0) {
                        break;
                    }

                    column.rows.push(new ViewModelRowData(item.item));
                    addedQuantity++;
                }

                if (addedQuantity > 0) {
                    addedItems.push(item);
                    item.remainingQuantity -= addedQuantity;
                }

                if (remainingLengthSpace <= 0) {
                    break;
                }
            }

            column.columnWidth = this.calculateColumnWidth(column.columnWidth, addedItems.map(i => i.item));
        }

        this.calculateAllColumnsRemainingSpace();
        tray.remainingSpace = this.calculateTrayRemainingSpace(tray);
        tray.calculateBrandColors();
    }

    autoAddItemsToTray(item: AddProjectItemData, tray: ViewModelTrayData) {
        let itemData = new ExtendedAddProjectItemData(item);
        let addedItems = [];

        let column: ViewModelColumnData;
        let colIndex = this.getColumn(tray, itemData);

        let addedQuantity = 0;

        if (colIndex === -1) {
            if (!this.checkTrayRemainingSpace(tray, itemData)) {
                return false;
            }

            column = new ViewModelColumnData();
            column.masterSequence = itemData.item.masterSequence;
            tray.columns.push(column);
        } else {
            column = tray.columns[colIndex];
        }

        for (let i = 0; i < itemData.remainingQuantity; i++) {
            if (!this.checkColumnRemainingSpace(column, itemData)) {
                addedItems.push(itemData);
                column.columnWidth = this.calculateColumnWidth(column.columnWidth, addedItems.map(i => i.item));
                itemData.remainingQuantity -= addedQuantity;
                i = 0;
                colIndex++;

                if (!tray.columns[colIndex]) {
                    if (!this.checkTrayRemainingSpace(tray, itemData)) {
                        return false;
                    }

                    column = new ViewModelColumnData();
                    column.masterSequence = itemData.item.masterSequence;
                    tray.columns.push(column);
                    addedItems = [];
                    addedQuantity = 0;

                    column.rows.push(new ViewModelRowData(itemData.item));
                    column.remainingSpace = this.calculateRemainingColumnSpace(column, itemData);
                    addedQuantity++;
                }
            } else {
                column.rows.push(new ViewModelRowData(itemData.item));
                column.remainingSpace = this.calculateRemainingColumnSpace(column, itemData);
                addedQuantity++;
            }
        }

        if (addedQuantity > 0) {
            addedItems.push(itemData);
            itemData.remainingQuantity -= addedQuantity;
        }

        column.columnWidth = this.calculateColumnWidth(column.columnWidth, addedItems.map(i => i.item));

        this.calculateAllColumnsRemainingSpace();
        tray.remainingSpace = this.calculateTrayRemainingSpace(tray);
        tray.calculateBrandColors();

        return true;
    }

    private getColumn(tray: ViewModelTrayData, item: ExtendedAddProjectItemData) {
        let columnIndex = -1;

        for (let i = (tray.columns.length - 1); i > -1; i--) {
            const remaining = tray.columns[i].remainingSpace - 1 / item.item.itemLength;

            if (remaining > 0 && tray.columns[i].masterSequence === item.item.masterSequence) {
                columnIndex = i;
            }
        }

        return columnIndex;
    }

    private checkTrayRemainingSpace(tray: ViewModelTrayData, item: ExtendedAddProjectItemData) {
        const remaining = tray.remainingSpace - (1 / item.item.itemWidth);

        if (remaining < 0) {
            return false;
        }

        return true;
    }

    private checkColumnRemainingSpace(column: ViewModelColumnData, item: ExtendedAddProjectItemData) {
        const remaining = parseFloat(Math.round((column.remainingSpace - (1 / item.item.itemLength)) + 'e10' as any) + 'e-10');
        const result = isNaN(remaining) ? 0 : remaining;

        if (result < 0) {
            return false;
        }

        return true;
    }

    private calculateRemainingColumnSpace(column: ViewModelColumnData, item: ExtendedAddProjectItemData) {
        const result = parseFloat(Math.round((column.remainingSpace - (1 / item.item.itemLength)) + 'e10' as any) + 'e-10');

        return isNaN(result) ? 0 : result;
    }
}
