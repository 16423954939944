import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'organizationStatus'
})
export class OrganizationStatusPipe implements PipeTransform {
    constructor() { }

    transform(id: number): string {
        switch (id) {
            case 0:
                return 'Inactive';
            case 1:
                return 'Active';
            default:
                return '';
        }
    }
}
