import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'shared-dropdown-multiselect',
  templateUrl: './dropdown-multiselect.component.html',
  styleUrls: ['./dropdown-multiselect.component.scss']
})
export class DropdownMultiselectComponent implements OnInit {

  @Input() model: Array<number>;

  @Input() items: Array<any>;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Output() selectionChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  change() {
    this.selectionChange.emit(this.model);
  }

  checkAll(select: NgModel, values) {
    select.update.emit(values.map(_ => _.id));
    this.change();
  }

  uncheckAll(select: NgModel) {
    select.update.emit([]);
    this.change();
  }
}
