import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'uniqueId'
})
export class UniqueIdPipe implements PipeTransform {
    constructor() { }

    transform(id: number): string {
        const formatId = id ? (id).toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false }) : '';
        return `IC${formatId}`;
    }
}
