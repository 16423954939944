import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'joinSelectedNames'
})
export class JoinSelectedNamesPipe implements PipeTransform {
    constructor() { }

    transform(ids: number[], list: Array<any>): string {
        if (ids && list) {
            const filteredList = list.filter(item => ids.indexOf(item.id) > -1);
            return filteredList.map(item => item.name).join(', ');
        }
        return '';
    }
}
