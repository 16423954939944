import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatDialog } from '@angular/material';
import { AlertService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ViewModelService } from 'src/app/shared/services/view-model.service';
import { TrolleyConfiguration } from 'src/app/shared/models/generated/trolley-configuration';
import { TrolleyType } from 'src/app/shared/models/generated/trolley-type';
import { NailingTrolleyViewModel } from 'src/app/shared/models/view-model-nailing-trolley';
import { ManageNailingTrolleyComponent } from './manage-nailing-trolley/manage-nailing-trolley.component';
import { ProjectItem } from 'src/app/shared/models/generated/project-item';
import { TrayOverflowOptionsDialogComponent } from '../dialog-tray-overflow-options/dialog-tray-overflow-options.component';
import { AddItemsTrayDialogComponent } from '../dialog-add-items-tray/dialog-add-items-tray.component';
import { AddItemsToTrayData, AddProjectItemData } from 'src/app/shared/models/addItemsToTray-dialog';
import { ViewModelTrolleyRow } from 'src/app/shared/models/view-model-trolley-row';
import { ViewModelTrolleyColumn } from 'src/app/shared/models/view-model-trolley-column';
import { Util } from 'src/app/shared/util';
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ProjectItemsFilter, SubBrandInfo, ProductTypeInfo } from 'src/app/shared/models/project-items-filter';
import { IMultiSelectSettings, IMultiSelectTexts, IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DialogEditContentsComponent } from '../dialog-edit-contents/dialog-edit-contents.component';
import { EditContentsItem } from 'src/app/shared/models/edit-contents-data';
import { ViewModelTrayData } from 'src/app/shared/models/view-model-tray-data';
import { NewTrayDialogComponent } from '../dialog-new-tray/dialog-new-tray.component';
import { TrayData } from 'src/app/shared/models/generated/tray-data';
import { ViewModelColumnData } from 'src/app/shared/models/view-model-column-data';
import { ProjectStatus } from 'src/app/shared/models/generated/project-status';
import { Trolley } from 'src/app/shared/models/generated/trolley';
import { ViewModel } from 'src/app/shared/models/view-model';
import { TrayType } from 'src/app/shared/models/generated/tray-type';

@Component({
    selector: 'app-nailing-trolleys',
    templateUrl: './nailing-trolleys.component.html',
    styleUrls: ['./nailing-trolleys.component.scss']
})
export class NailingTrolleysComponent implements OnInit, OnDestroy {

    private viewModelSubscription: Subscription;
    readonly = true;
    showTrayWarning = false;
    configurations: Array<TrolleyConfiguration>;
    activeConfigurations: Array<TrolleyConfiguration>;
    trolleys: Array<NailingTrolleyViewModel>;
    activeTrolley: NailingTrolleyViewModel;
    projectItems: Array<ProjectItem>;
    filteredProjectItems: Array<ProjectItem>;
    index = 0;
    itemSelection = [];
    filter: ProjectItemsFilter;
    brands: Array<IMultiSelectOption>;
    subBrands: Array<IMultiSelectOption>;
    productTypes: Array<IMultiSelectOption>;
    openTray: ViewModelTrayData = null;
    columnsInitiatedWidth = 0;
    columnEmptyWidth = 1;

    multiselect_settings: IMultiSelectSettings = {
        dynamicTitleMaxItems: 0,
        checkedStyle: 'fontawesome',
        enableSearch: false,
        showCheckAll: true,
        showUncheckAll: true
    };

    multiselectTextsBrand: IMultiSelectTexts = {
        defaultTitle: 'Brands'
    };
    multiselectTextsSubBrand: IMultiSelectTexts = {
        defaultTitle: 'Sub-brands'
    };
    multiselectTextsProductType: IMultiSelectTexts = {
        defaultTitle: 'Product types'
    };

    selectAllChecked = false;

    constructor(
        private dialog: MatDialog,
        private alertService: AlertService,
        private translate: TranslateService,
        private viewModelService: ViewModelService) {
    }

    ngOnInit() {
        this.filter = new ProjectItemsFilter();

        this.viewModelSubscription = this.viewModelService.viewModelData.subscribe(() => {
            this.getViewModelData();
        });
    }

    dropIn(event: CdkDragDrop<number>, index) {
        let showDialog = true;

        if (event.item.data && this.itemSelection.findIndex(i => i.id == event.item.data) === -1) {
            this.itemSelection.push(this.filteredProjectItems.find(i => i.id == event.item.data));
        }

        if (!this.itemSelection.length) {
            return;
        }

        if (this.validateSelectionCompartment(index) <= 0) {
            this.alertService.error('Selected items will not fit into the selected area!');
            showDialog = false;
            this.itemSelection = [];
        }

        this.itemSelection.forEach((item) => {
            if (item.itemStorageType.toLocaleUpperCase() !== 'NAILING') {
                showDialog = false;
            }
        });

        if (showDialog) {
            const dialogData = new AddItemsToTrayData();
            dialogData.items = this.itemSelection.map(i => new AddProjectItemData(i));
            dialogData.addColumn = false;

            const dialogRef = this.dialog.open(AddItemsTrayDialogComponent);
            dialogRef.componentInstance.data = dialogData;
            dialogRef.componentInstance.trayType = null;

            dialogRef.beforeClosed().subscribe((result: AddItemsToTrayData) => {
                if (result) {

                    const remaining = this.validateSelectionCompartment(index);
                    let quantitySum = 0;

                    result.items.forEach(el => {
                        quantitySum += el.quantity;
                    });

                    if (quantitySum > remaining) {
                        this.alertService.error('Selected items will not fit into the selected area!');
                        this.itemSelection = [];
                        return;
                    }

                    result.items.forEach((element) => {
                        const quantity = element.quantity;

                        for (let i = 0; i < quantity; i++) {
                            const columnLength = this.activeTrolley.compartments[index].rows.length;
                            const empty: Array<number> = [];

                            for (let r = 0; r < columnLength; r++) {
                                if (!this.activeTrolley.compartments[index].rows[r].projectItemId) {
                                    empty.push(r);
                                }
                            }

                            const nextEmpty = Math.max(...empty.map(e => e));

                            this.activeTrolley.compartments[index].rows[nextEmpty].projectItemId = element.id;
                            this.activeTrolley.compartments[index].rows[nextEmpty].compartment = index;
                            this.activeTrolley.compartments[index].rows[nextEmpty].trolleyId = this.activeTrolley.id;
                            this.activeTrolley.compartments[index].rows[nextEmpty].itemBrand = element.itemBrand;
                            this.activeTrolley.compartments[index].rows[nextEmpty].brandColor = element.brandColor;
                        }

                        this.viewModelService.updateTrolleyCompartmentData(this.activeTrolley.id, index, this.activeTrolley.compartments[index].rows);
                    });

                    this.refreshProjectItems();
                } else {
                    this.itemSelection = [];
                }
            });
        } else {
            this.itemSelection = [];
        }
    }

    next(skipSave = false) {
        if (!skipSave) {
            this.save(false);
        }

        if (!this.trolleys || this.trolleys.length < 1) {
            this.index = 0;
            this.activeTrolley = null;
        } else if (this.index === (this.trolleys.length - 1)) {
            this.index = 0;
            this.activeTrolley = this.trolleys[this.index];
        } else {
            this.index += 1;
            this.activeTrolley = this.trolleys[this.index];
        }
    }

    back() {
        this.save(false);

        if (!this.trolleys || this.trolleys.length < 1) {
            this.index = 0;
            this.activeTrolley = null;
        } if (this.index === 0) {
            this.index = (this.trolleys.length - 1);
            this.activeTrolley = this.trolleys[this.index];
        } else {
            this.index -= 1;
            this.activeTrolley = this.trolleys[this.index];
        }
    }

    save(showMessage = true) {
        if (this.activeTrolley && !this.readonly) {
            this.viewModelService.updateNailingTrolley(this.activeTrolley).subscribe((data) => {

                const item = this.trolleys.find(t => t.id === data.id);

                if (item) {
                    data.trolleyProjectItems.forEach(pi => {
                        item.compartments[pi.compartment].rows[pi.sequenceNumber].id = pi.id;
                    });
                }

                if (showMessage) {
                    this.translate.get('SHARED.ALERT_MESSAGE.SUCCESSFULLY_SAVED').subscribe((msg: string) => {
                        this.alertService.success(msg);
                    });
                }

                this.refreshProjectItems();
            });
        }
    }

    newTrolley() {
        const dialogRef = this.dialog.open(ManageNailingTrolleyComponent);
        dialogRef.componentInstance.configurations = this.activeConfigurations;

        dialogRef.beforeClosed().subscribe((result: NailingTrolleyViewModel) => {
            if (result) {
                this.viewModelService.addNailingTrolley(result).subscribe(data => {
                    const projectData = this.viewModelService.getData();
                    this.activeTrolley = this.initializeTrolley(data, projectData);
                });
            }
        });
    }

    editTrolley(trolley: NailingTrolleyViewModel) {
        const dialogRef = this.dialog.open(ManageNailingTrolleyComponent);
        dialogRef.componentInstance.configurations = this.configurations;
        dialogRef.componentInstance.model = Util.cloneObject(trolley);

        dialogRef.beforeClosed().subscribe((result: NailingTrolleyViewModel) => {
            if (result) {
                this.viewModelService.updateNailingTrolley(result).subscribe(data => {
                    const item = this.trolleys.find(t => t.id === trolley.id);

                    if (item) {
                        item.description = data.description;

                        data.trolleyProjectItems.forEach(pi => {
                            item.compartments[pi.compartment].rows[pi.sequenceNumber].id = pi.id;
                        });
                    }
                });
            }
        });
    }

    deleteTrolley(trolley: NailingTrolleyViewModel) {
        const dialogData: ConfirmDialogData = {
            title: 'TROLLEYS.DELETE_TITLE',
            message: 'TROLLEYS.DELETE_MESSAGE'
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
        confirmDialogRef.componentInstance.dialogData = dialogData;

        confirmDialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                this.viewModelService.deleteNailingTrolley(trolley).subscribe(() => {
                    const index = this.trolleys.findIndex(t => t.id === trolley.id);

                    if (index > -1) {
                        this.trolleys.splice(index, 1);
                        this.next(true);
                    }

                    this.refreshProjectItems();
                });
            }
        });
    }

    changeSelection(event, item) {
        if (event.target.checked) {
            this.itemSelection.push(item);
        } else {
            this.itemSelection.splice(this.itemSelection.indexOf(item), 1);
        }
        if (this.itemSelection.length === this.filteredProjectItems.filter(p => p.quantity > 0).length) {
            this.selectAllChecked = true;
        } else {
            this.selectAllChecked = false;
        }
    }
    selectAlls(event) {
        if (event.target.checked) {
            this.itemSelection = [...this.filteredProjectItems.filter(p => p.quantity > 0)];
            this.selectAllChecked = true;
        } else {
            this.itemSelection = [];
            this.selectAllChecked = false
        }
    }

    changeFilter(filterType: number) {
        switch (filterType) {
            case 1:
                this.filter.subBrands = [];
                this.filter.productTypes = [];
                break;
            case 2:
                this.filter.productTypes = []
                break;
        }

        this.populateFilters();

        let filteredProjectItems = Util.cloneObject(this.projectItems);

        if (this.filter.text) {
            filteredProjectItems = filteredProjectItems.filter(_ => {

                if (!_.itemCode || !_.itemDescription) {
                    return false;
                }

                return _.itemCode.toUpperCase().includes(this.filter.text.toUpperCase()) ||
                    _.itemDescription.toUpperCase().includes(this.filter.text.toUpperCase());
            });
        }

        if (this.filter.brands && this.filter.brands.length) {
            filteredProjectItems = filteredProjectItems.filter(item => this.filter.brands.indexOf(item.itemBrand) > -1);
        }

        if (this.filter.subBrands && this.filter.subBrands.length) {
            filteredProjectItems = filteredProjectItems.filter(item => this.filter.subBrands.indexOf(item.itemSubBrand) > -1);
        }

        if (this.filter.productTypes && this.filter.productTypes.length) {
            filteredProjectItems = filteredProjectItems.filter(item => this.filter.productTypes.indexOf(item.itemProductType) > -1);
        }

        this.filteredProjectItems = filteredProjectItems;
        this.itemSelection = [];
    }

    editCompartmentContent(i: number) {
        const compartment = this.activeTrolley.compartments[i];
        let items = [];
        compartment.rows.forEach((row, idx) => {
            const projectItem = this.projectItems.find(p => p.id == row.projectItemId);
            if (projectItem) {
                const item = new EditContentsItem(projectItem, idx);
                items.push(item);
            }
        });

        if (items.length > 0) {
            const dialogRef = this.dialog.open(DialogEditContentsComponent);
            dialogRef.componentInstance.items = items;
            dialogRef.componentInstance.readonly = this.readonly;

            dialogRef.beforeClosed().subscribe((result: EditContentsItem[]) => {
                if (result) {
                    const remainingIdx = result.map(r => r.idx);
                    let newRows = [];
                    compartment.rows.forEach((row, idx) => {
                        if (remainingIdx.indexOf(idx) >= 0) {
                            newRows.push(row)
                        }
                    });

                    for (let i = newRows.length; i < compartment.rows.length; i++) {
                        newRows.unshift(new ViewModelTrolleyRow());
                    }

                    compartment.rows = newRows;
                    this.viewModelService.updateTrolleyCompartmentData(this.activeTrolley.id, i, newRows);
                    this.refreshProjectItems();
                }
            });
        }
    }

    selectTray(tray) {
        this.save(false);
        this.openTray = tray;
        this.initializeTrays();
        this.recalculateTrayWarning();
    }

    editTray() {
        const isShelf = this.openTray.trayType === TrayType.deepShelf || this.openTray.trayType === TrayType.shallowShelf;

        const dialogRef = this.dialog.open(NewTrayDialogComponent, { data: { isShelf: isShelf } });
        dialogRef.componentInstance.model = Util.cloneObject(this.openTray);

        dialogRef.beforeClosed().subscribe((trayData: TrayData) => {
            if (trayData) {
                this.viewModelService.editNailingTray(trayData, this.openTray.id).subscribe(result => {
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                });
            }
        });
    }

    chooseOverflowTrayOption(items: AddItemsToTrayData, columnIndex: number) {
        const dialogRef = this.dialog.open(TrayOverflowOptionsDialogComponent, { data: { isShelf: false, hasRoom: this.openTray.remainingSpace > 0 , isNail: true} });
        dialogRef.componentInstance.model = new TrayData();

        dialogRef.beforeClosed().subscribe((action: string) => {
            if (action === "fillTrayToCapacity") {
                if (this.openTray.remainingSpace === 0) {
                    this.alertService.error('There are no more available spaces');
                    return false;
                }

                for (let trayItem of items.items) {
                    this.viewModelService.autoAddItemsToTray(trayItem, this.openTray);
                }
                this.initializeTrays();
                this.recalculateTrayWarning();
                this.refreshProjectItems(); 

                
            }
        });
    }

    dropEmpty(event: CdkDragDrop<number>) {
        if (event.item.data && this.itemSelection.findIndex(i => i.id == event.item.data) === -1) {
            this.itemSelection.push(this.filteredProjectItems.find(p => p.id == event.item.data));
        }

        this.addItemsToTray(this.itemSelection, true, null);
    }

    dropExisting(event: CdkDragDrop<number>, columnIndex: number) {
        if (event.item.data && this.itemSelection.findIndex(i => i.id == event.item.data) === -1) {
            this.itemSelection.push(this.filteredProjectItems.find(p => p.id == event.item.data));
        }

        this.addItemsToTray(this.itemSelection, false, columnIndex);
    }

    addItemsToTray(items: ProjectItem[], addColumn: boolean, columnIndex: number) {
        if (!items.length) {
            return;
        }

        const dialogData = new AddItemsToTrayData();
        dialogData.items = items.map(i => new AddProjectItemData(i));
        dialogData.addColumn = addColumn;

        const dialogRef = this.dialog.open(AddItemsTrayDialogComponent);
        dialogRef.componentInstance.data = dialogData;
        dialogRef.componentInstance.trayType = this.openTray.trayType;

        dialogRef.beforeClosed().subscribe((result: AddItemsToTrayData) => {
            if (result) {
                const updateResult = this.viewModelService.updateNailingTrayData(result, this.openTray, addColumn, columnIndex);

                if (updateResult) {
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                } else {
                    this.chooseOverflowTrayOption(result, columnIndex);

                }
            }
            this.refreshProjectItems();
        });
    }

    saveTray(showMessage: boolean = true) {
        if (this.openTray && !this.readonly) {
            this.viewModelService.saveTray(this.openTray).subscribe(() => {
                if (showMessage) {
                    this.alertService.success('Tray data successfully saved');
                }
            });
        }
    }

    closeTray() {
        this.saveTray(false);
        this.openTray = null;
        this.recalculateTrayWarning();
    }

    editTrayContents(column: ViewModelColumnData) {
        let items = [];
        column.rows.forEach((row, idx) => {
            if (row.projectItemId) {
                const projectItem = this.projectItems.find(p => p.id == row.projectItemId);
                if (projectItem) {
                    const item = new EditContentsItem(projectItem, idx);
                    items.unshift(item);
                }
            }
        });

        if (items.length > 0) {
            const dialogRef = this.dialog.open(DialogEditContentsComponent);
            dialogRef.componentInstance.items = items;
            dialogRef.componentInstance.readonly = this.readonly;
            dialogRef.componentInstance.trayType = this.openTray.trayType;

            dialogRef.beforeClosed().subscribe((result: EditContentsItem[]) => {
                this.viewModelService.updateNailingTrayColumnContents(this.openTray, column, result);
                this.initializeTrays();
                this.recalculateTrayWarning();
                this.refreshProjectItems();
            });
        }
    }

    autoFill() {
        this.clearAutoFillData();

        this.trolleys.forEach(trolley => {
            this.projectItems.forEach(item => {
                if (item.itemStorageType.toLowerCase() === 'nailing' && item.quantity > 0) {

                    trolley.compartments.forEach((compartment, compartmentIndex) => {
                        for (let r = (compartment.rows.length - 1); r > -1; r--) {
                            if (!compartment.rows[r].projectItemId && item.quantity > 0) {
                                if (r === (compartment.rows.length - 1)) {
                                    compartment.masterSequence = item.masterSequence;

                                    compartment.rows[r].projectItemId = item.id;
                                    compartment.rows[r].compartment = compartmentIndex;
                                    compartment.rows[r].trolleyId = trolley.id;
                                    compartment.rows[r].itemBrand = item.itemBrand;
                                    compartment.rows[r].brandColor = item.brandColor;
                                    item.quantity--;
                                }

                                if (r < (compartment.rows.length - 1) && compartment.masterSequence === item.masterSequence) {
                                    compartment.rows[r].projectItemId = item.id;
                                    compartment.rows[r].compartment = compartmentIndex;
                                    compartment.rows[r].trolleyId = trolley.id;
                                    compartment.rows[r].itemBrand = item.itemBrand;
                                    compartment.rows[r].brandColor = item.brandColor;
                                    item.quantity--;
                                }
                            }
                        }

                        this.viewModelService.updateTrolleyCompartmentData(trolley.id, compartmentIndex, trolley.compartments[compartmentIndex].rows);
                    });
                }
            });

            trolley.trays.forEach((tray) => {
                this.openTray = tray;

                if (tray.trayType === TrayType.shallow) {
                    this.projectItems.forEach(item => {
                        if (item.itemStorageType.toLowerCase() === 'shallow' && item.quantity > 0) {
                            const updateResult = this.viewModelService.autoUpdateNailingTrayData(new AddProjectItemData(item), tray);

                            if (updateResult) {
                                this.initializeTrays();
                                this.recalculateTrayWarning();
                            }
                        }
                    });
                }
            });

            trolley.trays.forEach((tray) => {
                this.openTray = tray;

                if (tray.trayType === TrayType.deep) {
                    this.projectItems.forEach(item => {
                        if ((item.itemStorageType.toLowerCase() === 'deep' || item.itemStorageType.toLowerCase() === 'shallow') && item.quantity > 0) {
                            const updateResult = this.viewModelService.autoUpdateNailingTrayData(new AddProjectItemData(item), tray);

                            if (updateResult) {
                                this.initializeTrays();
                                this.recalculateTrayWarning();
                            }
                        }
                    });
                }
            });

            this.openTray = null;
        });

        this.refreshProjectItems();
    }

    private initializeTrays() {
        this.calculateColumnsWidth();
    }

    private clearAutoFillData() {
        this.viewModelService.clearTrolleysData();
        this.getViewModelData();
    }

    private calculateColumnsWidth() {
        this.columnsInitiatedWidth = this.viewModelService.getColumnsInitiatedWidth(this.openTray);
        this.columnEmptyWidth = 1 - this.columnsInitiatedWidth;
    }

    private validateSelectionCompartment(compartmentIndex: number) {
        if (compartmentIndex > -1) {
            return this.activeTrolley.compartments[compartmentIndex].rows.filter(r => !r.projectItemId).length;
        }

        return 0;
    }

    private getViewModelData() {
        this.trolleys = [];
        this.brands = [];
        this.subBrands = [];
        this.productTypes = [];

        const projectData = this.viewModelService.getData();
        this.readonly = projectData.projectStatus == ProjectStatus.finalized;

        if (projectData) {
            this.refreshProjectItems();
            this.configurations = projectData.trolleyConfiguration.filter(_ => _.trolleyType === TrolleyType.nailing && _.storageSystem === projectData.storageSystem);
            this.activeConfigurations = this.configurations.filter(_ => _.isActive);

            if (projectData.trolleys.length > 0) {
                projectData.trolleys.sort((t1, t2) => t1.sequenceNumber - t2.sequenceNumber).filter(t => t.trolleyType === TrolleyType.nailing).forEach(trolley => {
                    this.initializeTrolley(trolley, projectData);
                });

                if (this.trolleys.length > 0) {
                    this.activeTrolley = this.trolleys[0];
                }
            }
        }
    }

    private initializeTrolley(trolley: Trolley, projectData: ViewModel) {
        const trolleyVM = new NailingTrolleyViewModel(trolley);
        this.createCompartments(trolleyVM);

        for (const projectItem of trolley.trolleyProjectItems) {
            trolleyVM.compartments[projectItem.compartment].rows[projectItem.sequenceNumber] = new ViewModelTrolleyRow(projectItem);
            if (projectItem.projectItemId) {
                const projectItemInfo = projectData.projectItems.find(pi => pi.id == projectItem.projectItemId);
                if (projectItemInfo) {
                    trolleyVM.compartments[projectItem.compartment].rows[projectItem.sequenceNumber].itemBrand = projectItemInfo.itemBrand;
                    trolleyVM.compartments[projectItem.compartment].rows[projectItem.sequenceNumber].brandColor = projectItemInfo.brandColor;
                }
            }
        }

        for (const trayId of trolley.trays) {
            const tray = projectData.nailingTrays.find(t => t.id == trayId);
            if (tray) {
                trolleyVM.trays.push(tray);
            }
        }

        this.trolleys.push(trolleyVM);
        return trolleyVM;
    }

    private refreshProjectItems() {
        const showTypes = ['SHALLOW', 'DEEP', 'NAILING'];
        const remainingProjectItems = this.viewModelService.calculateRemainingProjectItems();
        this.projectItems = remainingProjectItems.filter(i => i.itemStorageType && showTypes.indexOf(i.itemStorageType.toLocaleUpperCase()) > -1) || [];
        this.projectItems.sort((a, b) => Util.localeCompareSafe(a.masterSequence, b.masterSequence));

        this.changeFilter(0);
    }

    private createCompartments(trolley: NailingTrolleyViewModel) {
        const trolleyConfiguration = this.configurations.find(c => c.id === trolley.trolleyConfigurationId);

        if (trolleyConfiguration) {

            if (trolleyConfiguration.nailsCount.length > 0) {

                trolleyConfiguration.nailsCount.forEach(e => {

                    for (let c = 0; c < 4; ++c) {
                        const rows = new Array<ViewModelTrolleyRow>();

                        for (let r = 0; r < e; ++r) {
                            rows.push(new ViewModelTrolleyRow());
                        }
                        const compartment = new ViewModelTrolleyColumn();
                        compartment.rows = rows;

                        trolley.compartments.push(compartment);
                    }
                });
            } else {
                for (let c = 0; c < trolleyConfiguration.compartmentsCount; c++) {

                    const rows = new Array<ViewModelTrolleyRow>();
                    for (let r = 0; r < 12; r++) {
                        rows.push(new ViewModelTrolleyRow());
                    }

                    const compartment = new ViewModelTrolleyColumn();
                    compartment.rows = rows;

                    trolley.compartments.push(compartment);
                }
            }
        }
    }

    private populateFilters() {
        let brands = [];
        let subBrands = new Array<SubBrandInfo>();
        let productTypes = new Array<ProductTypeInfo>();

        if (this.projectItems) {
            this.projectItems.forEach(item => {
                if (item.quantity > 0) {
                    if (item.itemBrand && brands.indexOf(item.itemBrand) === -1) {
                        brands.push(item.itemBrand);
                    }

                    if (item.itemSubBrand && !subBrands.some(sb => sb.subBrand == item.itemSubBrand)) {
                        subBrands.push(new SubBrandInfo(item));
                    }

                    if (item.itemProductType && !productTypes.some(pt => pt.productType == item.itemProductType)) {
                        productTypes.push(new ProductTypeInfo(item));
                    }
                }
            });
        }

        brands = brands.sort((a, b) => a > b ? 1 : -1);
        subBrands = subBrands.sort((a, b) => a.subBrand > b.subBrand ? 1 : -1);
        productTypes = productTypes.sort((a, b) => a.productType > b.productType ? 1 : -1);

        this.brands = brands.map(item => {
            return { id: item, name: item };
        });

        // make sure brand is not selected if not available
        if (this.filter.brands && this.filter.brands.length > 0) {
            this.filter.brands = this.filter.brands.filter(b => brands.indexOf(b) >= 0);
        }

        // filter out sub brands and product types by selected brands
        if (this.filter.brands && this.filter.brands.length > 0) {
            subBrands = subBrands.filter(sb => this.filter.brands.indexOf(sb.brand) >= 0);
            productTypes = productTypes.filter(pt => this.filter.brands.indexOf(pt.brand) >= 0);
        }

        this.subBrands = subBrands.map(item => {
            return { id: item.subBrand, name: item.subBrand };
        });

        // make sure sub brand is not selected if not available
        if (this.filter.subBrands && this.filter.subBrands.length > 0) {
            this.filter.subBrands = this.filter.subBrands.filter(fsb => subBrands.some(sb => sb.subBrand == fsb));
        }

        // filter product types by subbrands
        if (this.filter.subBrands && this.filter.subBrands.length > 0) {
            productTypes = productTypes.filter(pt => this.filter.subBrands.indexOf(pt.subBrand) >= 0);
        }

        this.productTypes = productTypes.map(item => {
            return { id: item.productType, name: item.productType };
        });

        // make sure product type is not selected if not available
        if (this.filter.productTypes && this.filter.productTypes.length > 0) {
            this.filter.productTypes = this.filter.productTypes.filter(fpt => productTypes.some(pt => pt.productType == fpt));
        }
    }

    private recalculateTrayWarning() {
        this.showTrayWarning = false;
        if (this.openTray && this.openTray.trayType == TrayType.shallow) {
            for (const trayColumn of this.openTray.columns) {
                for (const trayRow of trayColumn.rows) {
                    const projectItem = this.projectItems.find(p => p.id == trayRow.projectItemId);
                    if (projectItem && projectItem.itemStorageType && projectItem.itemStorageType.toLocaleUpperCase() == 'DEEP') {
                        this.showTrayWarning = true;
                        return;
                    }
                }
            }
        }
    }

    ngOnDestroy() {
        this.viewModelSubscription.unsubscribe();
    }
}
