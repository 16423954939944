import { ViewModelTrayData } from './view-model-tray-data';
import { Trolley } from './generated/trolley';
import { ViewModelTrolleyData } from './view-model-trolley-data';
import { TrolleySize } from './generated/trolley-size';

export class TrayTrolleyViewModel {
    id: number;
    description: string;
    trolleySize: TrolleySize;
    sequenceNumber: number;
    trays: ViewModelTrayData[];

    constructor(model?: Trolley) {
        if (model) {
            this.id = model.id;
            this.description = model.description;
            this.trolleySize = model.trolleySize;
            this.sequenceNumber = model.sequenceNumber;
        }

        this.trays = [];
    }
}
