import { ViewModelTrolleyRow } from './view-model-trolley-row';

export class ViewModelTrolleyColumn {
    public rows: ViewModelTrolleyRow[];
    public masterSequence: string;

    constructor() {
        this.rows = [];
        this.masterSequence = null;
    }
}
