import { Component, OnInit } from '@angular/core';
import { AlertService, LoaderService } from 'src/app/shared/services';

import { Page } from 'src/app/shared/models/datatables';
import { Router } from '@angular/router';
import { ProjectListData } from 'src/app/shared/models/generated/project-list-data';
import { ProjectsService } from 'src/app/shared/services/projects.service';

import { MatDialog } from '@angular/material';
import { NewProjectDialogComponent } from './dialog-new-project/dialog-new-project.component';
import { ProjectData } from 'src/app/shared/models/generated/project-data';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog';
import { DialogShareProjectComponent } from './dialog-share-project/dialog-share-project.component';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

    uploadedFile: File;

    list: ProjectListData[];
    originalList: ProjectListData[];
    searchItems = [];
    page = new Page;
    searchValue: string;

    // Datatables query params
    offset = 1;
    limit = 20;
    filter = '';
    order_column = 1;
    order_direction = 'asc';

    constructor(
        private loaderService: LoaderService,
        private router: Router,
        private projectsService: ProjectsService,
        private dialog: MatDialog,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.onDisplayPage();
    }

    onDisplayPage() {
        const obs$ = this.projectsService.getApi();

        this.loaderService.wrap(obs$).subscribe(result => {
            this.originalList = result;
            this.populateSearch();
            this.filterList(null);
        });
    }

    setPage(pageInfo) {
        this.offset = pageInfo.offset * this.page.size;
        this.page.size = pageInfo.limit;
    }

    createProject() {
        const dialogRef = this.dialog.open(NewProjectDialogComponent);

        dialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                const obs$ = this.projectsService.createProject(result);
                this.loaderService.wrap(obs$).subscribe((item) => {
                    this.router.navigate(['project/' + item.id]);
                });
            }
        });
    }

    editProject(item) {
        this.router.navigate(['project/' + item.id]);
    }

    shareProject(item: ProjectListData) {
        const dialogRef = this.dialog.open(DialogShareProjectComponent);

        dialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                const obs$ = this.projectsService.shareProject(item.id, result);
                this.loaderService.wrap(obs$).subscribe(() => {
                    if (item.sharedWithUsers && item.sharedWithUsers.indexOf(result) < 0) {
                        item.sharedWithUsers.push(result);
                    }
                    
                    this.alertService.success('SHARED.ALERT_MESSAGE.SUCCESSFULLY_SHARED');
                });
            }
        });
    }

    duplicate(item: ProjectListData) {
        const dialogRef = this.dialog.open(NewProjectDialogComponent);

        dialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                const obs$ = this.projectsService.duplicateProject(item.id, result);
                this.loaderService.wrap(obs$).subscribe((item) => {
                    this.router.navigate(['project/' + item.id]);
                });
            }
        });
    }

    deleteProject(item) {
        const dialogData: ConfirmDialogData = {
            title: 'PROJECT.DELETE_PROJECT_TITLE',
            message: 'PROJECT.DELETE_PROJECT_MESSAGE'
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
        confirmDialogRef.componentInstance.dialogData = dialogData;

        confirmDialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                this.projectsService.deleteProject(item.id).subscribe((response) => {
                    this.onDisplayPage();
                });
            }
        });
    }

    filterList(filter: string) {
        if (this.originalList) {
            if (filter) {
                const filterValue = filter.toLocaleLowerCase();
                this.list = this.originalList.filter(p => 
                    p.name.toLocaleLowerCase().indexOf(filterValue) !== -1 ||
                    p.userName.toLocaleLowerCase().indexOf(filterValue) !== -1);
            } else {
                this.list = this.originalList;
            }
        } else {
            this.list = [];
        }

        this.page.totalPages = Math.floor(this.list.length / this.page.size);
        this.page.totalElements = this.list.length;
    }

    private populateSearch() {
        const searchNames = [];
        const searchUserNames = [];
        
        if (this.originalList) {
            for (const project of this.originalList) {
                if (!searchNames.includes(project.name)) {
                    searchNames.push(project.name);
                }
                if (!searchUserNames.includes(project.userName)) {
                    searchUserNames.push(project.userName);
                }
            }
        }

        this.searchItems = searchNames.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
            .concat(searchUserNames.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())));
    }
}
