import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_URL } from '../../../environments/environment';
import { ProjectListData } from '../models/generated/project-list-data';
import { TrolleyLabel } from '../models/generated/trolley-label';
import { TrolleyLabelBase } from '../models/generated/trolley-label-base';
import { ProjectUpdateData } from '../models/generated/project-update-data';
import { TrolleyConfiguration } from '../models/generated/trolley-configuration';
import { TrolleyData } from '../models/generated/trolley-data';
import { Trolley } from '../models/generated/trolley';
import { TrayBase } from '../models/generated/tray-base';
import { Tray } from '../models/generated/tray';
import { TrolleyBase } from '../models/generated/trolley-base';
import { TrolleySequenceData } from '../models/generated/trolley-sequence-data';
import { Project } from '../models/generated/project';
import { TrolleyWithTrays } from '../models/generated/trolley-with-trays';
import { FileData } from '../models/generated/file-data';
import { TrayUpdateData } from '../models/generated/tray-update-data';

@Injectable()
export class ProjectsService {

    constructor(
        private apiService: ApiService) { }

    private urls = {
        Projects: API_URL + 'Projects',
        ProjectItem: API_URL + 'Projects/Items',
        StartProject: API_URL + 'Projects/CreateProject',
        Trays: 'Trays',
        TrolleyLabels: 'TrolleyLabels',
        TrolleyConfigurations: API_URL + 'TrolleyConfigurations',
        Trolleys: 'Trolleys',
        Export: 'Export'
    };

    getApi() {
        return this.apiService.get<ProjectListData[]>(`${this.urls.Projects}`);
    }

    getProject(id) {
        return this.apiService.get<Project>(`${this.urls.Projects}/${id}`);
    }

    updateProject(id, data) {
        return this.apiService.post<ProjectUpdateData>(`${this.urls.Projects}/${id}`, data);
    }

    createProject(data) {
        return this.apiService.post(`${this.urls.StartProject}`, data);
    }

    duplicateProject(id: number, data) {
        return this.apiService.post(`${this.urls.Projects}/${id}/duplicate`, data);
    }

    shareProject(id: number, email: string) {
        return this.apiService.postString(`${this.urls.Projects}/${id}/share`, email);
    }

    finalizeProject(id: number) {
        return this.apiService.post(`${this.urls.Projects}/${id}/finalize`, null);
    }

    deleteProject(id) {
        return this.apiService.delete(`${this.urls.Projects}/${id}`);
    }

    createProjectItem(id, data) {
        return this.apiService.post(`${this.urls.Projects}/${id}/Items`, data);
    }

    updateProjectItemQuantity(id, data) {
        return this.apiService.post(`${this.urls.ProjectItem}/${id}`, data);
    }

    deleteProjectItem(id) {
        return this.apiService.delete(`${this.urls.ProjectItem}/${id}`);
    }

    addTrays(projectId, data) {
        return this.apiService.post<Tray>(`${this.urls.Projects}/${projectId}/${this.urls.Trays}`, data);
    }

    editTray(projectId, trayId, data) {
        return this.apiService.put<Tray>(`${this.urls.Projects}/${projectId}/${this.urls.Trays}/${trayId}/updatename`, data);
    }

    updateTray(projectId, trayId, data) {
        return this.apiService.put(`${this.urls.Projects}/${projectId}/${this.urls.Trays}/${trayId}`, data);
    }

    deleteTray(projectId: number, trayId: number) {
        return this.apiService.delete(`${this.urls.Projects}/${projectId}/${this.urls.Trays}/${trayId}`);
    }

    createTrays(projectId: number, model: TrayUpdateData[]) {
        return this.apiService.post<Array<Tray>>(`${this.urls.Projects}/${projectId}/${this.urls.Trays}/bulk`, model);
    }

    importProjectsFile(file: File, id: Number) {
        const formData = new FormData();
        formData.append('file', file);

        return this.apiService.post(`${this.urls.Projects}/${id}/ImportItems`, formData);
    }

    getTrolleyConfiguration() {
        return this.apiService.get<TrolleyConfiguration[]>(this.urls.TrolleyConfigurations);
    }

    createTrolley(projectId: number, data: TrolleyData) {
        return this.apiService.post<TrolleyWithTrays>(`${this.urls.Projects}/${projectId}/${this.urls.Trolleys}`, data);
    }

    updateTrolley(projectId: number, id: number, data: TrolleyBase) {
        return this.apiService.put<Trolley>(`${this.urls.Projects}/${projectId}/${this.urls.Trolleys}/${id}`, data);
    }

    deleteTrolley(projectId: number, id: number) {
        return this.apiService.delete(`${this.urls.Projects}/${projectId}/${this.urls.Trolleys}/${id}`);
    }

    reorganizeTrolleys(projectId: number, data: TrolleySequenceData[]) {
        return this.apiService.post(`${this.urls.Projects}/${projectId}/${this.urls.Trolleys}/reorganize`, data);
    }

    exportDetailsXlsx(projectId: number) {
        return this.apiService.get<FileData>(`${this.urls.Projects}/${projectId}/${this.urls.Export}/detailsxlsx`);
    }

    exportDetailsPdf(projectId: number) {
        return this.apiService.get<FileData>(`${this.urls.Projects}/${projectId}/${this.urls.Export}/detailspdf`);
    }

    exportSummaryPdf(projectId: number) {
        return this.apiService.get<FileData>(`${this.urls.Projects}/${projectId}/${this.urls.Export}/summarypdf`);
    }
}
