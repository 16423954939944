import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-share-project',
  templateUrl: './dialog-share-project.component.html',
  styleUrls: ['./dialog-share-project.component.scss']
})
export class DialogShareProjectComponent implements OnInit {

    email: string;

    constructor(
        private dialogRef: MatDialogRef<DialogShareProjectComponent>) { }

    ngOnInit() {
    }

    save() {
        this.dialogRef.close(this.email);
    }

    close() {
        this.dialogRef.close(null);
    }

}
