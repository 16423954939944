import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/services/authentication.service';

@Injectable()
export class AccessGuard implements CanActivate {
    constructor(private authService: AuthenticationService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        const roles = route.data.roles || [];
        if (this.authService.roleAccess(roles)) { return true; }
        if (this.authService.isLoggedIn()) { this.router.navigate(['/']); return true; }
        this.router.navigate(['/login']);
        return false;
    }
}
