import { TrolleyProjectItem } from './generated/trolley-project-item';

export class ViewModelTrolleyRow {
    public id: number;
    public trolleyId: number;
    public projectItemId: number;
    public compartment: number;
    public sequenceNumber: number;
    public itemBrand: string;
    public brandColor: string;

    constructor(data?: TrolleyProjectItem) {
        if (data) {
            this.id = data.id;
            this.trolleyId = data.trolleyId;
            this.projectItemId = data.projectItemId;
            this.compartment = data.compartment;
            this.sequenceNumber = data.sequenceNumber;
        }
    }
}
