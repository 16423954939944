import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrayData } from 'src/app/shared/models/generated/tray-data';
import { TrayType } from 'src/app/shared/models/generated/tray-type';

@Component({
    selector: 'app-dialog-tray-overflow-options',
    templateUrl: './dialog-tray-overflow-options.component.html',
    styleUrls: ['./dialog-tray-overflow-options.component.scss']
})
export class TrayOverflowOptionsDialogComponent implements OnInit {

    title: string;
    model: TrayData;
    canEditType: boolean;
    TRAY_TYPE = TrayType;
    edit = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<TrayOverflowOptionsDialogComponent>) { }

    ngOnInit() {
        if (!this.model) {
            this.model = new TrayData();
            this.canEditType = true;
            this.title = this.data.isShelf ? 'SHELF.NEW' : 'TRAY.NEW';
        } else {
            this.edit = true;
            this.title = this.data.isShelf ? 'SHELF.NOT_ENOUGH_SPACE' : 'TRAY.NOT_ENOUGH_SPACE';
        }
    }

    close() {
        this.dialogRef.close(false);
    }

    addToNewTray() {
        this.dialogRef.close("createNewTray");
    }

    fillTrayToCapacity() {
        this.dialogRef.close("fillTrayToCapacity");
    }
}
