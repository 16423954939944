﻿import { TrayBase } from './tray-base';
import { SubBrandInfo } from './sub-brand-info';

export class Tray extends TrayBase {
    public id: number;
    public subBrands: SubBrandInfo[];

    constructor() {
        super();
        this.subBrands = [];
    }
}
