﻿import { TrayData } from './tray-data';
import { TrayProjectItem } from './tray-project-item';

export class TrayBase extends TrayData {
    public projectId: number;
    public trolleyId: number;
    public sequenceNumberTrolley: number;
    public items: TrayProjectItem[];

    constructor() {
        super();
        this.items = [];
    }
}
