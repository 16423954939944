import { ProjectItem } from './generated/project-item';

export class OverviewContentsItem {
    itemId: number;
    itemCode: string;
    itemDescription: string;
    itemBrand: string;
    brandColor: string;
    quantity: number;
    comment: string;
    isDeep: boolean;

    constructor(item?: ProjectItem, quantity?: number) {
        if (item) {
            this.itemId = item.id;
            this.itemCode = item.itemCode;
            this.itemDescription = item.itemDescription;
            this.itemBrand = item.itemBrand;
            this.brandColor = item.brandColor;
            this.comment = item.comment;
            this.isDeep = item.itemStorageType && item.itemStorageType.toLocaleUpperCase() == 'DEEP';
        }

        this.quantity = quantity;
    }
}