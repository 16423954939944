import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'organizationType'
})
export class OrganizationTypePipe implements PipeTransform {
    constructor() { }

    transform(id: number): string {
        switch (id) {
            case 1:
                return 'Distributor';
            case 2:
                return 'Agent';
            case 3:
                return 'General Agent';
            case 4:
                return 'Technical Service Provider';
            default:
                return '';
        }
    }
}
