import { ViewModelTrayData } from './view-model-tray-data';
import { Trolley } from './generated/trolley';
import { ViewModelTrolleyColumn } from './view-model-trolley-column';

export class NailingTrolleyViewModel {
    id: number;
    description: string;
    sequenceNumber: number;
    trolleyConfigurationId: number;
    trays: ViewModelTrayData[];
    compartments: Array<ViewModelTrolleyColumn>;

    constructor(model?: Trolley) {
        if (model) {
            this.id = model.id;
            this.description = model.description;
            this.sequenceNumber = model.sequenceNumber;
            this.trolleyConfigurationId = model.trolleyConfigurationId;
        }

        this.trays = [];
        this.compartments = [];
    }
}
