import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from '../models/confirm-dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogData
    ) { }

    ngOnInit() {
        this.dialogData.buttonClose = this.dialogData.buttonClose ? this.dialogData.buttonClose : 'SHARED.NO';
        this.dialogData.buttonConfirm = this.dialogData.buttonConfirm ? this.dialogData.buttonConfirm : 'SHARED.YES';
    }

    close() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close(true);
    }

}
