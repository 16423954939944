import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ViewModel } from 'src/app/shared/models/view-model';
import { TrolleyConfiguration } from 'src/app/shared/models/generated/trolley-configuration';
import { TrolleyType } from 'src/app/shared/models/generated/trolley-type';
import { StorageSystem } from 'src/app/shared/models/generated/storage-system';
import { ViewModelTrayData } from 'src/app/shared/models/view-model-tray-data';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TrayType } from 'src/app/shared/models/generated/tray-type';
import { ViewModelService } from 'src/app/shared/services/view-model.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { ManageTrolleyComponent } from './manage-trolley/manage-trolley.component';
import { TrayTrolleyViewModel } from 'src/app/shared/models/view-model-trolleys';
import { AlertService } from 'src/app/shared/services';
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStatus } from 'src/app/shared/models/generated/project-status';
import { TrolleySize } from 'src/app/shared/models/generated/trolley-size';

@Component({
    selector: 'app-assemble-trolleys',
    templateUrl: './assemble-trolleys.component.html',
    styleUrls: ['./assemble-trolleys.component.scss']
})
export class AssembleTrolleysComponent implements OnInit, OnDestroy {

    private viewModelSubscription: Subscription;

    readonly = true;
    configurations: Array<TrolleyConfiguration>;
    configurationGroups: Array<{ key: TrolleySize, configurations: Array<TrolleyConfiguration> }>;
    trays: Array<ViewModelTrayData>;
    trolleys: Array<TrayTrolleyViewModel>;
    activeTrolley: TrayTrolleyViewModel;
    index = 0;
    totalShallow: number;
    totalShallowShelf: number;
    totalDeep: number;
    totalDeepShelf: number;
    columnsInitiatedWidth = {};
    columnEmptyWidth = {};
    storageSystem: string;
    trayTypes: Array<string>;
    trayType = TrayType;

    constructor(
        private dialog: MatDialog,
        private alertService: AlertService,
        private translate: TranslateService,
        private viewModelService: ViewModelService) {
        this.viewModelSubscription = this.viewModelService.viewModelData.subscribe(() => {
            this.getViewModelData();
        });
    }

    ngOnInit() {
        this.trayTypes = ['TRAY_TYPE.SHALLOW', 'TRAY_TYPE.DEEP', 'TRAY_TYPE.SHALLOW_SHELF', 'TRAY_TYPE.DEEP_SHELF'];
    }

    dropIn(event: CdkDragDrop<ViewModelTrayData[]>) {
        if (event.container === event.previousContainer) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            const tray = this.trays[event.previousIndex];
            const drop = this.validateTrayConfigurations(tray);

            if (tray && drop) {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);

                this.activeTrolley.trays = event.container.data;
            } else {
                this.alertService.error('Can not add tray because it will not fit!');
            }
        }
    }

    next(skipSave = false) {
        if (!skipSave) {
            this.save(false);
        }

        if (!this.trolleys || this.trolleys.length < 1) {
            this.index = -1;
            this.activeTrolley = null;
        } if (this.index >= (this.trolleys.length - 1)) {
            this.index = 0;
            this.activeTrolley = this.trolleys[this.index];
        } else if (this.index < this.trolleys.length - 1) {
            this.index += 1;
            this.activeTrolley = this.trolleys[this.index];
        }
    }

    back() {
        this.save(false);

        if (this.index <= 0) {
            this.index = (this.trolleys.length - 1);
            this.activeTrolley = this.trolleys[this.index];
        } else {
            this.index -= 1;
            this.activeTrolley = this.trolleys[this.index];
        }
    }

    removeTray(trolley: TrayTrolleyViewModel, tray: ViewModelTrayData) {
        const trayIndex = trolley.trays.findIndex(t => t.id === tray.id);

        if (trayIndex > -1) {
            this.trays.push(trolley.trays[trayIndex]);
            trolley.trays[trayIndex].trolleyId = null;
            trolley.trays[trayIndex].sequenceNumberTrolley = null;
            trolley.trays.splice(trayIndex, 1);
            this.trays = this.trays.sort((t1, t2) => t1.sequenceNumber - t2.sequenceNumber);
        }
    }

    save(showMessage = true) {
        if (this.activeTrolley && !this.readonly) {
            this.viewModelService.updateTrayTrolley(this.activeTrolley).subscribe(() => {
                if (showMessage) {
                    this.translate.get('SHARED.ALERT_MESSAGE.SUCCESSFULLY_SAVED').subscribe((msg: string) => {
                        this.alertService.success(msg);
                    });
                }
            });
        }
    }

    newTrolley() {
        const dialogRef = this.dialog.open(ManageTrolleyComponent);
        dialogRef.componentInstance.trolleySizes = this.configurationGroups.map(_ => _.key);

        dialogRef.beforeClosed().subscribe((result: TrayTrolleyViewModel) => {
            if (result) {
                this.viewModelService.addTrayTrolley(result).subscribe(data => {
                    this.trolleys.push(data);
                    this.index = this.trolleys.indexOf(data);
                    this.activeTrolley = data;
                });
            }
        });
    }

    editTrolley(trolley: TrayTrolleyViewModel) {
        const dialogRef = this.dialog.open(ManageTrolleyComponent);
        dialogRef.componentInstance.model = JSON.parse(JSON.stringify(trolley));
        dialogRef.componentInstance.trolleySizes = this.configurationGroups.map(_ => _.key);

        dialogRef.beforeClosed().subscribe((result: TrayTrolleyViewModel) => {
            if (result) {
                this.viewModelService.updateTrayTrolley(result).subscribe(data => {
                    const index = this.trolleys.findIndex(t => t.id === trolley.id);

                    if (index > -1) {
                        this.trolleys[index].description = data.description;
                    }
                });
            }
        });
    }

    deleteTrolley(trolley: TrayTrolleyViewModel) {
        const dialogData: ConfirmDialogData = {
            title: 'TROLLEYS.DELETE_TITLE',
            message: 'TROLLEYS.DELETE_MESSAGE'
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
        confirmDialogRef.componentInstance.dialogData = dialogData;

        confirmDialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                this.viewModelService.deleteTrayTrolley(trolley).subscribe(() => {
                    const index = this.trolleys.findIndex(t => t.id === trolley.id);

                    if (index > -1) {

                        this.trolleys[index].trays.forEach(tray => {
                            if (tray) {
                                tray.trolleyId = null;
                                tray.sequenceNumberTrolley = null;
                                this.trays.push(tray);
                                this.trays = this.trays.sort((t1, t2) => t1.sequenceNumber - t2.sequenceNumber);
                            }
                        });

                        this.trolleys.splice(index, 1);
                        this.next(true);
                    }
                });
            }
        });
    }

    getNameLines(name: string): string[] {
        if(name){
            return name.split(/[\n,\r]/);
        }
        return [];
    }

    private validateTrayConfigurations(tray: ViewModelTrayData) {
        let deepCount = 0;
        let shallowCount = 0;

        for (let tray of this.activeTrolley.trays) {
            if (tray.trayType == TrayType.deep || tray.trayType == TrayType.deepShelf) {
                deepCount++;
            } else if (tray.trayType == TrayType.shallow || tray.trayType == TrayType.shallowShelf) {
                shallowCount++;
            }
        }

        if (tray.trayType == TrayType.deep || tray.trayType == TrayType.deepShelf) {
            deepCount++;
        } else if (tray.trayType == TrayType.shallow || tray.trayType == TrayType.shallowShelf) {
            shallowCount++;
        }

        return this.configurations.some(c => c.deepCount >= deepCount && c.shallowCount >= shallowCount && c.trolleySize === this.activeTrolley.trolleySize);
    }

    private getViewModelData() {
        this.trolleys = [];
        const projectData = this.viewModelService.getData();
        this.readonly = projectData.projectStatus == ProjectStatus.finalized;

        if (projectData) {
            this.trays = projectData.trays.filter(t => !t.trolleyId);
            for (let tray of projectData.trays) {
                this.columnsInitiatedWidth[tray.id] = this.viewModelService.getColumnsInitiatedWidth(tray);
                this.columnEmptyWidth[tray.id] = 1 - this.columnsInitiatedWidth[tray.id];
            }

            this.totalDeep = projectData.trays.filter(t => t.trayType === TrayType.deep).length;
            this.totalDeepShelf = projectData.trays.filter(t => t.trayType === TrayType.deepShelf).length;
            this.totalShallowShelf = projectData.trays.filter(t => t.trayType === TrayType.shallowShelf).length;
            this.totalShallow = projectData.trays.filter(t => t.trayType === TrayType.shallow).length;

            switch (projectData.storageSystem) {
                case StorageSystem.mobile:
                    this.storageSystem = 'mobile';
                    break;
                case StorageSystem.fixed:
                    this.storageSystem = 'fixed';
                    break;
                default:
                    this.storageSystem = '';
                    break;
            }

            this.configurations = projectData.trolleyConfiguration
                .filter(_ => _.trolleyType === TrolleyType.trays && _.storageSystem === projectData.storageSystem && _.trolleyDoorType === projectData.trolleyDoorType)
                .sort((c1, c2) => {
                    if (c1.shallowCount !== c2.shallowCount) {
                        return c2.shallowCount - c1.shallowCount;
                    } else {
                        return c2.deepCount - c1.deepCount;
                    }
                });

            const configurationsMap = new Map<TrolleySize, Array<TrolleyConfiguration>>();
            this.configurations.forEach(configuration => {
                const key = configuration.trolleySize;
                if (!configurationsMap.has(key)) {
                    configurationsMap.set(key, [configuration]);
                } else {
                    const value = configurationsMap.get(key);
                    value.push(configuration);
                }
            });

            this.configurationGroups = new Array<{ key: TrolleySize, configurations: Array<TrolleyConfiguration> }>();
            for (let [key, configurations] of configurationsMap) {
                this.configurationGroups.push({ key, configurations });
            }

            if (projectData.trolleys.length > 0) {
                projectData.trolleys.sort((t1, t2) => t1.sequenceNumber - t2.sequenceNumber).filter(t => t.trolleyType === TrolleyType.trays).forEach(trolley => {
                    const trolleyVM = new TrayTrolleyViewModel(trolley);
                    for (const trayId of trolley.trays) {
                        const tray = projectData.trays.find(t => t.id === trayId);
                        if (tray) {
                            trolleyVM.trays.push(tray);
                        }
                    }

                    this.trolleys.push(trolleyVM);
                });

                if (this.trolleys.length > 0) {
                    this.trolleys = [...this.trolleys];
                    this.index = 0;
                    this.activeTrolley = this.trolleys[0];
                }
            }
        }

        console.log(this.activeTrolley);
    }

    ngOnDestroy() {
        this.viewModelSubscription.unsubscribe();
    }
}
