import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../shared/services';
import { LoginData } from '../models/login-data';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'authentication-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService) { }

    model: LoginData;
    showSso = false;

    ngOnInit() {
        localStorage.clear();
        this.model = new LoginData();
        // never show SSO inside dink environment
        this.showSso = environment.ssoUrl && !environment.dink ? true : false;
    }

    loginUser(form: NgForm) {
        if (form.valid) {
            this.authenticationService.login(this.model)
                .subscribe(
                    () => {
                        this.router.navigate(['/']);
                    }, error => {
                        this.alertService.error(error);
                    }
                );
        }
    }

    loginSSO() {
        if (environment.ssoUrl) {
            window.location.href = environment.ssoUrl;
        }
    }
}
