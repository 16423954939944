import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';

@Component({
  selector: 'shared-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  bsConfig: Partial<BsDatepickerConfig>;
  @Input() model: Date;
  @Input() name: string;
  @Input() disabled: boolean;
  @Output() changeDate = new EventEmitter<Date>();

  constructor() { }

  ngOnInit() {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-orange',
      dateInputFormat: 'DD/MM/YYYY'
    });
  }

  onChangeDate(date: Date) {
    this.changeDate.emit(date);
  }

}
