import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { LoaderService } from 'src/app/shared/services';
import { Project } from 'src/app/shared/models/generated/project';
import { ViewModel } from 'src/app/shared/models/view-model';
import { ViewModelService } from 'src/app/shared/services/view-model.service';
import { forkJoin } from 'rxjs';
import { ProjectStatus } from 'src/app/shared/models/generated/project-status';
import { SelectStorageComponent } from './select-storage/select-storage.component';
import { ConfigureTraysComponent } from './configure-trays/configure-trays.component';
import { NailingTrolleysComponent } from './nailing-trolleys/nailing-trolleys.component';
import { AssembleTrolleysComponent } from './assemble-trolleys/assemble-trolleys.component';
import { TrolleyReviewComponent } from './trolley-review/trolley-review.component';
import { FileHelperService } from 'src/app/shared/services/file-helper.service';

@Component({
  selector: 'app-project-items',
  templateUrl: './project-items.component.html',
  styleUrls: ['./project-items.component.scss']
})

export class ProjectItemsComponent implements OnInit {

    activeStep = 0;
    project_id: number;
    projectData: ViewModel;

    @ViewChild(SelectStorageComponent)
    private selectStorage: SelectStorageComponent;

    @ViewChild(ConfigureTraysComponent)
    private configureTrays: ConfigureTraysComponent;

    @ViewChild(AssembleTrolleysComponent)
    private assembleTrolleys: AssembleTrolleysComponent;

    @ViewChild(NailingTrolleysComponent)
    private nailingTrolleys: NailingTrolleysComponent;

    @ViewChild(TrolleyReviewComponent)
    private trolleyReview: TrolleyReviewComponent;

    steps = {
        1: 'PROJECT.UPLOAD',
        2: 'PROJECT.STORAGE',
        3: 'PROJECT.TRAYS',
        4: 'PROJECT.ASSEMBLE_TROLLEYS',
        5: 'PROJECT.NAILING',
        6: 'PROJECT.REVIEW',
        // 7: 'PROJECT.LABELS',
    };

    constructor(
        private projectsService: ProjectsService,
        private route: ActivatedRoute,
        private loaderService: LoaderService,
        private viewModelService: ViewModelService,
        private fileHelperService: FileHelperService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.project_id = params['id'];
            this.getProjectDetails();
        });
    }

    setStep(step) {
        if (this.steps[step]) {
            if (this.projectData.projectStatus == ProjectStatus.draft) {
                if (this.activeStep == 2 && this.selectStorage) {
                    this.selectStorage.save(false);
                } else if (this.activeStep == 3 && this.configureTrays) {
                    this.configureTrays.updateTray(false);
                } else if (this.activeStep == 4 && this.assembleTrolleys) {
                    this.assembleTrolleys.save(false);
                } else if (this.activeStep == 5 && this.nailingTrolleys) {
                    if (this.nailingTrolleys.openTray) {
                        this.nailingTrolleys.saveTray(false);
                    } else {
                        this.nailingTrolleys.save(false);
                    }
                } else if (this.activeStep == 6 && this.trolleyReview) {
                    this.trolleyReview.save(false);
                }
            }
            
            this.activeStep = step;
        }
    }

    getProjectDetails(step?: number) {
        const obs$ = forkJoin([
            this.projectsService.getProject(this.project_id),
            this.projectsService.getTrolleyConfiguration()
        ]);

        this.loaderService.wrap(obs$).subscribe((response: any) => {
            this.projectData = this.viewModelService.initializeModel(response[0]);
            this.projectData.trolleyConfiguration = response[1];

            if (step) {
                this.activeStep = step;
            }
            else if (this.projectData.projectStatus == ProjectStatus.draft && this.projectData.storageSystem) {
                this.activeStep = 3;
            } else {
                this.activeStep = 1;
            }
        });
    }

    onFinalized(finalized: boolean) {
        if (finalized) {
            this.projectData = this.viewModelService.getData();
            this.activeStep = 1;
        }
    }

    exportDetailsPdf() {
        this.loaderService.wrapObservable(
            this.projectsService.exportDetailsPdf(this.project_id)
        ).subscribe(file => {
            this.fileHelperService.saveFileData(file);
        });
    }

    exportDetailsXlsx() {
        this.loaderService.wrapObservable(
            this.projectsService.exportDetailsXlsx(this.project_id)
        ).subscribe(file => {
            this.fileHelperService.saveFileData(file);
        });
    }

    exportSummaryPdf() {
        this.loaderService.wrapObservable(
            this.projectsService.exportSummaryPdf(this.project_id)
        ).subscribe(file => {
            this.fileHelperService.saveFileData(file);
        });
    }
}
