import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class PlatformService {

    initialized: boolean;
    constructor() {}

    initialize() {
        return new Promise((resolve, reject) => {
            const onDeviceReady = () => {
                this.initialized = true;
                resolve();
            };

            if (!this.initialized) {
                // make sure to check and wait for deviceready only in native app
                if (environment.dink && window['cordova']) {
                    document.addEventListener('deviceready', onDeviceReady, false);
                } else {
                    onDeviceReady();
                }
            } else {
                onDeviceReady();
            }
        });
    }
}
