﻿import { TrolleyType } from './trolley-type';
import { TrolleySize } from './trolley-size';

export class TrolleyData {
    public trolleyConfigurationId: number;
    public description: string;
    public trolleyType: TrolleyType;
    public trolleySize: TrolleySize;
    public sequenceNumber: number;
}
