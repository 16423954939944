import { Component, OnInit, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { LoaderService, AlertService } from 'src/app/shared/services';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from 'src/app/shared/models/datatables';
import { ProjectsService } from 'src/app/shared/services/projects.service';

import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog';
import { NewProjectItemDialogComponent } from '../dialog-new-project-item/dialog-new-project-item.component';
import { Project } from 'src/app/shared/models/generated/project';
import { ViewModel } from 'src/app/shared/models/view-model';
import { ViewModelService } from 'src/app/shared/services/view-model.service';
import { ProjectStatus } from 'src/app/shared/models/generated/project-status';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-manage-inventory',
    templateUrl: './manage-inventory.component.html',
    styleUrls: ['./manage-inventory.component.scss']
})
export class ManageInventoryComponent implements OnInit, OnChanges, OnDestroy {

    private viewModelSubscription;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    readonly = true;

    projectData: ViewModel;
    uploadedFile: File;

    list = [];
    filter: string;
    page = new Page;

    constructor(
        private projectsService: ProjectsService,
        private loaderService: LoaderService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private viewModelService: ViewModelService
    ) { }

    ngOnInit() {
        this.viewModelSubscription = this.viewModelService.viewModelData.subscribe(() => {
            this.getViewModelData();
        });
    }

    onFileSelect(file: File) {
        this.uploadedFile = file;
    }

    uploadDisabled() {
        return (this.uploadedFile ? false : true) || (this.projectData && this.projectData.projectItems.length > 0);
    }

    uploadFile() {
        if (this.uploadedFile) {
            const obs$ = this.projectsService.importProjectsFile(this.uploadedFile, this.projectData.projectId);

            this.loaderService.wrap(obs$).subscribe((result) => {
                this.alertService.success('File uploaded successfully');
                this.loaderService.wrap(
                    this.projectsService.getProject(this.projectData.projectId)
                ).subscribe(pd => {
                    this.viewModelService.initializeModel(pd);
                    this.getViewModelData();
                })
            });
        }
    }

    setPage(pageInfo) {
        this.page.size = pageInfo.limit;
    }

    onSortRows(sortInfo) {
        this.page.pageNumber = 0;
        this.onDisplayPage();
    }

    onFilterItems(filterInfo) {
        const filter = filterInfo && filterInfo.target ? filterInfo.target.value.trim() : null;
        this.applyFilter(filter);
    }

    applyFilter(filter: string) {
        if (filter) {
            this.list = [...this.projectData.projectItems.filter(p =>
                p.itemCode.toLocaleLowerCase().indexOf(filter) !== -1 ||
                p.itemDescription.toLocaleLowerCase().indexOf(filter) !== -1)];
        } else {
            this.list = [...this.projectData.projectItems];
        }

        this.page.pageNumber = 0;
        this.onDisplayPage();
    }

    onSelectPageSize(size) {
        this.onDisplayPage();
    }

    onDisplayPage() {
        this.page.totalPages = Math.floor(this.list.length / this.page.size);
        this.page.totalElements = this.list.length;
    }

    updateQuantity(row, type?) {

        if (!type) {
            if (row.quantity <= 0) {
                row.quantity = 0;
            }
        } else {
            switch (type) {
                case '-':
                    if (row.quantity > 0) {
                        row.quantity -= 1;
                    }
                    break;
                case '+':
                    row.quantity += 1;
                    break;
                default:
                    break;
            }
        }

        this.projectsService.updateProjectItemQuantity(row.id, row).subscribe(() => {
            this.viewModelService.updateProjectItemQuantity(row.id, row);
            this.viewModelService.removeProjectItem(row.id);
        });
    }

    newProjectItem() {
        const dialogRef = this.dialog.open(NewProjectItemDialogComponent);

        dialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                const obs$ = this.projectsService.createProjectItem(this.projectData.projectId, result);
                this.loaderService.wrap(obs$).subscribe((response) => {
                    this.viewModelService.addProjectItem(response);
                });
            }
        });
    }

    deleteProjectItem(item) {
        const dialogData: ConfirmDialogData = {
            title: 'PROJECT.DELETE_ITEM_TITLE',
            message: 'PROJECT.DELETE_ITEM_MESSAGE'
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
        confirmDialogRef.componentInstance.dialogData = dialogData;

        confirmDialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                this.projectsService.deleteProjectItem(item.id).subscribe((response) => {
                    this.viewModelService.deleteProjectItem(item);
                    this.viewModelService.removeProjectItem(item.id);
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.viewModelSubscription) {
            this.viewModelSubscription.unsubscribe();
        }
    }

    ngOnChanges() {
        if (this.viewModelSubscription) {
            this.viewModelSubscription.unsubscribe();
        }
    }

    private getViewModelData() {
        this.projectData = this.viewModelService.getData();

        if (this.projectData.projectStatus != ProjectStatus.finalized) {
            this.readonly = false;
        }

        this.uploadedFile = null;
        this.list = null;
        this.applyFilter(this.filter);
    }
}
