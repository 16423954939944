import { Pipe, PipeTransform } from '@angular/core';
import { TrolleySize } from '../models/generated/trolley-size';

@Pipe({
  name: 'trolleySize'
})
export class TrolleySizePipe implements PipeTransform {

  transform(value: TrolleySize): string {
    switch (value) {
      case TrolleySize.x2100:
        return "2100mm";
      case TrolleySize.x1950:
        return "1950mm";
      case TrolleySize.x1600:
        return "1600mm (UK only)";
      case TrolleySize.x1370:
        return "1370mm";
      case TrolleySize.x970:
        return "970mm";
        case TrolleySize.x1790:
          return "1790mm";
      default:
        return null;
    }
  }

}
