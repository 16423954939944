import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';

@Injectable()
export class AccessService {

    constructor(
        private authService: AuthenticationService) { }

    ROLES = {
        Administrator: 'Administrator',
        SuperUser: 'SuperUser',
        User: 'User'
    };

    isAdmin() {
        const roles = [
            this.ROLES.Administrator
        ];

        return this.authService.roleAccess(roles);
    }

}
