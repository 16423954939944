import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class LoaderService {

    private generalLoading = 0;

    constructor() { }

    isGeneralLoaderDisplayed() {
        return this.generalLoading;
    }

    hideAllLoaders() {
        this.generalLoading = 0;
    }

    wrap(obs: Observable<any>) {
        this.showGeneralLoader();
        return obs.pipe(
            map(result => {
                this.hideGeneralLoader();
                return result;
            }));
    }

    wrapObservable<T>(obs: Observable<T>) {
        this.showGeneralLoader();
        return obs.pipe(
            map(result => {
                this.hideGeneralLoader();
                return result;
            }));
    }

    private showGeneralLoader() {
        this.generalLoading += 1;
    }

    private hideGeneralLoader() {
        this.generalLoading -= 1;
    }
}
