import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class StorageService {
    constructor(
    ) { }

    private storageCodes = {
        securityToken: 'Prostart_SecurityToken',
        refreshToken: 'Prostart_RefreshToken',
        userId: 'Prostart_UserId',
        displayName: 'Prostart_DisplayName',
        role: 'Prostart_Role'
    };
    private jwtHelper = new JwtHelperService();

    getSecurityToken() {
        return localStorage.getItem(this.storageCodes.securityToken);
    }

    setSecurityToken(token: string) {
        return localStorage.setItem(this.storageCodes.securityToken, token);
    }

    removeSecurityToken() {
        localStorage.removeItem(this.storageCodes.securityToken);
    }

    getRefreshToken() {
        return localStorage.getItem(this.storageCodes.refreshToken);
    }

    setRefreshToken(token: string) {
        return localStorage.setItem(this.storageCodes.refreshToken, token);
    }

    setDisplayName(name: string) {
        return localStorage.setItem(this.storageCodes.displayName, name);
    }

    getDisplayName() {
        return localStorage.getItem(this.storageCodes.displayName);
    }

    removeRefreshToken() {
        localStorage.removeItem(this.storageCodes.refreshToken);
    }

    getUserId() {
        return localStorage.getItem(this.storageCodes.userId);
    }

    setUserId() {
        const token = this.getSecurityToken();
        const decode = this.jwtHelper.decodeToken(token);
        return localStorage.setItem(this.storageCodes.userId, decode['prostart:userid']);
    }

    removeUserId() {
        localStorage.removeItem(this.storageCodes.userId);
    }

    getUserName() {
        const token = this.getSecurityToken();
        const decode = this.jwtHelper.decodeToken(token);
        return decode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
    }

    getUserEmail() {
        const token = this.getSecurityToken();
        const decode = this.jwtHelper.decodeToken(token);
        return decode['sub'];
    }

    setUserRole(token: string) {
        const decode = this.jwtHelper.decodeToken(token);
        localStorage.setItem(this.storageCodes.role, decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
    }

    getUserRole() {
        return localStorage.getItem(this.storageCodes.role);
    }

    getUseSso() {
        const token = this.getSecurityToken();
        if (!token) {
            return null;
        }

        const decode = this.jwtHelper.decodeToken(token);
        return decode['prostart:sso'];
    }
}
