export class LoginData {
    username: string;
    password: string;
    isPlatformLogin: boolean;
}

export class AuthenticationInfoModel {
    token: string;
    refreshToken: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class LoginInfo {

    constructor(email: string, password: string, isPlatformLogin = false) {
        this.username = email;
        this.password = password;
        this.isPlatformLogin = isPlatformLogin;
    }

    username: string;
    password: string;
    isPlatformLogin: boolean;
}

export class RefreshTokenData {
    token: string;
    refreshToken: string;

    constructor(token: string, refreshToken: string) {
        this.token = token;
        this.refreshToken = refreshToken;
    }
}

export class NewPassword {
    resetCode: string;
    username: string;
    password: string;
    newPassword: string;
    firstName: string;
    lastName: string;
}


export class SetPasswordModel extends LoginInfo {
    constructor(username: string, password: string, resetCode: string) {
        super(username, password);
        this.resetCode = resetCode;
    }
    resetCode: string;
    firstName: string;
    lastName: string;
}

export class ResetPassword {
    username: string;
}
