import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewModel } from 'src/app/shared/models/view-model';
import { TrolleyLabelViewModel, TrolleyLabelVMTrolley, TrolleyLabelVMCompartment, TrolleyLabelVMTray, TrolleyLabelVMLabel } from 'src/app/shared/models/view-model-trolley-label';
import { TrolleyType } from 'src/app/shared/models/generated/trolley-type';
import { TrolleyConfiguration } from 'src/app/shared/models/generated/trolley-configuration';
import { StorageSystem } from 'src/app/shared/models/generated/storage-system';
import { TrayType } from 'src/app/shared/models/generated/tray-type';
import { TrolleyLabel } from 'src/app/shared/models/generated/trolley-label';
import { MatDialog } from '@angular/material';
import { DialogTrolleyLabelComponent } from '../dialog-trolley-label/dialog-trolley-label.component';
import { TrolleyLabelsService } from 'src/app/shared/services/trolley-labels.service';
import { LoaderService, AlertService } from 'src/app/shared/services';
import { ViewModelService } from 'src/app/shared/services/view-model.service';
import { ProjectStatus } from 'src/app/shared/models/generated/project-status';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-trolley-labels',
  templateUrl: './trolley-labels.component.html',
  styleUrls: ['./trolley-labels.component.scss']
})
export class TrolleyLabelsComponent implements OnInit {

  @Output() finalized = new EventEmitter<boolean>();

  readonly = true;
  projectData: ViewModel;

  public model = new TrolleyLabelViewModel();
  public activeTrolley: TrolleyLabelVMTrolley; 

  constructor(
    private labelService: TrolleyLabelsService,
    private viewModelService: ViewModelService,
    private projectsService: ProjectsService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.refreshData();
  }

  public editTrayLabels(tray: TrolleyLabelVMTray) {
    const dialogRef = this.dialog.open(DialogTrolleyLabelComponent);
    dialogRef.componentInstance.model = JSON.parse(JSON.stringify(tray.labels));
    dialogRef.componentInstance.brandColor = tray.color;
    dialogRef.componentInstance.readonly = this.readonly;

    dialogRef.beforeClosed().subscribe((result: TrolleyLabelVMLabel[]) => {
      if (result) {
        this.loaderService.wrapObservable(
          this.labelService.saveTrayLabels(this.model.projectId, this.activeTrolley.id, tray.id, result)
        ).subscribe(result => {
          tray.labels = result;
        });
      }
    });
  }

  public editCompartmentLabels(compartment: TrolleyLabelVMCompartment) {
    const dialogRef = this.dialog.open(DialogTrolleyLabelComponent);
    dialogRef.componentInstance.model = JSON.parse(JSON.stringify(compartment.labels));
    dialogRef.componentInstance.brandColor = compartment.color;
    dialogRef.componentInstance.readonly = this.readonly;

    dialogRef.beforeClosed().subscribe((result: TrolleyLabelVMLabel[]) => {
      if (result) {
        this.loaderService.wrapObservable(
          this.labelService.saveCompartmentLabels(this.model.projectId, this.activeTrolley.id, compartment.sequenceNumber, result)
        ).subscribe(result => {
          compartment.labels = result;
        });
      }
    });
  }

  public back() {
    if (this.model && this.activeTrolley) {
      const index = this.model.trolleys.indexOf(this.activeTrolley);
      if (index > 0) {
        this.activeTrolley = this.model.trolleys[index - 1];
      } else {
        this.activeTrolley = this.model.trolleys[this.model.trolleys.length - 1];
      }
    }
  }

  public next() {
    if (this.model && this.activeTrolley) {
      const index = this.model.trolleys.indexOf(this.activeTrolley);
      if (index < this.model.trolleys.length - 1) {
        this.activeTrolley = this.model.trolleys[index + 1];
      } else {
        this.activeTrolley = this.model.trolleys[0];
      }
    }
  }

  public checkout() {
    const dialogData: ConfirmDialogData = {
      title: 'Finish project',
      message: 'Are you sure you would like to finish project?'
    };

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.componentInstance.dialogData = dialogData;

    confirmDialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        this.projectsService.finalizeProject(this.projectData.projectId).subscribe(() => {
          this.projectsService.getProject(this.projectData.projectId).subscribe(result => {
            const configuration = this.projectData.trolleyConfiguration;
            this.projectData = this.viewModelService.initializeModel(result);
            this.projectData.trolleyConfiguration = configuration;
            this.alertService.success('Successfully finalized!');
            this.finalized.emit(true);
          });
        });
      }
    });
  }

  private refreshData() {
    this.projectData = this.viewModelService.getData();
    this.readonly = this.projectData.projectStatus == ProjectStatus.finalized;
    this.loaderService.wrapObservable(
      this.labelService.loadViewModel(this.projectData)
    ).subscribe(result => {
      this.model = result;
      if (this.model && this.model.trolleys) {
        this.activeTrolley = this.model.trolleys[0];
      }
    });
  }

}
