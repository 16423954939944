import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Trolley } from 'src/app/shared/models/generated/trolley';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material';
import { ManageNailingTrolleyComponent } from '../nailing-trolleys/manage-nailing-trolley/manage-nailing-trolley.component';
import { NailingTrolleyViewModel } from 'src/app/shared/models/view-model-nailing-trolley';
import { AlertService } from 'src/app/shared/services';
import { TrolleyOverview, TrolleyOverviewCompartment, TrolleyOverviewTray } from 'src/app/shared/models/trolley-overview';
import { ViewModelService } from 'src/app/shared/services/view-model.service';
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ProjectStatus } from 'src/app/shared/models/generated/project-status';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { ProjectData } from 'src/app/shared/models/generated/project-data';
import { ViewModel } from 'src/app/shared/models/view-model';
import { forkJoin } from 'rxjs';
import { DialogOverviewContentsComponent } from '../dialog-overview-contents/dialog-overview-contents.component';

@Component({
    selector: 'app-trolley-review',
    templateUrl: './trolley-review.component.html',
    styleUrls: ['./trolley-review.component.scss']
})
export class TrolleyReviewComponent implements OnInit {

    @Output() finalized = new EventEmitter<boolean>();
    projectId: number;
    readonly = true;
    trolleys: Trolley[];
    trolleyOverview: { [id: string]: TrolleyOverview };

    constructor(
        private projectsService: ProjectsService,
        private viewModelService: ViewModelService,
        private alertService: AlertService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.initialize();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.trolleys, event.previousIndex, event.currentIndex);
    }

    editTrolley(trolley: Trolley) {
        const dialogRef = this.dialog.open(ManageNailingTrolleyComponent);
        const model = new NailingTrolleyViewModel();
        model.description = trolley.description;
        dialogRef.componentInstance.model = model;

        dialogRef.beforeClosed().subscribe((result: NailingTrolleyViewModel) => {
            if (result) {
                trolley.description = result.description;
            }
        });
    }

    deleteTrolley(trolley: Trolley) {
        const dialogData: ConfirmDialogData = {
            title: 'TROLLEYS.DELETE_TITLE',
            message: 'TROLLEYS.DELETE_MESSAGE'
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
        confirmDialogRef.componentInstance.dialogData = dialogData;

        confirmDialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                this.viewModelService.deleteTrolley(trolley).subscribe(result => {
                    if (result) {
                        this.trolleys = [...this.viewModelService.getData().trolleys];
                    }
                });
            }
        });
    }

    save(showMessage: boolean = true) {
        this.viewModelService.reorganizeTrolleys(this.trolleys).subscribe(() => {
            if (showMessage) {
                this.alertService.success("SHARED.ALERT_MESSAGE.SUCCESSFULLY_SAVED");
            }
        });
    }

    checkout() {
        const dialogData: ConfirmDialogData = {
            title: 'Archive project',
            message: 'Are you sure you would like to archive project?'
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
        confirmDialogRef.componentInstance.dialogData = dialogData;

        confirmDialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                this.projectsService.finalizeProject(this.projectId).subscribe(() => {
                    forkJoin([
                        this.projectsService.getProject(this.projectId),
                        this.projectsService.getTrolleyConfiguration()
                    ]).subscribe(result => {
                        var projectData = this.viewModelService.initializeModel(result[0]);
                        projectData.trolleyConfiguration = result[1];
                        this.alertService.success('Successfully archived!');
                        this.finalized.emit(true);
                    });
                });
            }
        });
    }

    showCompartmentOverview(compartment: TrolleyOverviewCompartment) {
        if (compartment && compartment.items && compartment.items.length > 0) {
            const dialogRef = this.dialog.open(DialogOverviewContentsComponent);
            dialogRef.componentInstance.items = compartment.items;
        }
    }

    showTrayOverview(tray: TrolleyOverviewTray) {
        const dialogRef = this.dialog.open(DialogOverviewContentsComponent);
        dialogRef.componentInstance.items = tray.items;
        dialogRef.componentInstance.tray = tray.trayData;
    }

    getNameLines(name: string): string[] {
        if(name){
            return name.split(/[\n,\r]/);
        }
        return [];
    }

    private initialize() {
        const projectData = this.viewModelService.getData();
        if (projectData) {
            this.projectId = projectData.projectId;
            this.readonly = projectData.projectStatus == ProjectStatus.finalized;
            this.trolleyOverview = this.viewModelService.getTrolleyOverview();
            this.trolleys = [...projectData.trolleys].sort((t1, t2) => t1.sequenceNumber  - t2.sequenceNumber);
        }
    }

}
