import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../shared/services';
import { ResetPassword } from '../models/login-data';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  model: ResetPassword;
  public errorMsg: string;
  public loading: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.model = new ResetPassword();
  }

  resetPassword() {
    this.errorMsg = null;
    this.loading = true;
    this.authenticationService.resetPassword(this.model)
      .subscribe(
        next => {
          this.loading = false;
          this.router.navigate(['/new-password'], { queryParams: { username: this.model.username } });
        },
        error => {
          this.alertService.error(error);
        }
      );
  }
}
