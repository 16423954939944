import { TrayType } from './generated/tray-type';
import { ViewModelColumnData } from './view-model-column-data';
import { Tray } from './generated/tray';
import { BrandColor } from './brand-color';
import { ViewModelSubBrandInfo } from './view-model-sub-brand-info';

export class ViewModelTrayData {
    public remainingSpace: number;

    public id: number;
    public name: string;
    public sequenceNumber: number;
    public sequenceNumberTrolley: number;
    public trayType: TrayType;
    public trolleyId: number;

    public columns: ViewModelColumnData[];

    public brandColors: BrandColor[];

    public subBrands: ViewModelSubBrandInfo[];

    constructor(data: Tray) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.remainingSpace = 1;
            this.sequenceNumber = data.sequenceNumber;
            this.sequenceNumberTrolley = data.sequenceNumberTrolley;
            this.trayType = data.trayType;
            this.trolleyId = data.trolleyId;
            this.columns = [];
        } else {
            this.id = null;
            this.name = '';
            this.remainingSpace = 1;
            this.sequenceNumber = null;
            this.sequenceNumberTrolley = null;
            this.trayType = null;
            this.trolleyId = null;
            this.columns = [];
        }

        this.brandColors = [];
        this.subBrands = [];
    }

    public calculateBrandColors() {
        const brandColors = <BrandColor[]>[];

        if (this.columns) {
            for (const column of this.columns) {
                if (column && column.rows) {
                    for (const row of column.rows) {
                        const brandName = row.itemBrand || '';
                        if (row.brandColor && !brandColors.some(bc => bc.brandName == brandName)) {
                            brandColors.push(new BrandColor(brandName, row.brandColor));
                        }
                    }
                }
            }
        }

        this.brandColors = brandColors;
    }

    public calculateName() {
        const subBrandNames = <string[]>[];

        if (this.columns) {
            for (const column of this.columns) {
                if (column && column.rows) {
                    for (const row of column.rows) {
                        const subBrandName = row.itemSubBrand || '';
                        if (subBrandName && !subBrandNames.some(sb => sb === subBrandName)) {
                            subBrandNames.push(subBrandName);
                        }
                    }
                }
            }
        }

        this.name = subBrandNames.join('\n');
    }
}
