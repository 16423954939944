﻿import { TrolleyData } from './trolley-data';
import { TrolleyDoorType } from './trolley-door-type';
import { TrolleyProjectItem } from './trolley-project-item';

export class TrolleyBase extends TrolleyData {
    public doorType: TrolleyDoorType;
    public trays: number[];
    public trolleyProjectItems: TrolleyProjectItem[];

    constructor() {
        super();
        this.trays = [];
        this.trolleyProjectItems = [];
    }
}
