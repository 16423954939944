import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Project } from 'src/app/shared/models/generated/project';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { LoaderService, AlertService } from 'src/app/shared/services';
import { ProjectUpdateData } from 'src/app/shared/models/generated/project-update-data';
import { StorageSystem } from 'src/app/shared/models/generated/storage-system';
import { ViewModelService } from 'src/app/shared/services/view-model.service';
import { ViewModel } from 'src/app/shared/models/view-model';
import { TrolleyDoorType } from 'src/app/shared/models/generated/trolley-door-type';
import { ProjectStatus } from 'src/app/shared/models/generated/project-status';
import { Trolley } from 'src/app/shared/models/generated/trolley';

@Component({
    selector: 'app-select-storage',
    templateUrl: './select-storage.component.html',
    styleUrls: ['./select-storage.component.scss']
})
export class SelectStorageComponent implements OnInit, OnChanges, OnDestroy {

    private viewModelSubscription;

    STORAGE = StorageSystem;
    DOOR = TrolleyDoorType;

    readonly = true;

    projectId: number;
    projectUpdate: ProjectUpdateData;

    constructor(
        private loaderService: LoaderService,
        private projectsService: ProjectsService,
        private viewModelService: ViewModelService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.viewModelSubscription = this.viewModelService.viewModelData.subscribe(() => {
            this.getViewModelData();
        });
    }

    selectStorage(type) {
        this.projectUpdate.storageSystem = type;
    }

    save(showMessage: boolean = true) {
        if (!this.readonly) {
            this.loaderService.wrap(
                this.projectsService.updateProject(this.projectId, this.projectUpdate)
            ).subscribe(() => {
                this.viewModelService.updateProjectData(this.projectUpdate);
                if (showMessage) {
                    this.alertService.success('SHARED.ALERT_MESSAGE.SUCCESSFULLY_SAVED');
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.viewModelSubscription) {
            this.viewModelSubscription.unsubscribe();
        }
    }

    ngOnChanges() {
        if (this.viewModelSubscription) {
            this.viewModelSubscription.unsubscribe();
        }
    }

    private getViewModelData() {
        const projectData = this.viewModelService.getData();
        this.projectId = projectData.projectId;
        this.readonly = projectData.projectStatus == ProjectStatus.finalized || projectData.trolleys.length > 0;

        const model = new ProjectUpdateData();
        model.name = projectData.name;
        model.storageSystem = projectData.storageSystem;
        model.trolleyDoorType = projectData.trolleyDoorType;

        if (!this.readonly) {
            if (!model.storageSystem) {
                model.storageSystem = StorageSystem.mobile;
            }

            if (!model.trolleyDoorType) {
                model.trolleyDoorType = TrolleyDoorType.none;
            }
        }

        this.projectUpdate = model;
    }
}
