import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService, LoaderService } from 'src/app/shared/services';

import { Page } from 'src/app/shared/models/datatables';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ProductsService } from 'src/app/shared/services/products.service';
import { Product } from 'src/app/shared/models/generated/product';

@Component({
    selector: 'app-import-master-data',
    templateUrl: './import-master-data.component.html',
    styleUrls: ['./import-master-data.component.scss']
})
export class ImportMasterDataComponent implements OnInit {

    @ViewChild(DatatableComponent) table: DatatableComponent;

    uploadedFile: File;

    page = new Page;
    products: Product[];
    list: Product[];

    // Datatables query params
    offset = 1;
    limit = 20;
    filter = '';
    order_column = 1;
    order_direction = 'asc';

    constructor(
        private loaderService: LoaderService,
        private alertService: AlertService,
        private productsService: ProductsService
    ) { }

    ngOnInit() {
        this.onDisplayPage();
    }

    onFileSelect(file: File) {
        this.uploadedFile = file;
    }

    uploadDisabled() {
        return this.uploadedFile ? false : true;
    }

    uploadFile() {
        if (this.uploadedFile) {
            const obs$ = this.productsService.importMasterFile(this.uploadedFile);

            this.loaderService.wrap(obs$).subscribe(() => {
                this.alertService.success('SHARED.ALERT_MESSAGE.SUCCESSFULLY_UPLOADED');
                this.onDisplayPage();
            });
        }
    }

    setPage(pageInfo) {
        this.offset = pageInfo.offset * this.page.size;
        this.page.size = pageInfo.limit;
    }

    onSortRows(sortInfo) {
        this.offset = 0;
        this.page.pageNumber = 0;
        this.order_column = sortInfo.sorts[0].prop;
        this.order_direction = sortInfo.sorts[0].dir;
        this.onDisplayPage();
    }

    onFilterItems(filterInfo) {
        const filter = filterInfo && filterInfo.target ? filterInfo.target.value.trim() : null;

        if (filter) {
            this.list = this.products.filter(p => 
                p.code.toLocaleLowerCase().indexOf(filter) !== -1 ||
                p.description.toLocaleLowerCase().indexOf(filter) !== -1);
        } else {
            this.list = this.products;
        }

        this.page.totalPages = Math.floor(this.list.length / this.page.size);
        this.page.totalElements = this.list.length;
    }

    onSelectPageSize(size) {
        this.offset = 0;
        this.onDisplayPage();
    }

    onDisplayPage() {
        const obs$ = this.productsService.getApi();

        this.loaderService.wrap(obs$).subscribe(result => {
            this.products = result;
            this.onFilterItems(null);
        });
    }
}
