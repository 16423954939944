import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertService } from '../services/alert.service';

@Component({
    selector: 'shared-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
    dragAreaClass = 'dragarea';
    fileName: string = null;
    translations: any;
    @Input() fileExt: string;
    @Input() accept: string;
    @Output() changeFile = new EventEmitter();
    @ViewChild('file') input: ElementRef;

    constructor(
        private alert: AlertService) { }

    ngOnInit() {

    }

    onFileChange(event: any) {
        const files = <Array<File>>event.target.files;

        if (files.length === 1) {
            const file = files[0];
            this.fileName = file.name;
            this.changeFile.emit(file);
        } else {
            this.alert.error(this.translations.SELECT_ONLY_ONE);
        }

        const fromFile = new FormData();
    }

    clearFile() {
        this.fileName = null;
        this.input.nativeElement.value = null;
        this.changeFile.emit(null);
    }

    @HostListener('dragover', ['$event']) onDragOver(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragenter', ['$event']) onDragEnter(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragend', ['$event']) onDragEnd(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }
    @HostListener('drop', ['$event']) onDrop(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
        event.stopPropagation();
        const files = <Array<File>>event.dataTransfer.files;
        if (files.length === 1) {
            if (this.isValidFileExtension(files)) {
                const file = files[0];
                this.fileName = file.name;
                this.changeFile.emit(file);
            } else {
                this.alert.error(this.translations.UNSUPPORTED_FILE);
            }
        } else {
            this.alert.error(this.translations.SELECT_ONLY_ONE);
        }
    }

    private isValidFileExtension(files: any) {

        let exist = true;
        const extensions = (this.fileExt.split(','))
                        .map(function (x) { return x.toLocaleUpperCase().trim(); });
        for (let i = 0; i < files.length; i++) {

            const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;

            const exists = extensions.indexOf(ext);
            if (exists === -1) {
                exist = false;
            }
        }

        return exist;
      }
}
