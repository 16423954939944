import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../shared/services';
import { NewPassword } from '../models/login-data';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit, OnDestroy {

  sub: any;
  errorMsg: string;
  loading: boolean;
  model: NewPassword;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.model = new NewPassword();
    this.sub = this.route.queryParams.subscribe(queryParams => {
      this.model.username = queryParams['username'];
      if (!this.model.username) {
        this.router.navigate(['/reset-password']);
      }
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  savePassword(f: NgForm) {
    this.loading = true;
    this.errorMsg = null;
    if (f.valid) {
      this.authenticationService.newPassword(this.model)
        .subscribe(
          next => {
            this.loading = false;
            this.router.navigate(['/login']);
          },
          error => {
            this.loading = false;
            this.alertService.error(error);
          }
        );
    }
  }
}
