import { TrolleyType } from './generated/trolley-type';
import { TrolleyConfiguration } from './generated/trolley-configuration';
import { TrayType } from './generated/tray-type';
import { TrolleyLabel } from './generated/trolley-label';

export class TrolleyLabelVMLabel {
    public id: number;
    public title: string;
    public description: string;
    public deleted: boolean;

    constructor(model?: TrolleyLabel) {
        if (model) {
            this.id = model.id;
            this.title = model.title;
            this.description = model.description;
            this.deleted = false;
        }
    }
}

export class TrolleyLabelVMTray {
    public id: number;
    public name: string;
    public type: TrayType;
    public color: string;
    public labels: TrolleyLabelVMLabel[];

    constructor() {
        this.labels = [];
    }
}

export class TrolleyLabelVMCompartment {
    public sequenceNumber: number;
    public color: string;
    public labels: TrolleyLabelVMLabel[];

    constructor() {
        this.labels = [];
    }
}

export class TrolleyLabelVMTrolley {
    public id: number;
    public description: string;
    public compartments: TrolleyLabelVMCompartment[]
    public trays: TrolleyLabelVMTray[];

    constructor() {
        this.compartments = [];
        this.trays = [];
    }
}

export class TrolleyLabelViewModel {
    projectId: number;
    trolleys: TrolleyLabelVMTrolley[];

    constructor() {
        this.trolleys = [];
    }
}