import { OverviewContentsItem } from './overview-contents-data';
import { ViewModelTrayData } from './view-model-tray-data';
import { BrandColor } from './brand-color';

export class TrolleyOverviewTray {
    trayData: ViewModelTrayData;
    items: OverviewContentsItem[];
    brandColors: BrandColor[];

    constructor(trayData: ViewModelTrayData) {
        this.trayData = trayData;
        this.items = [];
        this.brandColors = [];
    }

    public calculateBrandColors() {
        const brandColors = <BrandColor[]>[];

        if (this.items) {
            for (const item of this.items) {
                const brandName = item.itemBrand || '';
                if (item.brandColor && !brandColors.some(bc => bc.brandName == brandName)) {
                    brandColors.push(new BrandColor(brandName, item.brandColor));
                }
            }
        }

        this.brandColors = brandColors;
    }
}

export class TrolleyOverviewCompartment {
    elements: string[];
    items: OverviewContentsItem[];
    rows: number[];

    constructor() {
        this.elements = [];
        this.items = [];
        this.rows = [];
    }
}

export class TrolleyOverview {
    compartments: TrolleyOverviewCompartment[];
    trays: TrolleyOverviewTray[];

    constructor() {
        this.compartments = [];
        this.trays = [];
    }
}