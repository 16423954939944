import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthenticationInfoModel } from '../../authentication/models/login-data';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { StorageService } from './storage.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  private jwtHelper = new JwtHelperService();
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private storageService: StorageService,
    private authService: AuthenticationService) { }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    // HACK: got the idea from github to use 'fake' header to distinguish refresh action
    //       headers.delete apparently doesn't work
    const refreshAction = req.headers.has('X-META-REFRESH');
    if (refreshAction) {
      req.headers.delete('X-META-REFRESH', 'Refresh');
    }

    return next.handle(this.addToken(req, this.storageService.getSecurityToken())).pipe(
      catchError(error => {
        if (!refreshAction && error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              return this.handle401Error(req, next);
            default:
              return observableThrowError(error);
          }
        } else {
          return observableThrowError(error);
        }
      }));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      return this.authService.refresh().pipe(
        switchMap((newToken: AuthenticationInfoModel) => {
          if (newToken) {
            this.storageService.setSecurityToken(newToken.token);
            this.storageService.setRefreshToken(newToken.refreshToken);
            this.tokenSubject.next(newToken.token);
            return next.handle(this.addToken(req, newToken.token));
          }

          return this.logout();
        }),
        catchError(() => {
          return this.logout();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }));
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token));
        }));
    }
  }

  logout() {
    this.authService.logout();
    return observableThrowError('Unauthorized');
  }
}
