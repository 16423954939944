// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  dink: false,
  //ssoUrl: 'https://dev-identity.dink.eu/connect/authorize' +
    //'?client_id=stryker_apps_dev&response_type=code&scope=api:admin offline_access&redirect_uri=https://prostart.rhqeurope.com'
   ssoUrl: 'https://identity.dink.eu/connect/authorize?client_id=stryker_apps&response_type=code&scope=stryker_apps offline_access&redirect_uri=https://prostart.rhqeurope.com&login_hint=user@stryker.com'

};

export const BASE_URL = 'https://prostart-api.rhqeurope.com/';
export const API_URL = 'api/v1/';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
