import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AlertService {

  constructor(
    private router: Router,
    private tostr: ToastrService,
    private translate: TranslateService) {
}

success(message: string) {
    this.tostr.success(this.translate.instant(message));
}

info(message: string) {
    this.tostr.info(this.translate.instant(message));
}

error(error) {

    if (error) {
        if (typeof (error) === 'object') {
            if (error.status === 401 || error.status === 403 || error.status === 404) {
                switch (error.status) {
                    case 401: 
                        this.tostr.error('Unauthorized');
                        break;
                    case 403:
                        this.tostr.error('Forbidden');
                        break;
                    case 404:
                        this.tostr.error('Not found');
                        break;
                    default:
                        this.translate.get('ERRORS.GENERIC_MESSAGE')
                            .subscribe((msg: string) => {
                                this.tostr.error(msg);
                            });;
                        break;
                }
                
            } else if (error.status === 400) {
                if (error._body) {
                    const errors = error.json();
                    let messages = '';

                    errors.forEach(element => {
                        messages += element.ErrorMessage + '<br />';
                    });

                    if (messages) {
                        this.tostr.error(messages, '', {
                            enableHtml: true
                        });
                    } else {
                        this.translate.get('ERRORS.GENERIC_MESSAGE')
                            .subscribe((msg: string) => {
                                this.tostr.error(msg);
                            });
                    }

                } else {
                    this.translate.get('ERRORS.GENERIC_MESSAGE')
                        .subscribe((msg: string) => {
                            this.tostr.error(msg);
                        });
                }
            } else {
                this.translate.get('ERRORS.GENERIC_MESSAGE')
                    .subscribe((msg: string) => {
                        this.tostr.error(msg);
                    });
            }
        }

        if (typeof(error) === 'string') {
            this.tostr.error(error);
        }
    } else {
        this.translate.get('ERRORS.GENERIC_MESSAGE')
            .subscribe((msg: string) => {
                this.tostr.error(msg);
            });
    }
}
}
