import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportMasterDataComponent } from './import-master-data/import-master-data.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ProjectsComponent } from './projects/projects.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProjectItemsComponent } from './project-items/project-items.component';
import { ManageInventoryComponent } from './project-items/manage-inventory/manage-inventory.component';
import { SelectStorageComponent } from './project-items/select-storage/select-storage.component';

import { NewProjectDialogComponent } from './projects/dialog-new-project/dialog-new-project.component';
import { NewProjectItemDialogComponent } from './project-items/dialog-new-project-item/dialog-new-project-item.component';
import { AddItemsTrayDialogComponent } from './project-items/dialog-add-items-tray/dialog-add-items-tray.component';

import { ConfigureTraysComponent } from './project-items/configure-trays/configure-trays.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorPickerModule } from 'ngx-color-picker';
import { BrandColorsComponent } from './brand-colors/brand-colors.component';
import { NewTrayDialogComponent } from './project-items/dialog-new-tray/dialog-new-tray.component';
import { TrayOverflowOptionsDialogComponent } from './project-items/dialog-tray-overflow-options/dialog-tray-overflow-options.component';
import { DialogShareProjectComponent } from './projects/dialog-share-project/dialog-share-project.component';
import { TrolleyLabelsComponent } from './project-items/trolley-labels/trolley-labels.component';
import { DialogTrolleyLabelComponent } from './project-items/dialog-trolley-label/dialog-trolley-label.component';
import { AssembleTrolleysComponent } from './project-items/assemble-trolleys/assemble-trolleys.component';
import { ManageTrolleyComponent } from './project-items/assemble-trolleys/manage-trolley/manage-trolley.component';
import { NailingTrolleysComponent } from './project-items/nailing-trolleys/nailing-trolleys.component';
import { ManageNailingTrolleyComponent } from './project-items/nailing-trolleys/manage-nailing-trolley/manage-nailing-trolley.component';
import { TrolleyReviewComponent } from './project-items/trolley-review/trolley-review.component';
import { DialogEditContentsComponent } from './project-items/dialog-edit-contents/dialog-edit-contents.component';
import { DialogOverviewContentsComponent } from './project-items/dialog-overview-contents/dialog-overview-contents.component';

const appRoutes: Routes = [
    {
        path: '', component: MainComponent, canActivate: [AuthGuard],
        children: [
            { path: '', component: HomeComponent },
            { path: 'import', component: ImportMasterDataComponent },
            { path: 'brand-colors', component: BrandColorsComponent },
            { path: 'project/list', component: ProjectsComponent },
            { path: 'project/:id', component: ProjectItemsComponent },
        ]
    }
];

@NgModule({
    declarations: [
        ImportMasterDataComponent,
        MainComponent,
        HomeComponent,
        ProjectsComponent,
        ProjectItemsComponent,
        ManageInventoryComponent,
        SelectStorageComponent,
        NewProjectDialogComponent,
        NewProjectItemDialogComponent,
        AddItemsTrayDialogComponent,
        NewTrayDialogComponent,
        TrayOverflowOptionsDialogComponent,
        ConfigureTraysComponent,
        BrandColorsComponent,
        DialogShareProjectComponent,
        TrolleyLabelsComponent,
        DialogTrolleyLabelComponent,
        AssembleTrolleysComponent,
        ManageTrolleyComponent,
        NailingTrolleysComponent,
        ManageNailingTrolleyComponent,
        TrolleyReviewComponent,
        DialogEditContentsComponent,
        DialogOverviewContentsComponent
    ],
    imports: [
        CommonModule,
        AuthenticationModule,
        SharedModule,
        NgxDatatableModule,
        DragDropModule,
        ColorPickerModule,
        RouterModule.forRoot(appRoutes),
        TooltipModule.forRoot()
    ],
    entryComponents: [
        NewProjectDialogComponent,
        DialogShareProjectComponent,
        NewProjectItemDialogComponent,
        AddItemsTrayDialogComponent,
        NewTrayDialogComponent,
        TrayOverflowOptionsDialogComponent,
        ManageTrolleyComponent,
        DialogTrolleyLabelComponent,
        ManageNailingTrolleyComponent,
        DialogEditContentsComponent,
        DialogOverviewContentsComponent
    ]
})
export class ProstartAppModule { }
