import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectData } from 'src/app/shared/models/generated/project-data';

@Component({
    selector: 'app-dialog-new-project',
    templateUrl: './dialog-new-project.component.html',
    styleUrls: ['./dialog-new-project.component.scss']
})
export class NewProjectDialogComponent implements OnInit {

    model: ProjectData;

    constructor(
        private dialogRef: MatDialogRef<NewProjectDialogComponent>) { }

    ngOnInit() {
        this.model = new ProjectData();
    }

    save() {
        this.dialogRef.close(this.model);
    }

    close() {
        this.dialogRef.close(false);
    }

}
