import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { CustomHttpInterceptor } from './shared/services/custom-http.interceptor';
import { SharedModule } from './shared/shared.module';
import { ProstartAppModule } from './prostart-app/prostart-app.module';
import { AuthenticationService } from './authentication/services/authentication.service';

const routes: Routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        RouterModule.forRoot(routes),
        BrowserModule,
        BrowserAnimationsModule,
        AuthenticationModule,
        SharedModule,
        ProstartAppModule
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true }],
    entryComponents: [
        AppComponent
    ]
})

export class AppModule {
    constructor(
        private authService: AuthenticationService) {}

        ngDoBootstrap(app) {
            this.authService.appEntry().then(result => {
                app.bootstrap(AppComponent);
            });
        }
 }
