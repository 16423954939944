import { Component, OnInit, Input, Inject } from '@angular/core';
import { NailingTrolleyViewModel } from 'src/app/shared/models/view-model-nailing-trolley';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrolleyConfiguration } from 'src/app/shared/models/generated/trolley-configuration';

@Component({
  selector: 'app-manage-nailing-trolley',
  templateUrl: './manage-nailing-trolley.component.html',
  styleUrls: ['./manage-nailing-trolley.component.scss']
})
export class ManageNailingTrolleyComponent implements OnInit {

  @Input() model: NailingTrolleyViewModel;
  title: string;
  edit: boolean;

  constructor(
    private dialogRef: MatDialogRef<ManageNailingTrolleyComponent>,
    @Inject(MAT_DIALOG_DATA) public configurations: Array<TrolleyConfiguration>) { }

  ngOnInit() {
    if (!this.model) {
      this.title = 'TROLLEYS.NEW';
      this.model = new NailingTrolleyViewModel();
      this.edit = false;
    } else {
      this.title = 'TROLLEYS.EDIT';
      this.edit = true;
    }
  }

  save() {
    this.dialogRef.close(this.model);
  }

  close() {
    this.dialogRef.close();
  }

}
