import { Component, OnInit } from '@angular/core';
import { AccessService } from 'src/app/shared/services/access.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public accessService: AccessService,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.navigate(['project/list']);
  }
}
