import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AddItemsTrayDialogComponent } from '../dialog-add-items-tray/dialog-add-items-tray.component';
import { MatDialog, _MatListItemMixinBase } from '@angular/material';
import { ProjectItem } from 'src/app/shared/models/generated/project-item';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { LoaderService, AlertService } from 'src/app/shared/services';
import { TrayData } from 'src/app/shared/models/generated/tray-data';
import { NewTrayDialogComponent } from '../dialog-new-tray/dialog-new-tray.component';
import { TrayOverflowOptionsDialogComponent } from '../dialog-tray-overflow-options/dialog-tray-overflow-options.component';
import { ViewModelService } from 'src/app/shared/services/view-model.service';
import { ViewModel } from 'src/app/shared/models/view-model';
import { AddItemsToTrayData, AddProjectItemData } from 'src/app/shared/models/addItemsToTray-dialog';
import { TrayType } from 'src/app/shared/models/generated/tray-type';
import { Util } from 'src/app/shared/util';
import { IMultiSelectSettings, IMultiSelectTexts, IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { ProjectItemsFilter, SubBrandInfo, ProductTypeInfo } from 'src/app/shared/models/project-items-filter';
import { ViewModelColumnData } from 'src/app/shared/models/view-model-column-data';
import { EditContentsItem } from 'src/app/shared/models/edit-contents-data';
import { DialogEditContentsComponent } from '../dialog-edit-contents/dialog-edit-contents.component';
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ProjectStatus } from 'src/app/shared/models/generated/project-status';
import { ViewModelTrayData } from 'src/app/shared/models/view-model-tray-data';
import { Tray } from 'src/app/shared/models/generated/tray';
import { ViewModelRowData } from 'src/app/shared/models/view-model-row-data';
import { ViewModelSubBrandInfo } from 'src/app/shared/models/view-model-sub-brand-info';

@Component({
    selector: 'app-configure-trays',
    templateUrl: './configure-trays.component.html',
    styleUrls: ['./configure-trays.component.scss']
})
export class ConfigureTraysComponent implements OnInit, OnChanges, OnDestroy {

    private viewModelSubscription;

    readonly = true;
    projectData: ViewModel;

    showTrayWarning = false;
    activeTrayIndex = -1;
    itemSelection = [];

    columnsInitiatedWidth = 0;
    columnEmptyWidth = 1;

    trayTypes: Array<string>;
    trayType = TrayType;

    projectItems: Array<ProjectItem>;
    filteredProjectItems: Array<ProjectItem>;
    filter: ProjectItemsFilter;
    brands: Array<IMultiSelectOption>;
    subBrands: Array<IMultiSelectOption>;
    productTypes: Array<IMultiSelectOption>;
    autoGeneratedTrays: ViewModelTrayData[];

    multiselect_settings: IMultiSelectSettings = {
        dynamicTitleMaxItems: 0,
        checkedStyle: 'fontawesome',
        enableSearch: false,
        showCheckAll: true,
        showUncheckAll: true
    };

    multiselectTextsBrand: IMultiSelectTexts = {
        defaultTitle: 'Brands'
    };
    multiselectTextsSubBrand: IMultiSelectTexts = {
        defaultTitle: 'Sub-brands'
    };
    multiselectTextsProductType: IMultiSelectTexts = {
        defaultTitle: 'Product types'
    };

    selectAllChecked = false;

    constructor(
        private dialog: MatDialog,
        private projectsService: ProjectsService,
        private loaderService: LoaderService,
        private viewModelService: ViewModelService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.filter = new ProjectItemsFilter();
        this.trayTypes = ['TRAY_TYPE.SHALLOW', 'TRAY_TYPE.DEEP', 'TRAY_TYPE.SHALLOW_SHELF', 'TRAY_TYPE.DEEP_SHELF'];

        this.viewModelSubscription = this.viewModelService.viewModelData.subscribe(() => {
            this.getViewModelData();
            if (this.projectData.trays.length) {
                this.initializeTrays();
            }
        });
    }

    addTray() {
        const dialogRef = this.dialog.open(NewTrayDialogComponent, { data: { isShelf: false } });

        dialogRef.beforeClosed().subscribe((trayData: TrayData) => {
            if (trayData) {
                this.viewModelService.addTray(trayData).subscribe(result => {
                    this.activeTrayIndex = this.projectData.trays.indexOf(result);
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                });
            }
        });
    }

    addShelf() {
        const dialogRef = this.dialog.open(NewTrayDialogComponent, { data: { isShelf: true } });

        dialogRef.beforeClosed().subscribe((trayData: TrayData) => {
            if (trayData) {
                this.viewModelService.addTray(trayData).subscribe(result => {
                    this.activeTrayIndex = this.projectData.trays.indexOf(result);
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                });
            }
        });
    }

    editTray() {
        const me = this;
        const isShelf = this.projectData.trays[me.activeTrayIndex].trayType === TrayType.deepShelf || this.projectData.trays[me.activeTrayIndex].trayType === TrayType.shallowShelf;
        const dialogRef = this.dialog.open(NewTrayDialogComponent, { data: { isShelf: isShelf } });
        dialogRef.componentInstance.model = Util.cloneObject(this.projectData.trays[this.activeTrayIndex]);
        dialogRef.componentInstance.canEditType = true;

        dialogRef.beforeClosed().subscribe((trayData: TrayData) => {
            if (trayData) {
                this.viewModelService.editTray(trayData, me.projectData.trays[me.activeTrayIndex].id).subscribe(result => {
                    this.activeTrayIndex = this.projectData.trays.indexOf(result);
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                });
            }
        });
    }

    chooseOverflowTrayOption(items: AddItemsToTrayData, columnIndex: number) {
        const dialogRef = this.dialog.open(TrayOverflowOptionsDialogComponent, { data: { isShelf: false, hasRoom: this.projectData.trays[this.activeTrayIndex].remainingSpace > 0 } });
        dialogRef.componentInstance.model = new TrayData();

        dialogRef.beforeClosed().subscribe((action: string) => {
            if (action === "createNewTray") {
                const trayData = new TrayData();
                trayData.trayType = this.projectData.trays[this.activeTrayIndex].trayType;
                trayData.name = this.projectData.trays[this.activeTrayIndex].name + ' - Overflow';
                trayData.sequenceNumber = this.projectData.trays[this.activeTrayIndex].sequenceNumber + 1;

                this.viewModelService.addTray(trayData).subscribe(result => {
                    this.activeTrayIndex = this.projectData.trays.indexOf(result);
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                    this.refreshProjectItems();

                    const updateResult = this.viewModelService.updateTrayData(items, this.activeTrayIndex, true, 0);

                    if (updateResult) {
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                    this.refreshProjectItems();
                    } else {
                        for (let trayItem of items.items) {
                            this.viewModelService.autoAddItemsToTray(trayItem, this.projectData.trays[this.activeTrayIndex]);
                        }
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                    this.refreshProjectItems();
                    }
                });
            } else if (action === "fillTrayToCapacity") {
                //fill the tray to capacity
                for (let trayItem of items.items) {
                    this.viewModelService.autoAddItemsToTray(trayItem, this.projectData.trays[this.activeTrayIndex]);
                }
                this.initializeTrays();
                this.recalculateTrayWarning();
                this.refreshProjectItems();
            }
        });
    }

    autoGenerateTrays() {
        const projectItems = this.projectItems.filter(_ => _.quantity > 0 && _.masterSequence && (_.itemStorageType.toLowerCase() === 'deep' || _.itemStorageType.toLowerCase() === 'shallow'))
            .sort((a, b) => Util.localeCompareSafe(a.itemCode, b.itemCode));
        const data = [];
        const columns = new Array<ViewModelColumnData>();

        if (!projectItems.length) {
            return;
        }

        projectItems.forEach(item => {
            const firstSequenceChar = item.masterSequence;
            const itemStorageType = item.itemStorageType.toLowerCase();
            const sequence = data.find(_ => _.sequenceChar === firstSequenceChar && _.storageType === itemStorageType);

            if (sequence) {
                const dataItem = sequence.sequences.find(_ => _.masterSequence === item.masterSequence);

                if (dataItem) {
                    dataItem.items.push(item);
                } else {
                    sequence.sequences.push({
                        masterSequence: item.masterSequence,
                        items: [item]
                    })
                }
            } else {
                data.push({
                    sequenceChar: firstSequenceChar,
                    storageType: itemStorageType,
                    sequences: [{
                        masterSequence: item.masterSequence,
                        items: [item]
                    }]
                });
            }
        });

        data.forEach(sequenceItem => {
            sequenceItem.sequences.forEach(masterSequenceItem => {
                columns.push({
                    remainingSpace: 1,
                    masterSequence: masterSequenceItem.masterSequence,
                    columnWidth: null,
                    rows: new Array<ViewModelRowData>(),
                    masterSequenceChar: sequenceItem.sequenceChar,
                    storageType: sequenceItem.storageType
                });
                let activeColumnIndex = columns.length - 1;

                masterSequenceItem.items.forEach((item: ProjectItem) => {
                    let column = columns[activeColumnIndex];

                    if (item.itemWidth < column.columnWidth || column.columnWidth === null) {
                        column.columnWidth = item.itemWidth;
                    }

                    for (let i = 1; i <= item.quantity; i++) {
                        let remaining = parseFloat(Math.round((column.remainingSpace - (1 / item.itemLength)) + 'e10' as any) + 'e-10');
                        let result = isNaN(remaining) ? 0 : remaining;

                        if (result < 0) {
                            columns.push({
                                remainingSpace: 1,
                                masterSequence: masterSequenceItem.masterSequence,
                                rows: new Array<ViewModelRowData>(),
                                columnWidth: 0,
                                masterSequenceChar: sequenceItem.sequenceChar,
                                storageType: sequenceItem.storageType
                            });
                            activeColumnIndex = columns.length - 1;
                            column = columns[activeColumnIndex];
                            column.columnWidth = item.itemWidth;

                            remaining = parseFloat(Math.round((column.remainingSpace - (1 / item.itemLength)) + 'e10' as any) + 'e-10');
                            result = isNaN(remaining) ? 0 : remaining;
                        }

                        column.remainingSpace = result;
                        const projectItem = new AddProjectItemData(item);
                        column.rows.push(new ViewModelRowData(projectItem));
                    }
                });
            });
        });

        if (!columns.length) {
            return;
        }

        let trayRemaining = 1;
        let lastChar = columns[0].masterSequenceChar;
        let lastStorageType = columns[0].storageType;
        this.generateTray(lastStorageType);

        columns.forEach(colItem => {
            let remaining = parseFloat(Math.round((trayRemaining - (1 / colItem.columnWidth)) + 'e10' as any) + 'e-10');
            let result = isNaN(remaining) ? 0 : remaining;
            let activeTrayIndex = this.autoGeneratedTrays.length - 1;

            if (result < 0 || lastChar !== colItem.masterSequenceChar || lastStorageType !== colItem.storageType) {
                trayRemaining = 1;
                this.generateTray(colItem.storageType);
                activeTrayIndex = this.autoGeneratedTrays.length - 1;

                remaining = parseFloat(Math.round((trayRemaining - (1 / colItem.columnWidth)) + 'e10' as any) + 'e-10');
                result = isNaN(remaining) ? 0 : remaining;
            }

            if (!this.autoGeneratedTrays[activeTrayIndex].name) {
                this.autoGeneratedTrays[activeTrayIndex].name = `${colItem.rows[0].itemBrand} ${activeTrayIndex + 1}`;
            }

            trayRemaining = result;
            this.autoGeneratedTrays[activeTrayIndex].remainingSpace = result;
            this.autoGeneratedTrays[activeTrayIndex].columns.push(colItem);
            this.autoGeneratedTrays[activeTrayIndex].calculateBrandColors();
            this.autoGeneratedTrays[activeTrayIndex].calculateName();
            lastChar = colItem.masterSequenceChar;
            lastStorageType = colItem.storageType;
        });
    }

    generateTray(storageType: string) {
        const tray = new Tray();
        const trayVM = new ViewModelTrayData(tray);

        if (storageType.toUpperCase() === 'DEEP') {
            trayVM.trayType = TrayType.deep;
        } else if (storageType.toUpperCase() === 'SHALLOW') {
            trayVM.trayType = TrayType.shallow;
        }

        trayVM.name = null;
        trayVM.remainingSpace = 1;
        trayVM.columns = [];
        trayVM.brandColors = [];

        trayVM.sequenceNumber = Math.max(...this.autoGeneratedTrays.map(t => t.sequenceNumber), 0) + 1;
        this.autoGeneratedTrays.push(trayVM);
    }

    nameLines(): string[] {
        if (this.projectData.trays[this.activeTrayIndex].name) {
            return this.projectData.trays[this.activeTrayIndex].name.split(/[\n,\r]/);
        }
        return [];
    }

    autoFill() {
        this.clear();
        this.autoGeneratedTrays = [];

        this.autoGenerateTrays();

        this.viewModelService.createTrays(this.autoGeneratedTrays).subscribe(response => {
            this.viewModelService.initializeTrays(response, this.projectItems);
            this.getViewModelData();

            setTimeout(() => {
                this.initializeTrays();
            });

            this.alertService.success('Trays data successfully generated');
        });
    }

    changeSelection(event, item) {
        if (event.target.checked) {
            this.itemSelection.push(item);
        } else {
            this.itemSelection.splice(this.itemSelection.indexOf(item), 1);
        }
        if (this.itemSelection.length === this.filteredProjectItems.filter(p => p.quantity > 0).length) {
            this.selectAllChecked = true;
        } else {
            this.selectAllChecked = false;
        }
    }

    selectAlls(event) {
        if (event.target.checked) {
            this.itemSelection = [...this.filteredProjectItems.filter(p => p.quantity > 0)];
            this.selectAllChecked = true;
        } else {
            this.itemSelection = [];
            this.selectAllChecked = false
        }
    }

    dropEmpty(event: CdkDragDrop<number>) {
        if (this.projectData.trays[this.activeTrayIndex].trayType === TrayType.shallowShelf || this.projectData.trays[this.activeTrayIndex].trayType === TrayType.deepShelf) {
            this.dropToShelf(event);
        } else {
            if (this.itemSelection.length == 0 && event.item.data && this.itemSelection.findIndex(i => i.id == event.item.data) === -1) {
                this.itemSelection.push(this.filteredProjectItems.find(p => p.id == event.item.data));
            }
            this.addItemsToTray(this.itemSelection, true, null);
        }
    }

    dropExisting(event: CdkDragDrop<number>, columnIndex: number) {
        if (this.itemSelection.length == 0 && event.item.data && this.itemSelection.findIndex(i => i.id == event.item.data) === -1) {
            this.itemSelection.push(this.filteredProjectItems.find(p => p.id == event.item.data));
        }

        this.addItemsToTray(this.itemSelection, false, columnIndex);
    }

    dropToShelf(event: CdkDragDrop<number>) {
        if (this.itemSelection.length == 0 && event.item.data && this.itemSelection.findIndex(i => i.id == event.item.data) === -1) {
            this.itemSelection.push(this.filteredProjectItems.find(p => p.id == event.item.data));
        }

        this.addItemsToShelf(this.itemSelection);
    }

    changeActiveTray(direction: String) {
        if (this.activeTrayIndex < this.projectData.trays.length && this.projectData.trays.length > 1) {
            this.updateTray(false);
        }

        if (!this.projectData || !this.projectData.trays || this.projectData.trays.length < 1) {
            this.activeTrayIndex = -1;
        } else if (direction === '<') {
            if (this.activeTrayIndex <= 0) {
                this.activeTrayIndex = (this.projectData.trays.length - 1);
            } else {
                this.activeTrayIndex -= 1;
            }
        } else if (direction === '>') {
            if (this.activeTrayIndex >= (this.projectData.trays.length - 1)) {
                this.activeTrayIndex = 0;
            } else {
                this.activeTrayIndex += 1;
            }
        }

        this.calculateColumnsWidth();
        this.recalculateTrayWarning();
    }

    addItemsToTray(items: ProjectItem[], addColumn: boolean, columnIndex: number) {
        if (!items.length) {
            return;
        }

        const dialogData = new AddItemsToTrayData();
        dialogData.items = items.map(i => new AddProjectItemData(i));
        dialogData.addColumn = addColumn;

        const dialogRef = this.dialog.open(AddItemsTrayDialogComponent);
        dialogRef.componentInstance.data = dialogData;
        dialogRef.componentInstance.trayType = this.projectData.trays[this.activeTrayIndex].trayType;

        dialogRef.beforeClosed().subscribe((result: AddItemsToTrayData) => {
            if (result) {
                const updateResult = this.viewModelService.updateTrayData(result, this.activeTrayIndex, addColumn, columnIndex);

                if (updateResult) {
                    this.initializeTrays();
                    this.recalculateTrayWarning();
                } else {
                    this.chooseOverflowTrayOption(result, columnIndex);
                }

            }

            this.refreshProjectItems();
        });
    }

    addItemsToShelf(items: ProjectItem[]) {
        if (!items.length) {
            return;
        }

        const dialogData = new AddItemsToTrayData();
        dialogData.items = items.map(i => new AddProjectItemData(i));

        const dialogRef = this.dialog.open(AddItemsTrayDialogComponent);
        dialogRef.componentInstance.data = dialogData;
        dialogRef.componentInstance.trayType = this.projectData.trays[this.activeTrayIndex].trayType;

        dialogRef.beforeClosed().subscribe((result: AddItemsToTrayData) => {
            if (result) {
                const updateResult = this.viewModelService.updateShelfData(result, this.activeTrayIndex);

                if (updateResult) {
                    this.initializeTrays();
                }
            }
            this.refreshProjectItems();
        });
    }

    updateTray(showMessage: boolean = true) {
        const index = this.activeTrayIndex;
        if (!this.readonly && index >= 0 && this.projectData && this.projectData.trays && this.projectData.trays.length > 0) {
            this.viewModelService.saveTray(this.projectData.trays[index]).subscribe((data: any) => {
                if (data && data.subBrands) {
                    this.projectData.trays[index].subBrands = [];

                    data.subBrands.forEach(brand => {
                        brand.itemSizeInfos.sort((b1, b2) => b1.itemCode > b2.itemCode ? 1 : -1);

                        const subBrand: ViewModelSubBrandInfo = {
                            name: brand.name,
                            brandColor: brand.brandColor,
                            minSize: brand.itemSizeInfos[0].size,
                            maxSize: brand.itemSizeInfos[brand.itemSizeInfos.length - 1].size
                        };

                        this.projectData.trays[index].subBrands.push(subBrand);
                    });
                }

                if (showMessage) {
                    const isShelf = this.projectData.trays[index].trayType === TrayType.shallowShelf || this.projectData.trays[index].trayType === TrayType.deepShelf ? true : false;
                    this.alertService.success(isShelf ? 'Shelf data successfully saved' : 'Tray data successfully saved');
                }
            });
        }
    }

    changeFilter(filterType: number) {
        switch (filterType) {
            case 1:
                this.filter.subBrands = [];
                this.filter.productTypes = [];
                break;
            case 2:
                this.filter.productTypes = []
                break;
        }

        this.populateFilters();

        let filteredProjectItems = Util.cloneObject(this.projectItems);

        if (this.filter.text) {
            filteredProjectItems = filteredProjectItems.filter(_ => {
                if (!_.itemCode || !_.itemDescription) {
                    return false;
                }

                return _.itemCode.toUpperCase().includes(this.filter.text.toUpperCase()) ||
                    _.itemDescription.toUpperCase().includes(this.filter.text.toUpperCase());
            });
        }

        if (this.filter.brands && this.filter.brands.length) {
            filteredProjectItems = filteredProjectItems.filter(item => this.filter.brands.indexOf(item.itemBrand) > -1);
        }

        if (this.filter.subBrands && this.filter.subBrands.length) {
            filteredProjectItems = filteredProjectItems.filter(item => this.filter.subBrands.indexOf(item.itemSubBrand) > -1);
        }

        if (this.filter.productTypes && this.filter.productTypes.length) {
            filteredProjectItems = filteredProjectItems.filter(item => this.filter.productTypes.indexOf(item.itemProductType) > -1);
        }

        this.filteredProjectItems = filteredProjectItems;
        this.resetItemSelection();
    }

    editTrayContents(column: ViewModelColumnData) {
        let items = [];
        column.rows.forEach((row, idx) => {
            if (row.projectItemId) {
                const projectItem = this.projectItems.find(p => p.id == row.projectItemId);
                if (projectItem) {
                    const item = new EditContentsItem(projectItem, idx);
                    items.unshift(item);
                }
            }
        });

        if (items.length > 0) {
            const dialogRef = this.dialog.open(DialogEditContentsComponent);
            dialogRef.componentInstance.items = items;
            dialogRef.componentInstance.readonly = this.readonly;
            dialogRef.componentInstance.trayType = this.projectData.trays[this.activeTrayIndex].trayType;

            dialogRef.beforeClosed().subscribe((result: EditContentsItem[]) => {
                this.viewModelService.updateTrayColumnContents(this.activeTrayIndex, column, result);
                this.initializeTrays();
                this.recalculateTrayWarning();
                this.refreshProjectItems();
            });
        }
    }

    deleteTray() {
        const isShelf = this.projectData.trays[this.activeTrayIndex].trayType === TrayType.deepShelf || this.projectData.trays[this.activeTrayIndex].trayType === TrayType.shallowShelf;
        const dialogData: ConfirmDialogData = {
            title: isShelf ? 'SHELF.DELETE_TITLE' : 'TRAY.DELETE_TITLE',
            message: isShelf ? 'SHELF.DELETE_MESSAGE' : 'TRAY.DELETE_MESSAGE'
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
        confirmDialogRef.componentInstance.dialogData = dialogData;

        confirmDialogRef.beforeClosed().subscribe((result) => {
            if (result) {
                this.viewModelService.deleteTray(this.activeTrayIndex).subscribe(() => {
                    this.changeActiveTray('>');
                    this.refreshProjectItems();
                });
            }
        });
    }

    isTray() {
        if (this.projectData.trays.length > 0 && this.activeTrayIndex < this.projectData.trays.length && (this.projectData.trays[this.activeTrayIndex].trayType === TrayType.shallow || this.projectData.trays[this.activeTrayIndex].trayType === TrayType.deep)) {
            return true;
        }
        return false;
    }

    isShelf() {
        if (this.projectData.trays.length > 0 && this.activeTrayIndex < this.projectData.trays.length && (this.projectData.trays[this.activeTrayIndex].trayType === TrayType.shallowShelf || this.projectData.trays[this.activeTrayIndex].trayType === TrayType.deepShelf)) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        if (this.viewModelSubscription) {
            this.viewModelSubscription.unsubscribe();
        }
    }

    ngOnChanges() {
        if (this.viewModelSubscription) {
            this.viewModelSubscription.unsubscribe();
        }
    }

    private getViewModelData() {
        this.projectData = this.viewModelService.getData();
        this.readonly = this.projectData.projectStatus == ProjectStatus.finalized;
        if (this.projectData.trays.length > 0) {
            this.activeTrayIndex = 0;
        }

        this.brands = [];
        this.subBrands = [];
        this.productTypes = [];

        if (this.projectData) {
            this.recalculateTrayWarning();
            this.refreshProjectItems();
        }
    }

    private refreshProjectItems() {
        const showTypes = ['SHALLOW', 'DEEP', 'NAILING'];
        const remainingProjectItems = this.viewModelService.calculateRemainingProjectItems();
        this.projectItems = remainingProjectItems.filter(p => p.itemStorageType && showTypes.indexOf(p.itemStorageType.toLocaleUpperCase()) > -1);
        this.projectItems.sort((a, b) => Util.localeCompareSafe(a.masterSequence, b.masterSequence));
        this.changeFilter(0);
    }

    private populateFilters() {
        let brands = [];
        let subBrands = new Array<SubBrandInfo>();
        let productTypes = new Array<ProductTypeInfo>();

        if (this.projectItems) {
            this.projectItems.forEach(item => {
                if (item.quantity > 0) {
                    if (item.itemBrand && brands.indexOf(item.itemBrand) === -1) {
                        brands.push(item.itemBrand);
                    }

                    if (item.itemSubBrand && !subBrands.some(sb => sb.subBrand == item.itemSubBrand)) {
                        subBrands.push(new SubBrandInfo(item));
                    }

                    if (item.itemProductType && !productTypes.some(pt => pt.productType == item.itemProductType)) {
                        productTypes.push(new ProductTypeInfo(item));
                    }
                }
            });
        }

        brands = brands.sort((a, b) => a > b ? 1 : -1);
        subBrands = subBrands.sort((a, b) => a.subBrand > b.subBrand ? 1 : -1);
        productTypes = productTypes.sort((a, b) => a.productType > b.productType ? 1 : -1);

        this.brands = brands.map(item => {
            return { id: item, name: item };
        });

        // make sure brand is not selected if not available
        if (this.filter.brands && this.filter.brands.length > 0) {
            this.filter.brands = this.filter.brands.filter(b => brands.indexOf(b) >= 0);
        }

        // filter out sub brands and product types by selected brands
        if (this.filter.brands && this.filter.brands.length > 0) {
            subBrands = subBrands.filter(sb => this.filter.brands.indexOf(sb.brand) >= 0);
            productTypes = productTypes.filter(pt => this.filter.brands.indexOf(pt.brand) >= 0);
        }

        this.subBrands = subBrands.map(item => {
            return { id: item.subBrand, name: item.subBrand };
        });

        // make sure sub brand is not selected if not available
        if (this.filter.subBrands && this.filter.subBrands.length > 0) {
            this.filter.subBrands = this.filter.subBrands.filter(fsb => subBrands.some(sb => sb.subBrand == fsb));
        }

        // filter product types by subbrands
        if (this.filter.subBrands && this.filter.subBrands.length > 0) {
            productTypes = productTypes.filter(pt => this.filter.subBrands.indexOf(pt.subBrand) >= 0);
        }

        this.productTypes = productTypes.map(item => {
            return { id: item.productType, name: item.productType };
        });

        // make sure product type is not selected if not available
        if (this.filter.productTypes && this.filter.productTypes.length > 0) {
            this.filter.productTypes = this.filter.productTypes.filter(fpt => productTypes.some(pt => pt.productType == fpt));
        }
    }

    private initializeTrays() {
        this.calculateColumnsWidth();
    }

    private calculateColumnsWidth() {
        if (this.activeTrayIndex >= 0) {
            this.columnsInitiatedWidth = this.viewModelService.getColumnsInitiatedWidth(this.projectData.trays[this.activeTrayIndex]);
            this.columnEmptyWidth = 1 - this.columnsInitiatedWidth;
        }
    }

    private resetItemSelection() {
        this.itemSelection = [];
        this.selectAllChecked = false;
    }

    private recalculateTrayWarning() {
        this.showTrayWarning = false;
        if (this.activeTrayIndex >= 0 && this.projectData.trays && this.projectData.trays.length > 0) {
            const tray = this.projectData.trays[this.activeTrayIndex];
            if (tray.trayType == TrayType.shallow) {
                for (const trayColumn of tray.columns) {
                    for (const trayRow of trayColumn.rows) {
                        const projectItem = this.projectData.projectItems.find(p => p.id == trayRow.projectItemId);
                        if (projectItem && projectItem.itemStorageType && projectItem.itemStorageType.toLocaleUpperCase() == 'DEEP') {
                            this.showTrayWarning = true;
                            return;
                        }
                    }
                }
            }
        }
    }

    private clear() {
        this.viewModelService.clearTrays();

        // this.initializeTrays();
        this.refreshProjectItems();
    }
}
