﻿import { TrayData } from './tray-data';
import { TrayProjectItem } from './tray-project-item';

export class TrayUpdateData extends TrayData {
    public items: TrayProjectItem[];

    constructor() {
        super();
        this.items = [];
    }
}
