import { Trolley } from './generated/trolley';
import { ProjectStatus } from './generated/project-status';
import { StorageSystem } from './generated/storage-system';
import { ViewModelColumnData } from './view-model-column-data';
import { ViewModelTrayData } from './view-model-tray-data';
import { ProjectItem } from './generated/project-item';
import { TrolleyConfiguration } from './generated/trolley-configuration';
import { TrolleyDoorType } from './generated/trolley-door-type';

export class ViewModel {
    public name: string;
    public projectId: number;
    public projectItems: ProjectItem[];
    public projectStatus: ProjectStatus;
    public storageSystem: StorageSystem;
    public trolleyDoorType: TrolleyDoorType;
    public trays: ViewModelTrayData[];
    public nailingTrays: ViewModelTrayData[];
    public trolleys: Trolley[];
    public trolleyConfiguration: TrolleyConfiguration[];

    constructor() {
        this.name = '';
        this.projectId = null;
        this.projectStatus = null;
        this.storageSystem = null;
        this.trolleyDoorType = null;
        this.projectItems = [];
        this.trays = [];
        this.nailingTrays = [];
        this.trolleys = [];
        this.trolleyConfiguration = [];
    }
}
