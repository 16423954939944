import { ViewModelRowData } from './view-model-row-data';

export class ViewModelColumnData {
    public columnWidth: number;
    public remainingSpace: number;
    public rows: ViewModelRowData[];
    public masterSequence: string;
    public masterSequenceChar: string;
    public storageType: string;

    constructor() {
        this.remainingSpace = 1;
        this.columnWidth = 0;
        this.rows = [];
        this.masterSequence = null;
        this.masterSequenceChar = null;
        this.storageType = null;
    }
}
