import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService, LoaderService } from '.';
import { BASE_URL } from '../../../environments/environment';
import { ApiOptions } from '../models/api-options';
import { StorageService } from './storage.service';

@Injectable()
export class ApiService {

    private jwtHelper = new JwtHelperService();

    constructor(
        private httpService: HttpClient,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private storageService: StorageService ) { }

    get<T>(url: string, options?: ApiOptions) {
        if (!options) { options = new ApiOptions(); }

        return this.httpService.get<T>(BASE_URL + url)
            .pipe(
                catchError(error => {
                    this.loaderService.hideAllLoaders();
                    if (options.showGlobalError) { this.alertService.error(error); }
                    return throwError(error);
                })
            );
    }

    post<T>(url: string, data: any, options?: ApiOptions) {
        if (!options) { options = new ApiOptions(); }

        return this.httpService.post<T>(BASE_URL + url, data)
            .pipe(
                catchError(error => {
                    this.loaderService.hideAllLoaders();
                    if (options.showGlobalError) { this.alertService.error(error); }
                    return throwError(error);
                })
            );
    }

    postString(url: string, data: string, options?: ApiOptions) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        if (!options) { options = new ApiOptions(); }

        return this.httpService.post(BASE_URL + url, JSON.stringify(data), { headers: headers })
            .pipe(
                catchError(error => {
                    this.loaderService.hideAllLoaders();
                    if (options.showGlobalError) { this.alertService.error(error); }
                    return throwError(error);
                })
            );
    }

    put<T>(url: string, data: any, options?: ApiOptions) {
        if (!options) { options = new ApiOptions(); }

        return this.httpService.put<T>(BASE_URL + url, data)
            .pipe(
                catchError(error => {
                    this.loaderService.hideAllLoaders();
                    if (options.showGlobalError) { this.alertService.error(error); }
                    return throwError(error);
                })
            );
    }

    delete(url: string, options?: ApiOptions) {
        if (!options) { options = new ApiOptions(); }

        return this.httpService.delete(BASE_URL + url)
            .pipe(
                catchError(error => {
                    this.loaderService.hideAllLoaders();
                    if (options.showGlobalError) { this.alertService.error(error); }
                    return throwError(error);
                })
            );
    }

    getFile(url: string, data: any, options?: ApiOptions) {
        if (!options) { options = new ApiOptions(); }

        return this.httpService.post(BASE_URL + url, data)
            .pipe(
                catchError(error => {
                    this.loaderService.hideAllLoaders();
                    if (options.showGlobalError) { this.alertService.error(error); }
                    return throwError(error);
                })
            );
    }

    sendEmail(url: string, data: any, options?: ApiOptions) {
        if (!options) { options = new ApiOptions(); }

        return this.httpService.post(BASE_URL + url, data)
            .pipe(
                catchError(error => {
                    this.loaderService.hideAllLoaders();
                    if (options.showGlobalError) { this.alertService.error(error); }
                    return throwError(error);
                })
            );
    }
}
