import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API_URL, BASE_URL, environment } from '../../../environments/environment';
import { AlertService } from '../../shared/services';
import { StorageService } from '../../shared/services/storage.service';
import { AuthenticationInfoModel, LoginData, LoginInfo, NewPassword, RefreshTokenData, SetPasswordModel } from '../models/login-data';
import { PlatformService } from './platform.service';

@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private storageService: StorageService,
        private platformService: PlatformService,
        private alertService: AlertService) { }
    private jwtHelper = new JwtHelperService();

    private urls = {
        authentication: API_URL + 'Authentication/Authentication',
        ssoAuthentication: API_URL + 'Authentication/SingleSignOnAuthentication',
        platformAuthentication: API_URL + 'Authentication/PlatformAuthentication',
        authenticationRefresh: API_URL + 'Authentication/AuthenticationRefresh',
        resetPasswordRequest: API_URL + 'Authentication/ResetPasswordRequest',
        resetPassword: API_URL + 'Authentication/ResetPassword',
    };

    login(loginData: LoginData) {

        const loginInfo = new LoginInfo(loginData.username, loginData.password);
        return this.http.post(BASE_URL + this.urls.authentication, loginInfo)
            .pipe(
                map((response: AuthenticationInfoModel) => {

                    if (response.token) {
                        this.storageService.setSecurityToken(response.token);
                        this.storageService.setRefreshToken(response.refreshToken);
                        this.storageService.setUserRole(response.token);
                    }

                }),
                catchError(error => {
                    return throwError(error);
                })
            );
    }

    loginSso(code: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(BASE_URL + this.urls.ssoAuthentication, JSON.stringify(code), { headers: headers})
            .pipe(
                map((response: AuthenticationInfoModel) => {
                    if (response.token) {
                        this.storageService.setSecurityToken(response.token);
                        this.storageService.setRefreshToken(response.refreshToken);
                        this.storageService.setUserRole(response.token);
                    }
                }),
                catchError(error => {
                    return throwError(error);
                })
            );
    }

    tryLoginPlatformSSO(resolve, reject, retries = 5) {
        const cmp = this;
        const tokenFailure = () => {
            cmp.alertService.error('The token cannot be retrieved');
            reject();
        };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const tokenRetrieved = (data: any) => {
            const tokenObject = JSON.parse(data);
            
            const fullUrl = this.urls.platformAuthentication;
            cmp.http.post(fullUrl, JSON.stringify(tokenObject.token), { headers: headers})
                .subscribe((response: AuthenticationInfoModel) => {
                    if (response) {
                        cmp.storageService.setSecurityToken(response.token);
                        cmp.storageService.setRefreshToken(response.refreshToken);
                        cmp.storageService.setUserRole(response.token);
                    }

                    resolve(response);
                }, () => {
                    if (retries > 0) {
                        cmp.tryLoginPlatformSSO(resolve, reject, retries - 1);
                    } else {
                        reject();
                    }
                });
        };

        this.platformService.initialize().then(() => {
            const obj = {
                successCallback: tokenRetrieved,
                failureCallback: tokenFailure
            };
            DKPlugin.getAuthToken(obj);
        });
    }

    isLoggedIn() {
        const token = this.storageService.getSecurityToken();

        if (!token) {
            return false;
        }

        return true;
    }

    needRefresh() {
        const token = this.storageService.getSecurityToken();

        const decode = this.jwtHelper.decodeToken(token);
        const expTime = decode['exp'];
        const now = moment().unix();

        if ((+expTime - +now) < 600) {
            return true;
        }

        return false;
    }

    refresh()  {
        if (environment.dink) {
            return this.loginPlatformSSO().pipe(map((newToken: AuthenticationInfoModel) => {
                if (newToken) {
                    this.storageService.setSecurityToken(newToken.token);
                    this.storageService.setRefreshToken(newToken.refreshToken);
                    this.storageService.setUserRole(newToken.token);
                }

                return newToken;
            }));
        } else {
            const securityToken = this.storageService.getSecurityToken();
            const refreshToken = this.storageService.getRefreshToken();
            const refreshData = new RefreshTokenData(securityToken, refreshToken);

            return this.http.post(BASE_URL + this.urls.authenticationRefresh, refreshData, { headers: { 'X-META-REFRESH': 'Refresh' } })
                .pipe(map((newToken: AuthenticationInfoModel) => {
                    if (newToken) {
                        this.storageService.setSecurityToken(newToken.token);
                        this.storageService.setRefreshToken(newToken.refreshToken);
                        this.storageService.setUserRole(newToken.token);
                    }

                    return newToken;
                }));
        }
    }

    resetPassword(email) {
        return this.http.post(BASE_URL + this.urls.resetPasswordRequest, email);
    }

    newPassword(data: NewPassword) {
        const model = new SetPasswordModel(data.username, data.password, data.resetCode);
        model.firstName = data.firstName;
        model.lastName = data.lastName;
        return this.http.post(BASE_URL + this.urls.resetPassword, model);
    }

    logout() {
        this.storageService.removeSecurityToken();
        this.storageService.removeRefreshToken();

        this.router.navigate(['/login']);
    }

    roleAccess(roles: Array<string>) {
        const role = this.storageService.getUserRole();

        if (!role || roles.length === 0) {
            return false;
        }

        if (roles.indexOf(role) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    tryLoginPlatformSSOPromise() {
        return new Promise((resolve, reject) => {
            this.tryLoginPlatformSSO(resolve, reject);
        })
    }

    loginPlatformSSO() {
        return from(this.tryLoginPlatformSSOPromise());
    }

    appEntry() {
        return new Promise((resolve, reject) => {
            // use automatic login inside dink environment
            if (environment.dink) {
                this.tryLoginPlatformSSOPromise().then(() => resolve(null), () => reject());
            } else {
                const cmp = this;
                const code = cmp.getQueryParam('code');
                const error = cmp.getQueryParam('error');

                if (code) {
                    cmp.loginSso(code).subscribe(
                        () => {
                            window.location.href = window.location.origin + '/#';
                        }, () => {
                            window.location.href = window.location.origin + '/#/login';
                        });
                } else if (error) {
                    window.location.href = window.location.origin + '/#/login';
                } else {
                    if (this.isLoggedIn()) {
                        if (this.needRefresh()) {
                            this.refresh().subscribe(() => {
                                resolve(null);
                            }, () => {
                                resolve(null);
                                this.logout();
                            });
                        } else {
                            resolve(null);
                        }
                    } else if (environment.ssoUrl && this.storageService.getUseSso()) {
                        this.storageService.removeSecurityToken();
                        this.storageService.removeRefreshToken();
                        window.location.href = environment.ssoUrl;
                    } else {
                        resolve(null);
                        this.logout();
                    }
                }
            }
        });
    }

    private getQueryParam(name) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');

        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}
