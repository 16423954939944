import { ApiService } from './api.service';
import { TrolleyLabel } from '../models/generated/trolley-label';
import { API_URL } from 'src/environments/environment';
import { TrolleyLabelBase } from '../models/generated/trolley-label-base';
import { TrolleyLabelVMTray, TrolleyLabelVMLabel, TrolleyLabelViewModel, TrolleyLabelVMTrolley, TrolleyLabelVMCompartment } from '../models/view-model-trolley-label';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ViewModel } from '../models/view-model';
import { ViewModelTrayData } from '../models/view-model-tray-data';
import { ProjectItem } from '../models/generated/project-item';
import { TrolleyProjectItem } from '../models/generated/trolley-project-item';
import { TrolleyType } from '../models/generated/trolley-type';

@Injectable()
export class TrolleyLabelsService {

    private urls = {
        Projects: API_URL + 'Projects',
        TrolleyLabels: 'TrolleyLabels'
    };

    constructor(
        private apiService: ApiService
    ) {}

    loadViewModel(projectData: ViewModel) {
        return this.getTrolleyLabels(projectData.projectId).pipe(map(result => {
            return this.initializeViewModel(projectData, result);
        }));
    }

    saveTrayLabels(projectId: number, trolleyId: number, trayId: number, labels: TrolleyLabelVMLabel[]) {
        return this.saveLabels(projectId, trolleyId, trayId, null, labels);
    }

    saveCompartmentLabels(projectId: number, trolleyId: number, compartment: number, labels: TrolleyLabelVMLabel[]) {
        return this.saveLabels(projectId, trolleyId, null, compartment, labels);
    }

    getTrolleyLabels(projectId: number) {
        return this.apiService.get<TrolleyLabel[]>(this.trolleyLabelsUrl(projectId));
    }

    createTrolleyLabel(projectId: number, data: TrolleyLabelBase) {
        return this.apiService.post<TrolleyLabelBase>(this.trolleyLabelsUrl(projectId), data);
    }

    updateTrolleyLabel(projectId: number, id: number, data: TrolleyLabelBase) {
        return this.apiService.put<TrolleyLabel>(`${this.trolleyLabelsUrl(projectId)}/${id}`, data);
    }

    deleteTrolleyLabel(projectId: number, id: number) {
        return this.apiService.delete(`${this.trolleyLabelsUrl(projectId)}/${id}`);
    }

    private saveLabels(projectId: number, trolleyId: number, trayId: number, compartment: number, labels: TrolleyLabelVMLabel[]) {
        let $obsArray = [];
        let sequenceNumber = 0;
        for (let trayLabel of labels) {
            if (trayLabel.id && trayLabel.deleted) {
                $obsArray.push(this.deleteTrolleyLabel(projectId, trayLabel.id));
            } else {
                let label = new TrolleyLabelBase();
                label.description = trayLabel.description;
                label.sequenceNumber = sequenceNumber++;
                label.title = trayLabel.title;
                label.trayId = trayId;
                label.compartment = compartment;
                label.trolleyId = trolleyId;

                if (trayLabel.id) {
                    $obsArray.push(this.updateTrolleyLabel(projectId, trayLabel.id, label));
                } else {
                    $obsArray.push(this.createTrolleyLabel(projectId, label));
                }
            }
        }

        const $obs = forkJoin($obsArray).pipe(map((result:TrolleyLabel[]) => {
            let labels = [];
            for (let label of result) {
                if (label && label.id) {
                    labels.push(new TrolleyLabelVMLabel(label));
                }
            }

            return labels;
        }));

        return $obs;
    }

    private initializeViewModel(projectData: ViewModel, labels: TrolleyLabel[]) {
        let vm = new TrolleyLabelViewModel();
        vm.projectId = projectData.projectId;

        for (let trolley of projectData.trolleys.sort((t1, t2) => t1.sequenceNumber - t2.sequenceNumber)) {
            let trolleyVM = new TrolleyLabelVMTrolley();
            trolleyVM.id = trolley.id;
            trolleyVM.description = trolley.description;

            const trolleyLabels = labels.filter(l => l.trolleyId == trolley.id);

            for (let trayId of trolley.trays) {
                let tray = null;
                if (trolley.trolleyType == TrolleyType.trays) {
                    tray = projectData.trays.find(t => t.id == trayId);
                } else {
                    tray = projectData.nailingTrays.find(t => t.id == trayId);
                }

                if (tray) {
                    let trayVM = new TrolleyLabelVMTray();
                    trayVM.id = tray.id;
                    trayVM.name = tray.name;
                    trayVM.type = tray.trayType;
                    trayVM.color = this.findTrayColor(tray, projectData.projectItems);

                    for (let label of trolleyLabels.filter(l => l.trayId == tray.id).sort((l1, l2) => l1.sequenceNumber - l2.sequenceNumber)) {
                        trayVM.labels.push(new TrolleyLabelVMLabel(label));
                    }

                    trolleyVM.trays.push(trayVM);
                }
            }

            if (trolley.trolleyConfigurationId) {
                const configuration = projectData.trolleyConfiguration.find(c => c.id == trolley.trolleyConfigurationId);
                if (configuration) {
                    for (let i=0; i < configuration.compartmentsCount; i++) {
                        let compartmentVM = new TrolleyLabelVMCompartment();
                        compartmentVM.sequenceNumber = i;
                        compartmentVM.color = this.findCompartmentColor(i, trolley.trolleyProjectItems, projectData.projectItems);

                        for (var label of trolleyLabels.filter(l => l.compartment == i)) {
                            compartmentVM.labels.push(new TrolleyLabelVMLabel(label));
                        }

                        trolleyVM.compartments.push(compartmentVM);
                    }
                }
            }

            vm.trolleys.push(trolleyVM);
        }

        return vm;
    }

    private findTrayColor(tray: ViewModelTrayData, projectItems: ProjectItem[]) {
        for (let column of tray.columns) {
            for (let row of column.rows) {
                const projectItem = projectItems.find(p => p.id == row.projectItemId);
                if (projectItem && projectItem.brandColor) {
                    return projectItem.brandColor;
                }
            }
        }

        return "#ffffff";
    }

    private findCompartmentColor(compartment: number, trolleyProjectItems: TrolleyProjectItem[], projectItems: ProjectItem[]) {
        for (let trolleyProjectItem of trolleyProjectItems.filter(p => p.compartment == compartment)) {
            const projectItem = projectItems.find(p => p.id == trolleyProjectItem.projectItemId);
            if (projectItem && projectItem.brandColor) {
                return projectItem.brandColor;
            }
        }

        return "#ffffff";
    }

    private trolleyLabelsUrl(projectId: number) {
        return `${this.urls.Projects}/${projectId}/${this.urls.TrolleyLabels}`;
    }
}