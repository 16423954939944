import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_URL } from '../../../environments/environment';
import { Product } from '../models/generated/product';
import { BrandColor } from '../models/generated/brand-color';

@Injectable()
export class ProductsService {

    constructor(
        private apiService: ApiService) { }

    private urls = {
        Products: API_URL + 'Products',
        ImportMaster: API_URL + 'Products/import',
        BrandColors: API_URL + 'Products/brandcolors'
    };

    getApi() {
        return this.apiService.get<Product[]>(`${this.urls.Products}`);
    }

    importMasterFile(file: File) {
        const formData = new FormData();
        formData.append('file', file);

        return this.apiService.post(`${this.urls.ImportMaster}`, formData);
    }

    getBrandColors() {
        return this.apiService.get<BrandColor[]>(this.urls.BrandColors);
    }

    updateBrandColors(data: BrandColor[]) {
        return this.apiService.post<BrandColor[]>(this.urls.BrandColors, data);
    }
}
