import { ProjectItem } from './generated/project-item';

export class ProjectItemsFilter {
    text: string;
    brands: Array<string>;
    subBrands: Array<string>;
    productTypes: Array<string>;
}

export class SubBrandInfo {

    constructor(projectItem: ProjectItem) {
        this.subBrand = projectItem.itemSubBrand;
        this.brand = projectItem.itemBrand;
    }

    subBrand: string;
    brand: string;
}

export class ProductTypeInfo extends SubBrandInfo {

    constructor(projectItem: ProjectItem) {
        super(projectItem);
        this.productType = projectItem.itemProductType;
    }

    productType: string;
}
