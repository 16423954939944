import { Component, OnInit, Input } from '@angular/core';
import { TrolleyLabel } from 'src/app/shared/models/generated/trolley-label';
import { MatDialogRef } from '@angular/material/dialog';
import { TrolleyLabelVMLabel } from 'src/app/shared/models/view-model-trolley-label';

@Component({
  selector: 'app-dialog-trolley-label',
  templateUrl: './dialog-trolley-label.component.html',
  styleUrls: ['./dialog-trolley-label.component.scss']
})
export class DialogTrolleyLabelComponent implements OnInit {

  model: TrolleyLabelVMLabel[];
  brandColor: string;
  readonly: boolean;

  constructor(
    private dialogRef: MatDialogRef<DialogTrolleyLabelComponent>,
  ) { 
    this.readonly = true;
  }

  ngOnInit() {
    if (!this.model) {
      this.model = [];
    }

    if (this.model.length == 0 && !this.readonly) {
      this.addLabel();
    }

    if (!this.brandColor) {
      this.brandColor = '#ffffff';
    }
  }

  addLabel() {
    this.model.push(new TrolleyLabelVMLabel());
  }

  deleteLabel(label: TrolleyLabelVMLabel) {
    if (label.id) {
      label.deleted = true;
    } else {
      const index = this.model.indexOf(label);
      if (index > -1) {
        this.model.splice(index, 1);
      }
    }
  }

  save() {
    this.dialogRef.close(this.model);
  }

  close() {
      this.dialogRef.close(false);
  }

}
