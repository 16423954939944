import { Component, OnInit } from '@angular/core';
import { LoaderService, AlertService } from 'src/app/shared/services';
import { BrandColor } from 'src/app/shared/models/generated/brand-color';
import { ProductsService } from 'src/app/shared/services/products.service';

@Component({
  selector: 'app-brand-colors',
  templateUrl: './brand-colors.component.html',
  styleUrls: ['./brand-colors.component.scss']
})
export class BrandColorsComponent implements OnInit {

  items: BrandColor[];

  constructor(
    private loaderService: LoaderService,
    private productsService: ProductsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.loaderService.wrapObservable(this.productsService.getBrandColors())
      .subscribe(data => {
        this.items = data;
      });
  }

  save() {
    this.loaderService.wrapObservable(this.productsService.updateBrandColors(this.items))
      .subscribe(data => {
        this.items = data;
        this.alertService.success('SHARED.ALERT_MESSAGE.SUCCESSFULLY_SAVED');
      });
  }

}
