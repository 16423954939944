import { Component, OnInit, Input } from '@angular/core';
import { ProjectItem } from 'src/app/shared/models/generated/project-item';
import { MatDialogRef } from '@angular/material';
import { EditContentsItem } from 'src/app/shared/models/edit-contents-data';
import { TrayType } from 'src/app/shared/models/generated/tray-type';

@Component({
  selector: 'app-dialog-edit-contents',
  templateUrl: './dialog-edit-contents.component.html',
  styleUrls: ['./dialog-edit-contents.component.scss']
})
export class DialogEditContentsComponent implements OnInit {

    items: EditContentsItem[];
    readonly: boolean;
    trayType: TrayType;

    constructor(
        private dialogRef: MatDialogRef<DialogEditContentsComponent>) { 
        this.readonly = true;
    }

    ngOnInit() {

    }

    removeItem(item: EditContentsItem) {
        this.items.splice(this.items.indexOf(item), 1);
    }

    save() {
        this.dialogRef.close(this.items);
    }

    close() {
        this.dialogRef.close(false);
    }
}
