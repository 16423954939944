import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { StorageService } from '../services';
import { AccessService } from '../services/access.service';

@Component({
    selector: 'shared-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() active: number;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        public accessService: AccessService,
        public storageService: StorageService) { }

    ngOnInit() {
    }

    logout() {
        this.authService.logout();
    }

    goToState(url: string) {
        this.router.navigate([url]);
    }
}
