import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthenticationService } from './services/authentication.service';
import { PlatformService } from './services/platform.service';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'new-password', component: NewPasswordComponent }
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forRoot(appRoutes)
    ],
    declarations: [
        LoginComponent,
        NewPasswordComponent,
        ResetPasswordComponent
    ],
    exports: [
        LoginComponent
    ],
    providers: [
        PlatformService,
        AuthenticationService
    ]
})
export class AuthenticationModule { }
