import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../services';

@Component({
  selector: 'shared-general-loader',
  templateUrl: './general-loader.component.html',
  styleUrls: ['./general-loader.component.scss']
})
export class GeneralLoaderComponent implements OnInit {

  constructor(public loaderService: LoaderService) { }

  ngOnInit() {
  }

}
