import { Component, OnInit } from '@angular/core';
import { OverviewContentsItem } from 'src/app/shared/models/overview-contents-data';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewModelTrayData } from 'src/app/shared/models/view-model-tray-data';
import { TrayType } from 'src/app/shared/models/generated/tray-type';

@Component({
    selector: 'app-dialog-overview-contents',
    templateUrl: './dialog-overview-contents.component.html',
    styleUrls: ['./dialog-overview-contents.component.scss']
})
export class DialogOverviewContentsComponent implements OnInit {

    items: OverviewContentsItem[];
    tray: ViewModelTrayData;
    trayType = TrayType;

    constructor(private dialogRef: MatDialogRef<DialogOverviewContentsComponent>) {
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close(false);
    }
}
