import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { FileData } from '../models/generated/file-data';

@Injectable()
export class FileHelperService {

    saveFileData(fileData: FileData) {
        const blob = this.b64toBlob(fileData.contents, fileData.contentType);
        this.saveFile(blob, fileData.fileName);
    }

    saveFile(blob: Blob, fileName: string) {
        saveAs(blob, fileName);
    }

    b64toBlob(b64Data, contentType) {
        contentType = contentType || '';
        const sliceSize = 512;

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
