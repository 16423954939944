import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrolleySize } from 'src/app/shared/models/generated/trolley-size';
import { TrayTrolleyViewModel } from 'src/app/shared/models/view-model-trolleys';

@Component({
  selector: 'app-manage-trolley',
  templateUrl: './manage-trolley.component.html',
  styleUrls: ['./manage-trolley.component.scss']
})
export class ManageTrolleyComponent implements OnInit {

  @Input() model: TrayTrolleyViewModel;
  title: string;
  edit: boolean;

  constructor(
    private dialogRef: MatDialogRef<ManageTrolleyComponent>,
    @Inject(MAT_DIALOG_DATA) public trolleySizes: Array<TrolleySize>) { }

  ngOnInit() {
    if (!this.model) {
      this.title = 'TROLLEYS.NEW';
      this.model = new TrayTrolleyViewModel();
      this.model.trolleySize = this.trolleySizes[0];
      this.edit = false;
    } else {
      this.title = 'TROLLEYS.EDIT';
      this.edit = true;
    }
  }

  save() {
    this.dialogRef.close(this.model);
  }

  close() {
    this.dialogRef.close();
  }
}
