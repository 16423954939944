import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddItemsToTrayData } from 'src/app/shared/models/addItemsToTray-dialog';
import { TrayType } from 'src/app/shared/models/generated/tray-type';

@Component({
    selector: 'app-dialog-add-items-tray',
    templateUrl: './dialog-add-items-tray.component.html',
    styleUrls: ['./dialog-add-items-tray.component.scss']
})
export class AddItemsTrayDialogComponent implements OnInit {

    data: AddItemsToTrayData;
    trayType: TrayType;

    constructor(
        private dialogRef: MatDialogRef<AddItemsTrayDialogComponent>) { }

    ngOnInit() {
    }

    addAll() {
        this.data.items.forEach(i => i.quantity = i.quantityAvailable);
        this.dialogRef.close(this.data);
    }

    addEdited() {
        if (this.data.items.some(i => i.quantity > i.quantityAvailable)) {
            return;
        }
        
        this.dialogRef.close(this.data);
    }

    close() {
        this.dialogRef.close(false);
    }

}
