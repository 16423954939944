export class Util {
    private static strykerDomain = '@stryker.com';

    public static addStrykerDomain(email: string) {
        return email && !email.endsWith(Util.strykerDomain) ?
            email.concat(Util.strykerDomain) :
            email;
    }

    public static removeStrykerDomain(email: string) {
        return email && email.endsWith(Util.strykerDomain) ?
            email.replace(Util.strykerDomain, '') :
            email;
    }

    public static cloneObject(obj: object) {
        return JSON.parse(JSON.stringify(obj));
    }

    public static localeCompareSafe(a: string, b: string): number {
        if (a !== null && a !== undefined) {
            return a.localeCompare(b);
        }

        return b === null || b === undefined ? 0 : 1;
    }
}
